import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "./PopupModal.scss";
import {
  Button,
  Grid,
  FormControl,
  Box,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  submitEsignBatch,
  validateUserClear,
} from "../../redux/pre_processor/actions";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { messages } from "../../constants/commonMessages";
import { notification } from "../../constants/notificationMessages";

export default function BasicModal({
  openAuth,
  handleClose,
  handleUserPermission,
}) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [remark, setRemark] = useState("");
  const [passwordType, setPasswordType] = useState(false);
  const dispatch = useDispatch();
  const validateUsers = useSelector((state) => state?.prePro?.validateUser);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(validateUserClear());
  }, []);

  const handleUsernameChange = (name) => {
    setErrorMessage("");
    setUserName(name);
  };
  const handlePasswordChange = (name) => {
    setPassword(name);
    setErrorMessage("");
  };

  let validateUser;
  useEffect(() => {
    if (localStorage.getItem("userRole") === "pre-processor") {
      setRemark(notification.remarkPre);
    } else if (localStorage.getItem("userRole") === "processor") {
      setRemark(notification.remarkPro);
    } else if (localStorage.getItem("userRole") === "validator") {
      setRemark(notification.remarkVal);
    }
    validateUser = validateUsers;
    if (
      validateUser?.loader === false &&
      validateUser?.next === true &&
      validateUser?.message == ""
    ) {
      handleClose();
      handleUserPermission();
      dispatch(validateUserClear());
    }
    if (validateUser?.message !== "") {
      setErrorMessage(validateUser?.message);
    }
  }, [validateUsers, remark]);
  const handelAuthSubmit = () => {
    dispatch(
      submitEsignBatch(
        userName,
        localStorage.userid,
        localStorage.userRole,
        password
      )
    );
  };

  const togglePassword = () => {
    setPasswordType(!passwordType);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Modal
        open={openAuth}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box component={Paper} className="modal-box">
          <Grid container className="modal-popup">
            <Grid item>
              <div className="modal-title">
                <h4>
                  {messages.AUTH_CREDS}
                  <span className="close-btn">
                    <CloseIcon onClick={handleClose} />
                  </span>
                </h4>
                <p className="modal-subtext">{messages.AUTH_P}</p>
              </div>
              <FormControl fullWidth>
                <div className="input-wrap">
                  <label>{messages.USER_NAME_AUTH}</label>
                  <OutlinedInput
                    size="medium"
                    fullWidth
                    placeholder="User Name"
                    onChange={(e) => handleUsernameChange(e.target.value)}
                  />
                </div>
                <div className="input-wrap">
                  <label>{messages.PASS}</label>
                  <OutlinedInput
                    fullWidth={true}
                    size="medium"
                    type={passwordType ? "text" : "password"}
                    required
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    name="password"
                    // value={password}
                    placeholder="Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {passwordType ? (
                            <Visibility />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="input-wrap">
                  <label>Remark</label>
                  <TextField
                    fullWidth
                    //placeholder="I’m the Pre Processor"
                    value={remark}
                  ></TextField>
                </div>
                <div className="error">
                  {errorMessage && <label>{errorMessage}</label>}
                </div>
                <Button className="button-submit" onClick={handelAuthSubmit}>
                  Continue
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
