import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Select,
  IconButton,
  Button,
  MenuItem,
  FormControl,
} from "@mui/material";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import "./templateCreation.scss";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AddCircleOutline } from "@mui/icons-material";
import ProgressBar from "../../../components/ProgressBar";
import { createTemplates } from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";

export default function TemplateCreation() {
  const Dispatch = useDispatch();
  const [parameters, setParameters] = useState([
    { parameter: "", sheet_name: "" },
    { parameter: "", sheet_name: "" },
    { parameter: "", sheet_name: "" },
    { parameter: "", sheet_name: "" },
    { parameter: "", sheet_name: "" },
  ]);
  const [addParameter, setAddParameter] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);
  const [singleSheet, setSingleSheet] = useState("");
  const [selectedSheet, setSelectedSheet] = useState([]);
  const [loader, setLoader] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [templateName, setTemplateName] = useState("");
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  const [successNotify, setSuccessNotify] = useState(false);

  useEffect(() => {}, [parameters.length, addParameter, sheetNames]);

  useEffect(() => {
    if (apiStatus && apiStatus != undefined) {
      if (apiStatus.loader === false) {
        if (apiStatus.message != undefined || apiStatus.message != null) {
          if (apiStatus.next === true) {
            setLoader(false);
            setSuccessNotify(true);
            setMessage(apiStatus.message);
            console.log("notify.........", successNotify);
            setSeverity("success");
          } else if (apiStatus.next === false) {
            setSuccessNotify(true);
            setMessage(apiStatus.message);
            setSeverity("error");
          }
        }
      }
    }
  }, [apiStatus]);

  const addNewParameter = () => {
    // const newArray = [...parameters,addParameter];
    setParameters([...parameters, ...addParameter]);
    setAddParameter([]);
  };

  const handleChange = (event) => {
    let counts = event.target.value;
    let obj = [];
    for (let index = 0; index < counts; index++) {
      obj[index] = { parameter: "", sheet_name: "" };
    }
    setAddParameter(obj);
  };

  const onParameterChange = (e, index) => {
    parameters[index].parameter = e.target.value;
    setParameters(parameters);
  };

  const onChangeSheet = (e) => {
    setSingleSheet(e.target.value);
  };

  const addSheetNames = () => {
    setSheetNames([...sheetNames, singleSheet]);
  };
  const addTemplateName = (e) => {
    setTemplateName(e.target.value);
  };
  const deleteParameters = (index) => {
    setLoader(true);
    setParameters(parameters.filter((values, i) => i !== index));
    setTimeout(() => {
      setLoader(false);
    }, 2000);

    // let param = parameters;
    // setParameters([]);
    // setParameters(param);
  };
  const onSelectedSheet = (e, index) => {
    parameters[index].sheet_name = e.target.value;
    setParameters(parameters);
    // setSelectedSheet(e.target.value);
  };

  const clickToSave = () => {
    Dispatch(createTemplates(parameters, templateName));
    setLoader(true);
  };
  return (
    <Box>
      <Header />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text="Template Creation" />
            <Grid className="upper-container" container>
              <Grid item xs={9}>
                <FormControl className="form-style">
                  <TextField
                    className="upper-container-input"
                    size="small"
                    placeholder="Sheet Names"
                    // value={sheetNames}
                    onChange={(e) => onChangeSheet(e)}
                  />
                  <Button className="save-btn" onClick={addSheetNames}>
                    +Add
                  </Button>
                  <TextField
                    className="upper-container-input"
                    size="small"
                    placeholder="PDF"
                    //   value={}
                    onChange={(e) => addTemplateName(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} className="upper-container-btn">
                <Button
                  disabled={templateName == "" ? true : false}
                  className="save-btn"
                  onClick={clickToSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Box className="mid-container">
              {loader === true ? (
                <ProgressBar />
              ) : (
                <>
                  {parameters?.map((param, index) => (
                    <Box key={index} className="mid-box">
                      {console.log("otifyyyyyyyyyyyyyyyyyyy", param?.parameter)}
                      {/* <FormControl> */}
                      <TextField
                        className="input-text"
                        placeholder="Parameters"
                        size="small"
                        defaultValue={param?.parameter}
                        onChange={(e) => onParameterChange(e, index)}
                      />
                      <Select
                        className="input-select"
                        id={index}
                        // label="Select Project"
                        inputProps={{ "aria-label": "Without label" }}
                        size="small"
                        defaultValue={param?.sheet_name}
                        onChange={(e) => onSelectedSheet(e, index)}
                      >
                        {sheetNames?.map((index) => (
                          <MenuItem value={index}>{index}</MenuItem>
                        ))}
                      </Select>
                      <IconButton onClick={() => deleteParameters(index)}>
                        <DeleteForeverIcon />
                      </IconButton>
                      {/* </FormControl> */}
                    </Box>
                  ))}
                </>
              )}
            </Box>
            <Grid className="lower-container" container>
              <Grid item xs={6} className="lower-container-item">
                <TextField
                  className="input-text"
                  placeholder="Parameters"
                  size="small"
                  //   value={parameters}
                  onChange={handleChange}
                />
                <IconButton onClick={addNewParameter}>
                  <AddCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {loader && <ProgressBar></ProgressBar>}
      {successNotify && (
        <CustomizedSnackbars
          severity={severity}
          state={successNotify}
          text={message}
          handleClose={() => setSuccessNotify(false)}
        />
      )}
    </Box>
  );
}
