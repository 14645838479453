import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import Box from "@mui/material/Box";
import { TextField, OutlinedInput } from "@mui/material";
import { FormControl, Grid, Select, MenuItem } from "@mui/material";
import Footer from "../../../components/Footer";
import "./BatchProcess.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserList,
  isSignatureUploaded,
  clearIsSignatureUploadedStatus,
  validateUserPermission,
  validateUserClear,
  clearApiStatus,
} from "../../../redux/processor/actions";
import Authentication from "../../../components/Modal/PopupModal";
import ModalSign from "../../../components/Modal/signature/ModalSign";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import ProcessorStepper from "../../../components/Stepper/ProcessorStepper";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import ProgressBar from "../../../components/ProgressBar";
import Label from "../../../components/label/Label";
import { notification } from "../../../constants/notificationMessages";
import constants from "../../../constants/ConstantValues";

const BatchProcessPreProcessor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [timeLineEndDate, setTimeLineEndDate] = useState(null);
  const [userName, setUserName] = useState("");
  const [openAuth, setOpenAuth] = useState(false);
  const [openSignPad, setOpenSignPad] = useState(false);
  const [userList, setUserUserList] = useState({});
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const remark = notification.remarkPro;
  const getUser = useSelector((state) => state?.prePro?.userList);
  const signUploaded = useSelector((state) => state?.pro?.isSignatureUploaded);
  const subject = notification.subjectPro;
  const apiStatus = useSelector((state) => state?.pro?.apiStatus);
  const { SUBJECT, TIMELINE, SELECT, ASSIGN, SUBMIT_VALIDATION,SUBMIT_PROCESSING} = constants;

  useEffect(() => {
    dispatch(getUserList());
    dispatch(clearIsSignatureUploadedStatus());
    dispatch(isSignatureUploaded());
    dispatch(validateUserClear());
    dispatch(clearApiStatus());
  }, []);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.loading === false) {
        if (apiStatus?.message != undefined || apiStatus?.message != null) {
          if (apiStatus?.next === true) {
            setSuccess(true);
            setMessage(apiStatus?.message);
            setTimeout(() => {
              history.push("/processor-dashboard");
            }, 1000);
          }
        } else if (apiStatus?.message != null && apiStatus?.next === false) {
          setMessage(apiStatus?.message);
          setErr(true);
        }
      }
    }
  }, [apiStatus]);

  const onClick = (event, action) => {
    if (action === "previous") {
      history.push("/upload-pdf-against-batch");
    }
    if (action === "continue") {
      if (signUploaded?.isSignatureUploaded !== undefined) {
        if (signUploaded?.isSignatureUploaded === true) {
          setOpenAuth(true);
        } else if (signUploaded?.isSignatureUploaded === false) {
          setOpenSignPad(true);
        }
      }
    }
  };
  const handleUserPermission = () => {
    const projectId = localStorage.projectId;
    const batchId = localStorage.batchId;
    const endDate = format(timeLineEndDate, "yyyy-MM-dd");
    dispatch(
      validateUserPermission({
        userName,
        projectId,
        batchId,
        endDate,
        subject,
        remark,
      })
    );
  };
  const handleEsignDialogBoxClose = () => {
    setOpenSignPad(false);
    setTimeout(() => {
      dispatch(isSignatureUploaded());
    }, 1500);
  };

  const clearAPiStatue = () => {
    dispatch(clearApiStatus());
  };

  return (
    <>
      <React.Fragment>
        <Header page="new-project" />
        <ProcessorStepper currentStep={2} />
        <Box className="batchProcessing">
          <Grid container className="h-center">
            <Grid item md={4}>
              <div className="input-wrap-title">
                <Label
                  fontSize="16px"
                  fontWeight="500"
                  text={SUBMIT_VALIDATION}
                ></Label>
              </div>
              <form action="" className="batch-processing-form">
                <FormControl fullWidth>
                  <div className="input-wrap">
                    <label>{SUBJECT}</label>
                    <TextField
                      className="input-wrap-text"
                      size="small"
                      fullWidth
                      readOnly
                      placeholder="Subject"
                      value={subject}
                    />
                  </div>
                  <div className="input-wrap">
                    <label>{SELECT}</label>
                    <Select
                      className="input-wrap-select"
                      fullWidth
                      value={userName}
                      name={SELECT}
                      input={
                        <OutlinedInput
                          onChange={(event) => setUserName(event.target.value)}
                        />
                      }
                    >
                      {getUser &&
                        getUser.all_validator &&
                        getUser.all_validator.length > 0 &&
                        getUser.all_validator.map((option, index) => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                  </div>
                  <div className="input-wrap">
                    <label>{TIMELINE}</label>
                    <DatePicker
                      className="Date-picker"
                      variant="outlined"
                      onChange={(date: Date) => setTimeLineEndDate(date)}
                      selected={timeLineEndDate}
                      minDate={new Date()}
                      showDisabledMonthNavigation
                    />
                  </div>
                </FormControl>
              </form>
            </Grid>
          </Grid>
          {openAuth && (
            <Authentication
              openAuth={openAuth}
              handleClose={() => setOpenAuth(false)}
              handleUserPermission={handleUserPermission}
            />
          )}

          {openSignPad && (
            <ModalSign
              openSignPad={openSignPad}
              handleEsignDialogBoxClose={handleEsignDialogBoxClose}
            ></ModalSign>
          )}
        </Box>
        <Footer
          isNextDisable={
            userName !== "" && timeLineEndDate !== null ? false : true
          }
          isSaveDisable={
            userName !== "" && timeLineEndDate !== null ? false : true
          }
          isPrevious={false}
          onHandleClick={(event, action) => onClick(event, action)}
        />

        {success && (
          <CustomizedSnackbars
            severity="success"
            state={success}
            text={message}
            handleClose={() => setSuccess(false)}
          />
        )}

        {err && (
          <CustomizedSnackbars
            severity="error"
            state={err}
            text={message}
            handleClose={() => setErr(false)}
          />
        )}

        {apiStatus.loader && <ProgressBar />}
      </React.Fragment>
    </>
  );
};
export default BatchProcessPreProcessor;
