import { initialState } from "./initialState";
import { errorMessage } from "../../constants/responseMessages";
import ValidatorService from "../../services/validtorService";
import {
  API_STATUS,
  CLEAR_API_STATUS,
  GET_LIST,
  GET_VALI_DATA,
  PDF_AND_PAGE_DETAILS,
  VALIDATE_USER_STATUS,
  IS_SIGNATURE_UPLOADED_STATUS,
} from "./types";

const service = new ValidatorService();

export const getValidatorList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST,
      payload: { ...initialState, loader: true },
    });
    service
      .getValidatorDashboard(data)
      .then((res) => {
        dispatch({
          type: GET_LIST,
          payload: { data: res.data.data, loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getValiData = (projectId, batchId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    dispatch({
      type: PDF_AND_PAGE_DETAILS,
      payload: {},
    });
    dispatch({
      type: GET_VALI_DATA,
      payload: [],
    });
    service
      .getValiData(projectId, batchId)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState?.apiStatus, loader: false },
        });

        dispatch({
          type: GET_VALI_DATA,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};

export const clearApiStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_API_STATUS,
      payload: {},
    });
  };
};

export const valiPageRedirection = (data) => {
  return (dispatch) => {
    dispatch({
      type: PDF_AND_PAGE_DETAILS,
      payload: {
        data,
      },
    });
  };
};

export const validateData = (data) => {
  // columnsCount used in free-form only
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .validateData(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            next: data?.next,
            nextStep: true,
            message: res?.data?.message,
            isError: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            message: errorMessage(error),
            isError: true,
          },
        });
      });
  };
};

export const rejectValidatorData = (data) => {
  // columnsCount used in free-form only
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .rejectValidatorData(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            next: data?.next,
            isRejected: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            loader: false,
            message: errorMessage(error),
          },
        });
      });
  };
};
export const validateUserClear = () => {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_USER_STATUS,
      payload: {},
    });
  };
};

export const isSignatureUploaded = () => {
  return (dispatch) => {
    dispatch({
      type: IS_SIGNATURE_UPLOADED_STATUS,
    });
    service
      .isSignatureUploaded()
      .then((json) => {
        dispatch({
          type: IS_SIGNATURE_UPLOADED_STATUS,
          payload: {
            isSignatureUploaded: json?.data?.is_signature_uploaded,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: IS_SIGNATURE_UPLOADED_STATUS,
          payload: { message: errorMessage(error) },
        });
      });
  };
};
export const clearIsSignatureUploadedStatus = () => {
  return (dispatch) => {
    dispatch({
      type: IS_SIGNATURE_UPLOADED_STATUS,
      payload: "",
    });
  };
};
