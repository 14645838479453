/* eslint-disable react/prop-types */
import React from "react";
import "./LeftSideBar.scss";
import Button from "@mui/material/Button";
import { Box, ButtonGroup, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
const LeftSideBar = ({ selectedKey = "" }) => {
  return (
    <Box className="Sidebar-add">
      <Link className="sidebar-link" to="/audit-user-activity">
        <Button
          key="one"
          fullWidth
          className={
            window.location.pathname == "/audit-user-activity" ||
            window.location.pathname == "/audit-user-activity"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Audit Trail
        </Button>
      </Link>
      <Link className="sidebar-link" to="/batch-monitor">
        <Button
          key="five"
          fullWidth
          className={
            window.location.pathname == "/batch-monitor"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Batch Details
        </Button>
      </Link>
      <Link className="sidebar-link" to="/data-report">
        <Button
          key="two"
          fullWidth
          className={
            window.location.pathname == "/data-report"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Data Deletion Report
        </Button>
      </Link>
      <Link className="sidebar-link" to="/list-directory">
        <Button
          key="three"
          fullWidth
          className={
            window.location.pathname == "/list-directory" ||
            window.location.pathname == "/upload-batch" ||
            window.location.pathname == "/upload-batch" ||
            window.location.pathname == "/review-batch-records-details"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Upload Batch Records
        </Button>
      </Link>
      <Link className="sidebar-link" to="/output-list">
        <Button
          key="five"
          fullWidth
          className={
            window.location.pathname == "/excel-output" ||
            window.location.pathname == "/output-list"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Manage Output Files
        </Button>
      </Link>
      <Link className="sidebar-link" to="/merge-pdf">
        <Button
          key="six"
          fullWidth
          className={           
            window.location.pathname == "/merge-pdf"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Merge PDF Files
        </Button>
      </Link>
      {/* <Link className='sidebar-link' to='/list-page'><Button key="four" fullWidth className={window.location.pathname =="/add-group" || window.location.pathname == "/list-page"? "btn-sidebar-active": "btn-sidebar"} >Manage Groups</Button></Link> */}
      <Link className="sidebar-link" to="/manage-user">
        <Button
          key="five"
          fullWidth
          className={
            window.location.pathname == "/user-form" ||
            window.location.pathname == "/manage-user"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Manage Users
        </Button>
      </Link>
      <Link className="sidebar-link" to="/merge-op-files">
        <Button
          key="five"
          fullWidth
          className={
            window.location.pathname == "/merge-op-files" ||
            window.location.pathname == "/merge-op-files"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
          Merge Op Files
        </Button>
      </Link>
      <Link className="sidebar-link" to="/create-template">
        <Button
          key="five"
          fullWidth
          className={
            window.location.pathname == "/create-template" ||
            window.location.pathname == "/create-template"
              ? "btn-sidebar-active"
              : "btn-sidebar"
          }
        >
         Create Templates
        </Button>
      </Link>
    </Box>
  );
};

export default LeftSideBar;
