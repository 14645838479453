import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import "./OutputTable.scss";
import { IconButton, ListItem, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditTwoTone } from "@mui/icons-material";
import { action } from "../../constants/action";


const Tables = (props) => {
  const { Type } = props;
  const { data } = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [data1, setData1] = useState("")

  const handleClick = (event, list) => {
    setAnchorEl(event.currentTarget);
    setData1(list?.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const showDetails = (data) => {
    history.push("/excel-output", data);
  };

  const PreviewDataPointCalculation = () => {
    history.push({
      pathname: "/data-point-calculation",
      state: { id: data1 },
    });
  };

  return (
    <div>
      <TableContainer className="outputTable-container"
      >
        <Table stickyHeader className="table-output" aria-label="simple table">
          <TableHead>
            <TableRow>
              {Type == "Batch" ? (
                <></>
              ) : (
                <TableCell align="left">Project Name</TableCell>
              )}
              {Type == "Batch" ? (
                <></>
              ) : (
                <TableCell align="left">Batch Name</TableCell>
              )}
              <TableCell align="left">File Name</TableCell>
              <TableCell align="left">File Type</TableCell>
              {Type == "Batch" ? (
                <TableCell align="left">Drug Type</TableCell>
              ) : (
                ""
              )}

              <TableCell align="left">Status</TableCell>
              {Type == "Batch" ? (
                ""
              ) : (
                <TableCell align="left">Created Date</TableCell>
              )}
              {Type == "Batch" ? (
                ""
              ) : (
                <TableCell align="left">Preview</TableCell>
              )}  {Type == "Batch" ? (
                ""
              ) : (
                <TableCell align="left">Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((list) => {
              return (
                <>
                  <TableRow key={list.id}>
                    {Type == "Batch" ? (
                      <></>
                    ) : (
                      <TableCell align="left">{list.project_name}</TableCell>
                    )}
                    {Type == "Batch" ? (
                      <></>
                    ) : (
                      <TableCell align="left">{list.batch_name}</TableCell>
                    )}

                    <TableCell align="left">{list.file_name}</TableCell>
                    <TableCell align="left">{list.file_type}</TableCell>
                    {Type == "Batch" ? (
                      <TableCell align="left">{list.drug_type}</TableCell>
                    ) : (
                      ""
                    )}

                    {Type == "Batch" ? (
                      <TableCell align="left">{list.status}</TableCell>
                    ) : list.rejected === true ? (
                      <TableCell>Rejected</TableCell>
                    ) : (
                      <TableCell align="left">
                        {list.status ? "Approved" : ""}
                      </TableCell>
                    )}
                    <TableCell>{list.created_date}</TableCell>
                    {Type == "Batch" ? (
                      ""
                    ) : (

                      <TableCell>
                        {list.disable_action ? (
                          <></>
                        ) : (

                          <IconButton onClick={() => showDetails(list)}>
                            <VisibilityIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                    {/* {
                      list.file_type === "csv" && */}

                    <TableCell>
                      <IconButton onClick={(e) => handleClick(e, list)}>
                        <MoreVertIcon />
                      </IconButton>
                      {Type == "Batch" ? (
                        ""
                      ) : (
                        <Menu
                          className="action-list"
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            className="action-item"
                            onClick={() =>
                              PreviewDataPointCalculation()
                            }
                          >
                            {action.DPCALCULATION}
                          </MenuItem>
                        </Menu>
                      )}
                    </TableCell>
                    {/* } */}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Tables;
