import {
  USER_LOGIN,
  LOGIN_SUCCSS,
  LOGIN_FAIL,
  API_STATUS,
  FORGOT_PASSWORD_STATUS,
  FORGOT_PASSWORD_LINK_STATUS,
  IS_LOGGED_IN_ALREADY,
  E_SIGN_UPLOAD_STATUS,
} from "./types";
import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
      };
    case LOGIN_SUCCSS:
      return {
        users: action.payload,
      };
    case LOGIN_FAIL:
      return {
        loading: false,
        users: [],
        error: action.payload,
      };
    case API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };
    case FORGOT_PASSWORD_STATUS:
      return {
        ...state,
        forgotPasswordStatus: action.payload,
      };
    case FORGOT_PASSWORD_LINK_STATUS:
      return {
        ...state,
        forgotPasswordLinkStatus: action.payload,
      };
    case IS_LOGGED_IN_ALREADY:
      return {
        isLoggedInAlready: action.payload,
      };
    case E_SIGN_UPLOAD_STATUS:
      return {
        eSignatureStatus: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
