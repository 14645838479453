import { Paper } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ModalProcessor from "../../Modal/rejectModal/ModalProcessor";

const RowRejectAndReviewNew = ({
  handleRowRejection,
  deleteRowRejection,
  role,
  isReadOnly,
  validatorRowRejectionData,
  markAsResolveRejection,
  isDataRejectedByProcessor,
  validatedData,
  parameterData,
  parameterIndex,
  isDataRejectedByValidator,
  heading,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [index, setIndex] = useState(null);

  const handleChange = (index) => {
    setModalOpen(true);
    // setIndex(index);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleReject = (comment) => {
    handleRowRejection(parameterIndex, comment);
    setModalOpen(false);
  };

  const handleDeleteRejection = () => {
    deleteRowRejection(parameterIndex);
    setModalOpen(false);
  };

  const markAsResolve = () => {
    markAsResolveRejection(parameterIndex);
    setModalOpen(false);
  };

  // disable delete button(inside rejection model) untill rejected
  const disableDeleteRejection = () => {
    // if the cell is rejected then only enable delete button
    if (role === "preProcessor") {
      return parameterData?.processorRowRejection?.isRejected ? false : true;
    } else if (role === "processor") {
      return parameterData?.processorRowRejection?.isRejected ? false : true;
    } else if (role === "validator") {
      return parameterData?.validatorRowRejection?.isRejected ? false : true;
    }
  };

  const isChecked = () => {
    let checked = false;
    if (role === "preProcessor") {
      checked = parameterData?.processorRowRejection?.isRejected ? true : false;
    } else if (role === "validator") {
      checked = parameterData?.validatorRowRejection?.isRejected ? true : false;
    } else if (role === "processor") {
      checked = parameterData?.processorRowRejection?.isRejected ? true : false;
    }

    // 2nd check box in processor role after rejected by validator
    if (role === "processor" && isReadOnly) {
      checked = parameterData?.validatorRowRejection?.isRejected;
    }
    if (role === "preProcessor" && isReadOnly) {
      checked = parameterData?.processorRowRejection?.isRejected;
    }
    return checked;
  };

  const isDisabled = (index) => {
    if (role === "preProcessor" && isDataRejectedByProcessor) {
      // if row rejected by vali then enable only in pro role to view and resolve rejection did by vali in pro role
      return parameterData?.processorRowRejection?.isRejected ? false : true;
    } else if (role === "processor" && isDataRejectedByValidator) {
      // if row rejected by vali then enable only in pro role to view and resolve rejection did by vali in pro role
      return parameterData?.validatorRowRejection?.isRejected ? false : true;
    } else if (role === "validator") {
      if (parameterData && parameterData?.rowValidation?.isValidated) {
        return true;
      }
    } else return false;
  };

  const rejectionContent = () => {
    let remark = "";
    if (isDataRejectedByValidator) {
      remark = parameterData?.validatorRowRejection?.remark;
    } else {
      remark =
        role === "validator"
          ? parameterData?.validatorRowRejection?.remark
          : parameterData?.processorRowRejection?.remark;
    }
    return remark;
  };

  const onChangeCheckBox = () => {
    if (role === "preProcessor") {
      handleChange();
    }
    if (role === "processor") {
      if (!isReadOnly && !isChecked()) {
        handleChange();
      }

      if (isChecked() && !isReadOnly) {
        handleDeleteRejection();
      }

      if (isReadOnly && isDataRejectedByValidator) {
        handleChange();
      }
    }
    if (role === "validator") {
      if (!isReadOnly && !isChecked()) {
        handleChange();
      }
      if (isChecked() && !isReadOnly) {
        handleDeleteRejection();
      }
    }
  };
  return (
    <>
      <div className="checkbox-wrap ">
        <input
          type="checkbox"
          id="rejection"
          name="rejection"
          checked={isChecked()}
          onChange={(e) => onChangeCheckBox()}
          disabled={isDisabled(index)}
          color={"red"}
        />
      </div>
      {modalOpen && (
        <ModalProcessor
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleReject={handleReject}
          isShowClearButton={false}
          markAsResolve={markAsResolve}
          handleDeleteRejection={handleDeleteRejection}
          isReadOnly={isReadOnly}
          disableDeleteRejection={disableDeleteRejection()} // temp
          rejectionContent={rejectionContent}
          heading={heading}
        />
      )}
    </>
  );
};

export default RowRejectAndReviewNew;
