import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import LeftSideBar from "../../../../components/LeftSideBar/LeftSideBar";
import { Grid, Box } from "@mui/material";
import AdministratorHeader from "../../../../components/header/AdministratorHeader";
import AuditActivityTable from "../../../../components/AuditTrailComponent/AuditActivityTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserActivity } from "../../../../redux/administrator/actions";
import CustomizedSnackbars from "../../../../components/snackbars/CustomizedSnackbars";

export default function UserActivity() {
  const userActivityList = useSelector((state) => state?.admin.userActivity);
  const dispatch = useDispatch();
  const apiStatus = useSelector((state) => state?.admin?.apiStatus);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.loader === false) {
        if (apiStatus?.message === undefined || apiStatus?.message === "") {
          // if (apiStatus?.next === true) {
          //   setMessage(apiStatus?.message)
          //   setSuccess(true)
          //   setTimeout(() => {
          //     history.push("/pre_processor_dashboard");
          //   }, 1000);
          // }
        } else if (apiStatus?.message != null && apiStatus?.next === false) {
          setMessage(apiStatus?.message)
          setErr(true)

        }
      }
    }
  }, [apiStatus]);

  return (
    <>
      <Header />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader
              isArrow={true}
              isExport={userActivityList?.data}
              text="Audit Trail"
            />
            <Grid item md={12}>
              <AuditActivityTable />
            </Grid>
          </Grid>
        </Grid>
        {err && (
          <CustomizedSnackbars
            severity="error"
            state={err}
            text={message}
            handleClose={() => setErr(false)}
          />
        )}
      </Box>
    </>
  );
}
