/* eslint-disable no-empty */
import React, { useState, useEffect } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Grid } from "@mui/material";
import "./index.scss";
import UploadBatchHeader from "../../../components/header/UploadBatch/index";
import List from "@mui/material/List";
import CheckboxTitle from "../../../components/UploadBatchRecord/CheckboxTitle/CheckboxTitle";
import SelectedFileList from "../../../components/UploadBatchRecord/SelectedFileList/SelectedFileList";
import constants from "../../../constants/ConstantValues";
import BottomButtonsList from "../../../components/UploadBatchRecord/BottomButtonsList/BottomButtonsList";
import { useDispatch, useSelector } from "react-redux";
import {
  listBoxFiles,
  uploadClientData,
} from "../../../redux/administrator/actions";
import { useHistory } from "react-router";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";

const Index = (props) => {
  const dispatch = useDispatch();
  const { PDF, TEMPLATE, OUTPUT, PREVIOUS, SAVE, GRANT } = constants;
  const [selectedName, setSelectedButton] = useState("pdfs");
  const [selectedBottomButton, setBottomButtonName] = useState(PREVIOUS);
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [groupData, setGroupData] = useState([]);

  let propsData = props?.location?.state;
  if (propsData?.value) {
    localStorage.setItem("pvalue", propsData.value);
    localStorage.setItem("folderName", propsData.folderName);
    // localStorage.setItem('fileType', propsData.)
  }

  let group = useSelector((state) => state?.admin?.boxFiles);
  const history = useHistory();
  let isOpen = false;
  let text = "";
  let severity = "";
  useEffect(() => {
    setGroupData(group);
  }, [group]);

  useEffect(() => {
    let pdata = localStorage.getItem("pvalue");
    let data = {
      folderId: pdata,
      fileType: "pdfs",
    };
    dispatch(listBoxFiles(data));
  }, [dispatch]);

  const handleToggle = (value) => () => {
    const currentIndex = checkedFiles.indexOf(value);
    let newChecked = [...checkedFiles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFiles(newChecked);
  };

  const removeSelectedFile = (index) => {
    let addedFiles = [...checkedFiles];
    if (index > -1) {
      addedFiles.splice(index, 1);
      setCheckedFiles(addedFiles);
    }
  };

  const res = useSelector((state) => state?.admin?.uploadData);
  if (res?.message === "submitted") {
    isOpen = true;
    text = "Uploaded";
    severity = "success";
  }

  const onSelectBottomButton = (selectedButton = "") => {
    setBottomButtonName(selectedButton);
    if (selectedButton === PREVIOUS) {
      history.push("/list-directory");
    } else if (selectedButton === SAVE) {
    } else if (selectedButton === GRANT) {
      let data = {
        body: {
          folder_name: localStorage.getItem("folderName"),
          client_file_details: checkedFiles,
          file_type: selectedName,
        },
      };
      if (checkedFiles?.length > 0) {
        dispatch(uploadClientData(data));
        setTimeout(() => {
          history.push("/review-batch-records-details");
        }, 1000);
      }
    }
  };

  const onSelectHeaderButton = (buttonSelected = "") => {
    setSelectedButton(buttonSelected);
    // if (buttonSelected === PDF) {
    //   setFileArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]);
    //   setCheckedFiles([]);
    // } else if (buttonSelected === TEMPLATE) {
    //   setFileArray([1, 2, 3, 4]);
    //   setCheckedFiles([]);
    // } else if (buttonSelected === OUTPUT) {
    //   setFileArray([1, 2]);
    //   setCheckedFiles([]);
    // }

    let data = {
      folderId: localStorage.getItem("pvalue"),
      fileType: buttonSelected,
    };
    dispatch(listBoxFiles(data));
  };

  const selectFileMargin = {
    marginTop: "2%",
  };

  return (
    <>
      <Header page="new-project" />
      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            isOpen = false;
          }}
          severity={severity}
          state={isOpen}
          text={text}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar selectedKey={"three"} page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <Box className="uploadBatchHeader-wrap"></Box>
            <UploadBatchHeader
              onClick={(e) => {
                onSelectHeaderButton(e);
              }}
              selectedKey={selectedName}
              position="left"
              headerColor="#F5F5F5"
            />
            <Grid container>
              <Grid item xs={5}>
                <List className="scroller check-box-style">
                  {groupData?.map((value, index) => {
                    return (
                      <CheckboxTitle
                        key={index}
                        checked={checkedFiles}
                        onClick={(e) => handleToggle(e)}
                        value={value}
                      ></CheckboxTitle>
                    );
                  })}
                </List>
              </Grid>
              <Grid className="scroller" item xs={7}>
                <Grid container className="selected-file-margin" spacing={1}>
                  {checkedFiles.map((value, index) => (
                    <Grid key={index} item xs={2} sm={5.8} md={5.8}>
                      <SelectedFileList
                        onClick={() => {
                          removeSelectedFile(index);
                        }}
                        value={value}
                      ></SelectedFileList>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <BottomButtonsList
                  onClick={(e, action) => {
                    onSelectBottomButton(e, action);
                  }}
                  selectedKey={selectedBottomButton}
                  position="end"
                  headerColor="#FFFFF"
                  isPrevious={true}
                ></BottomButtonsList>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
