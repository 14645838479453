import DatePicker from "react-datepicker";
import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import "./basicDateRangePicker.scss";
import "react-datepicker/dist/react-datepicker.css";
import Buttons from "../buttons/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";

export default function BasicDateRangePicker({ filterByDate, clear }) {
  const type = useSelector((state) => state?.prePro?.type);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = React.useState("");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const clearChild = () => {
    setStartDate("")
    setEndDate("");
    setStatus("");
    clear();
  }

  return (
    <>
      <Grid container className="BasicDatePiker-wrap">
        <Grid item md={4}>
          <Box className="box">
            <DatePicker
              placeholderText="MM/DD/YYYY"
              className="date-picker"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
            />
            <div className="datePikerTo">to</div>
            <DatePicker
              placeholderText="MM/DD/YYYY"
              className="date-picker"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
            />
          </Box>
        </Grid>
        <Grid item md={8}>
          <FormControl className="status-search">
            <InputLabel className="StatusLable">Status </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={status}
              label="Status"
              onChange={handleChange}
              disabled={type === "project"}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"draft"}>Draft</MenuItem>
              <MenuItem value={"assigned"}>Assigned</MenuItem>
            </Select>
          </FormControl>

          <Buttons
            className="btn-search"
            text="Search"
            size="medium"
            onClick={(e) => filterByDate(startDate, endDate, status)}
          />
          <Buttons
            className="btn-search"
            text="Clear"
            size="medium"
            //onClick={handleClear}
            onClick={(e) => clearChild()}
          />
        </Grid>
      </Grid>
    </>
  );
}
