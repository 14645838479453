import React from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import "../ReviewBatchRecords/reviewbatch.scss";
// import Tables from '../../../components/Table/Table';

export default function PreviewOpFile() {
  const history = useHistory();
  const backToList = () => {
    history.goBack();
  };
  return (
    <>
      <Header />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8} className="previewop-file-grid">
            <Box className="previewop-main-box">
              <IconButton onClick={backToList}>
                <ArrowBackIcon />
              </IconButton>
              <p>Project Name/Batch Name</p>
            </Box>
            <Box className="previewop-table-box">{/* <Tables/> */}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
