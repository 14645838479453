import React from "react";
class Label extends React.Component {
  render() {
    return (
      <div
        style={{
          fontSize: this.props.fontSize,
          fontWeight: this.props.fontWeight,
          color: this.props.color,
          opacity: this.props.opacity,
          textTransform: this.props.textTransform,
          padding: this.props?.padding,
          ...(this.props?.lineHeight && {
            lineHeight: this?.props?.lineHeight,
          }),
        }}
        className="label"
      >
        {this.props.text}
      </div>
    );
  }
}
export default Label;
