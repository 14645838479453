export const initialState = {
  apiStatus:
  {
    loader: false,
    message: null,
    next: false,
    data: [],
  },

};
