import React from "react";
import { any, string } from "prop-types";
import Button from "../../Controls/buttons/HeaderButton";
import "./BottomButtonsList.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import constants from "../../../constants/ConstantValues";

function BottomButtonsList({ headerColor, position, onClick, selectedKey,isPrevious,isNextDisable }) {
  const { PREVIOUS, SAVE, GRANT, } = constants;

  return (
    <div 
    className="bottom-btn-wrap"
      style={{
        background: headerColor,
        justifyContent: position,
      }}
    >
     {isPrevious && <Button
        onClick={() => {
          onClick(PREVIOUS);
        }}
        className={
          selectedKey.toLowerCase() === PREVIOUS.toLowerCase()
            ? "bottom-btn"
            : "bottom-btn-selected"
        }
        text={PREVIOUS}
        variant="contained"
        cusStyle={{
          paddingRight: "20px",
          marginRight: "15px",
          marginTop: "5px",
          height: "38px",
        }}
        startIcon={<ArrowBackIosIcon />}
      ></Button>}
      <Button
        text="Submit"
        cusStyle={{ marginRight: "15px", marginTop: "5px", height: "38px" }}
        variant="contained"
        isDisabled={isNextDisable}
        onClick={() => {
          onClick(GRANT);
        }}
        className={
          selectedKey.toLowerCase() === GRANT.toLowerCase()
            ? "bottom-btn"
            : "bottom-btn-selected"
        }
      ></Button>
    </div>
  );
}

BottomButtonsList.propTypes = {
  selectedKey: string,
  onClick: any,
  headerColor: string,
  position: string,
};

export default BottomButtonsList;
