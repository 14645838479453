import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import "./DataMapTable.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalProcessor from "../../Modal/rejectModal/ModalProcessor";

const CellMenu = (props) => {
  const {
    rejectCell,
    isRejectedByProcessor,
    rejectionContent,
    deleteRejection,
    role,
    markAsResolveByProcessor,
    isRejectedByValidator,
    validatorRejectionRemark,
    processorRejectionRemark,
    // validatedData,
    // mappingIndex,
    isRwoValidated,
    markAsResolveRejection,
    heading,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const openpop = Boolean(anchorEl);
  const [content, setContent] = useState("");

  const options = (isRejectedByProcessor, isRejectedByValidator) => {
    let options = [];
    if (role === "preProcessor") {
      // options = ["View Remark", "Mark As Resolve"];
      options = ["View Remark & Resolve"];
    } else if (role === "processor") {
      options = ["Reject"];

      if (isRejectedByProcessor) {
        // options = ["Edit", "Undo"];
        options = ["Undo Rejection"];
      }
      if (isRejectedByValidator) {
        // options = [...options, "View Validator Remark", "Mark As Resolve"];
        options = [...options, "View Validator Remark & Resolve"];
      }
    } else if (role === "validator") {
      options = ["Reject"];
      if (isRejectedByValidator) {
        options = ["Undo Rejection"];
      }
    }

    return options;
  };

  const handleClickOption = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleClickOnOption = (option) => {
    if (role === "preProcessor") {
      if (option === "View Remark & Resolve") {
        setContent(processorRejectionRemark);
      }
      setViewDialogOpen(true);
    }

    if (role === "processor") {
      if (option === "Reject") {
        setContent("");
        setModalOpen(true);
      }
      if (option === "Edit") {
        setContent(processorRejectionRemark);
        setModalOpen(true);
      }
      if (option === "Undo Rejection") {
        setContent("");
        handleDeleteRejection();
        // setModalOpen(true);
      }
      if (option === "View Validator Remark & Resolve") {
        setContent(validatorRejectionRemark);
        setViewDialogOpen(true);
      }
      if (option === "Mark As Resolve") {
        markAsResolveByProcessor();
      }
    }

    if (role === "validator") {
      if (option === "Reject") {
        setModalOpen(true);
      }
      if (option === "Edit") {
        setContent(validatorRejectionRemark);
        setModalOpen(true);
      }
      if (option === "Undo Rejection") {
        setContent("");
        handleDeleteRejection();
        // setModalOpen(true);
      }
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setModalOpen(false);
  };

  const handleReject = (rejectionRemark) => {
    rejectCell(rejectionRemark);
    setAnchorEl(null);
    setModalOpen(false);
  };

  const handleCloseViewDialogBox = () => {
    setViewDialogOpen(false);
    setAnchorEl(null);
  };

  const markAsResolveViewDialogBox = () => {
    markAsResolveByProcessor();
    setViewDialogOpen(false);
  };

  const handleDeleteRejection = () => {
    deleteRejection();
    setAnchorEl(null);
    setModalOpen(false);
  };

  const markAsResolve = () => {
    markAsResolveRejection();
    setModalOpen(false);
  };

  const disableDeleteRejection = () => {
    // if the cell is rejected then only enable delete button
    if (role === "processor") {
      return isRejectedByProcessor ? false : true;
    } else if (role === "validator") {
      return isRejectedByValidator ? false : true;
    }
  };

  const disableMarkAsResolve = () => {
    if (role === "preProcessor") {
      return isRejectedByValidator ? false : true;
    }
  };

  const isDisabled = () => {
    let disable = false;
    if (
      role === "validator" &&
      isRwoValidated
      // validatedData[mappingIndex]?.isRowValidated
    ) {
      disable = true;
    }
    return disable;
  };
  return (
    <>
      <button
        aria-label="more"
        aria-controls="long-menu"
        id="long-button"
        className="readMore-btn"
        aria-haspopup="true"
        onClick={handleClickOption}
      >
        <MoreVertIcon
        //  onClick={() => {
        //  handleData();
        // }}
        />
      </button>

      {openpop && (
        <Menu
          id="basic-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          onClose={handleClosePopUp}
          open={openpop}
        >
          {options(isRejectedByProcessor, isRejectedByValidator).map(
            (option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  handleClickOnOption(option);
                }}
                disabled={isDisabled()}
              >
                {option}
              </MenuItem>
            )
          )}
        </Menu>
      )}
      {modalOpen && (
        <ModalProcessor
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleReject={handleReject}
          isShowClearButton={false}
          rejectionContent={content}
          handleDeleteRejection={handleDeleteRejection}
          // isRejectedByProcessor={isRejectedByProcessor}
          disableDeleteRejection={disableDeleteRejection()}
          disableMarkAsResolve={disableMarkAsResolve()}
          markAsResolve={markAsResolve}
          heading={heading}
        />
      )}

      {viewDialogOpen && (
        <ModalProcessor
          modalOpen={viewDialogOpen}
          handleClose={handleCloseViewDialogBox}
          rejectionContent={content}
          heading={"Cell Rejection Remark."}
          isReadOnly={true}
          markAsResolve={markAsResolveViewDialogBox}
        />
      )}
    </>
  );
};

export default CellMenu;
