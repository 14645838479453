import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./footer.scss";
const Footer = ({
  onHandleClick,
  isNextDisable,
  isSaveDisable,
  isPrevious,
  isPreviousDisable,
  isNext,
}) => {
  return (
    <Box className="MapTableFooter">
      {isPrevious && (
        <Button
        className="Pre-next-btn"
          size="medium"
          disabled={isPreviousDisable}
          onClick={(event) => onHandleClick(event, "previous")}
          startIcon={<ArrowBackIosIcon />}
        >
          Previous
        </Button>
      )}
      {isNext && (
        <Button
        className="Pre-next-btn"
          size="medium"
          disabled={isSaveDisable}
          onClick={(event) => onHandleClick(event, "save")}
        >
          save
        </Button>
      )}
      <Button
        className="btn-contained"
        disabled={isNextDisable}
        onClick={(event) => onHandleClick(event, "continue")}
        size="medium"
      >
        Continue
      </Button>
    </Box>
  );
};

Footer.defaultProps = {
  isShowSave: true,
  isShowSaveAndNext: true,
  isShowPrevious: true,
};
export default Footer;
