import React, { useState } from "react";
import { Button, Divider, Menu, MenuItem } from "@mui/material";

const AddMoreColumnTH = ({
  column,
  addColInBetween,
  deleteColumn,
  showColHeadersMenu,
}) => {
  const [colAnchorEl, setColAnchorEl] = React.useState(null);
  const [indexOfRowOrColToBeAdd, setIndexOfRowOrColToBeAdd] = useState(null);

  const handleAddBetweenColClick = (e, id) => {
    setColAnchorEl(e.currentTarget);
    setIndexOfRowOrColToBeAdd(id);
  };

  const handleColClose = (pos) => {
    indexOfRowOrColToBeAdd && addColInBetween(indexOfRowOrColToBeAdd, pos);
    setColAnchorEl(null);
    setIndexOfRowOrColToBeAdd(null);
  };

  const handleDeleteColumn = () => {
    indexOfRowOrColToBeAdd && deleteColumn(indexOfRowOrColToBeAdd);
    setColAnchorEl(null);
    setIndexOfRowOrColToBeAdd(null);
  };

  const handleAddBetweenColClose = () => {
    setColAnchorEl(null);
    setIndexOfRowOrColToBeAdd(null);
  };

  return (
    <>
      <Button
        size="small"
        aria-controls="simple-menu2"
        aria-haspopup="true"
        onClick={(e) =>
          showColHeadersMenu && handleAddBetweenColClick(e, column?.id)
        }
        // disabled={isPreview || !isAddColInBetween}
      >
        {column.render("Header")}
      </Button>
      {showColHeadersMenu && (
        <Menu
          anchorEl={colAnchorEl}
          keepMounted
          open={Boolean(colAnchorEl)}
          onClose={handleAddBetweenColClose}
        >
          <MenuItem
            onClick={(e) => {
              handleColClose("before");
            }}
          >
            Insert Column Left
          </MenuItem>
          <Divider light />
          <MenuItem
            onClick={(e) => {
              handleColClose("after");
            }}
          >
            Insert Column Right
          </MenuItem>
          <Divider light />
          <MenuItem
            onClick={(e) => {
              handleDeleteColumn();
            }}
          >
            Delete Column
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

AddMoreColumnTH.defaultProps = {
  showColHeadersMenu: true,
};

export default AddMoreColumnTH;
