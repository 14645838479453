import { Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Label from "../../label/Label";
import "./freeFrom.scss";
import CloseIcon from "@mui/icons-material/Close";

const isDisabled = (parameterData) => {
  if (parameterData?.validatorRowRejection?.isRejected) {
    return true;
  } else {
    let isRejectedWithinCell;

    Object.keys(parameterData).map((key) => {
      if (parameterData[key]?.validatorCellRejection?.isRejected) {
        isRejectedWithinCell = true;
      }
    });

    if (isRejectedWithinCell) {
      return true;
    } else return false;
  }
};
const ValidationButton = ({
  // mappingsTotalLength,
  onClickValidate,
  // rowRejectionData,
  // validatedData,
  parameterData,
  parameterIndex,
}) => {
  return (
    <>
      <div className="sub-row validatior">
        {/* {validatedData[index]?.isRowValidated ? ( */}
        {parameterData?.rowValidation?.isValidated ? (
          <div className="validator-edit">
            <Tooltip
              title={`Validated On :${parameterData?.rowValidation?.date}`}
              placement="top"
            >
              <IconButton>
                <CloseIcon
                  onClick={(e) => onClickValidate("invalidate", parameterIndex)}
                />
                {/* <Label
              className="validation-info"
              text={`Validated On :${parameterData?.rowValidation?.date}`}
            /> */}
              </IconButton>
              {/* <EditIcon
              onClick={(e) => onClickValidate("invalidate", parameterIndex)}
            /> */}
              {/* <div className="validator-lable-wrap">
              <Label className="validation-info" text={"Validated On:"} />
              <Label
                className="validation-info"
                text={`${parameterData?.rowValidation?.date}`}
              />
            </div> */}
            </Tooltip>
          </div>
        ) : (
          <div className="checkbox-wrap ">
            <input
              type="checkbox"
              // checked={isChecked()}
              onChange={(e) => onClickValidate("validate", parameterIndex)}
              disabled={isDisabled(parameterData)}
              color={"red"}
            />
          </div>
          // <Button
          //   className="btn-validate"
          //   onClick={(e) => onClickValidate("validate", parameterIndex)}
          //   disabled={isDisabled(parameterData)}
          // >
          //   Validate
          // </Button>
        )}
      </div>
      {/* );
      })} */}
    </>
  );
};

export default ValidationButton;
