import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Select, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import pdfIcon from "../../assets/Pdf.svg";
import MenuItem from "@mui/material/MenuItem";
import "./DaysCard.scss";

const theme = createTheme({
  typography: {
    subtitle2: {
      fontSize: 18,
      fontWeight: 700,
      color: "#2D2D2D",
      marginLeft: "10px",
    },
  },
});

const DayCard = ({
  tagName,
  actualFilesListData,
  handleChange,
  cardIndex,
  selectedFiles,
  uploadedBatchPdfs,
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Card className="day-card-box">
          <CardContent className="dayCard-content">
            <Typography
              className="txt-day"
              variant="subtitle2"
            >
              {tagName}
            </Typography>
            <div
            className="dayCard-wrap">
              <img
               className="daycard-pdfIcon"
                src={pdfIcon}
              ></img>

              <Select
              className="daycardSelect"
                readOnly={localStorage.ocrReInitiate == "true"}
                disableUnderline={true}
                fullWidth
                size="medium"
                labelId="demo-select-small"
                id="demo-select-small"
                value={selectedFiles[cardIndex]?.pdfName}
                // value={selectedFiles[cardIndex]?.original_filename}
                // onChange={(e)=>handleChange(e.target.value,tagName )}
              >
                <MenuItem
                  value=""
                  onClick={(e) => handleChange(cardIndex, "", "", "")}
                ></MenuItem>

                {actualFilesListData?.data?.map((item, index) => (
                  <MenuItem
                    value={item?.file_name}
                    onClick={(e) =>
                      handleChange(
                        cardIndex,
                        item?.file_name,
                        item?.file_url,
                        tagName
                      )
                    }
                  >
                    {item?.file_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </CardContent>
        </Card>
      </ThemeProvider>
    </>
  );
};

export default DayCard;