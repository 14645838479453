import React from "react";
import { Grid, Box } from "@mui/material";
import "./mapData.scss";
import { bindActionCreators } from "redux";
import * as acts from "../../../redux/validator/actions";
import { connect } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import ProgressBar from "../../../components/ProgressBar";
import MapDataTable from "../../../components/MapDataTable/TemplateDriven/MapDataTable";
import CNCTableWrapper from "../../../components/MapDataTable/Free-Form/CNCTableWrapper";
import ImageViewer from "./ImageViewer";
import ModalProcessor from "../../../components/Modal/rejectModal/ModalProcessor";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";

class MapData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coorData: [],
      selectedFile: {
        tagName: "",
        fileNo: 0, // index is considered as file
        pageNo: 0,
      },
    };
  }

  handleCloseModal = () => {
    this.setState({
      openRejectionModal: false,
    });
  };

  handleReject = (remark) => {
    this.props?.child.current.mapData("reject", remark);
    this.setState({
      openRejectionModal: false,
    });
  };

  render() {
    return (
      <div>
        {this.props?.coorData && this.props?.coorData?.length ? (
          <Box className="img-viewer">
            <Grid container spacing={2}>
              {this?.props?.hideScreen ? (
                <Grid xs={this?.props?.leftgrid} item>
                  <ImageViewer isFullView={this?.props?.isFullView} />
                </Grid>
              ) : (
                <></>
              )}
              <Grid xs={this?.props?.rightgrid} item>
                {this.props?.is_template_uploaded === true ? (
                  <MapDataTable
                    selectedFile={this.state?.selectedFile}
                    areaIndex={this.state?.areaIndex}
                    parametersData={this.props?.parametersData}
                    ref={this.props?.child}
                    isFullView={this?.props?.isFullView}
                    role={localStorage?.userRole}
                    reProcess={this.props.reProcess}
                    reValidate={this.props.reValidate}
                    showContextMenu={false}
                  />
                ) : this.props?.is_template_uploaded === false ? (
                  <CNCTableWrapper
                    selectedFile={this.state?.selectedFile}
                    ref={this.props?.child}
                    role={localStorage.userRole}
                    parametersData={this.props?.parametersData}
                    columnsCount={this.props?.columnsCount}
                    reProcess={this.props?.reProcess}
                    reValidate={this.props?.reValidate}
                    showContextMenu={false}
                    isDisableOnChangeInput={true}
                    isDisableOnBlur={true}
                    isFullView={this?.props?.isFullView}
                  />
                ) : (
                  <TableSkeleton />
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={this?.props?.leftgrid}>
              <TableSkeleton />
            </Grid>
            <Grid item xs={this?.props?.rightgrid}>
              <TableSkeleton />
            </Grid>
          </Grid>
        )}

        {this.state.openRejectionModal && (
          <ModalProcessor
            modalOpen={this.state.openRejectionModal}
            handleClose={this.handleCloseModal}
            handleReject={this.handleReject}
            isShowClearButton={false}
            disableDeleteRejection={true}
            isShowDeleteButton={false}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    coorData: state.vali?.coorData?.coordinatesDetails || [],
    parametersData: state.vali?.coorData?.mapping_details?.parametersData || [],
    is_template_uploaded: state.vali?.coorData?.is_template_uploaded,
    apiStatus: state.vali?.apiStatus,
    columnsCount: state.vali?.coorData?.mapping_details?.columnsCount,
    reProcess: state.vali?.coorData?.mapping_details?.reProcess,
    reValidate: state.vali?.coorData?.mapping_details?.reValidate,
    validatorRowRejectionData:
      state.vali?.coorData?.mapping_details?.validatorRowRejectionData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapData);
