export const initialState = {
  apiStatus: {
    loading: false,
    message: null,
    next: false,
    data: null,
    uploadPdf: [],
  },
  preProProjects: [],
  type: "project",
  uploadedPdfAndTags: [],
  coorData: [],
};
