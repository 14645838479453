import { initialState } from "./initialState";
import {
  API_STATUS,
  CLEAR_API_STATUS,
  DELETE_PDF,
  PRE_PRO_PROJECTS,
  GET_USER_LIST,
  IS_SIGNATURE_UPLOADED_STATUS,
  ASSIGN_USER_API_STATUS,
  VALIDATE_USER_STATUS,
  UPLOADED_TEMPLATE_AND_PARAMETERS,
  UPLOADED_BATCH_PDFS,
  GET_TAGS,
  UPDATE_FILTER,
  GET_UPLOADED_PDF_TAGS,
  GET_COOR_DATA,
  GET_PROJECT_TYPE_LIST,
  GET_PROJECT_TYPE,
  GET_OUTPUT_FORMAT_LIST,
  GET_SHEETS_LIST,
  AUTO_REFRESH,
  SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE,
} from "./types";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };
    case DELETE_PDF:
      return {
        deletePdf: action.payload,
      };

    case PRE_PRO_PROJECTS:
      return {
        ...state,
        preProProjects: action.payload,
      };

    case CLEAR_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case UPLOADED_TEMPLATE_AND_PARAMETERS:
      return {
        ...state,
        uploadedTemplateAndParameters: action.payload,
      };
    case GET_USER_LIST:
      return {
        ...state,
        userList: action.payload.data,
      };
    case IS_SIGNATURE_UPLOADED_STATUS:
      return {
        ...state,
        isSignatureUploaded: action.payload,
      };
    case ASSIGN_USER_API_STATUS:
      return {
        ...state,
        assignUserApiStatus: action.payload,
      };
    case VALIDATE_USER_STATUS:
      return {
        ...state,
        validateUser: action.payload,
      };

    case UPLOADED_BATCH_PDFS:
      return {
        ...state,
        uploadedBatchPdfs: action.payload,
      };

    case GET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    case UPDATE_FILTER:
      return {
        ...state,
        type: action.payload,
      };

    case GET_UPLOADED_PDF_TAGS:
      return {
        ...state,
        uploadedPdfAndTags: action.payload,
      };

    case GET_COOR_DATA:
      return {
        ...state,
        coorData: action.payload,
      };
    case GET_PROJECT_TYPE_LIST:
      return {
        ...state,
        drugType: action.payload.data,
      };
    case GET_PROJECT_TYPE:
      return {
        ...state,
        folderType: action.payload.data,
      };
    case GET_SHEETS_LIST:
      return {
        ...state,
        sheetNames: action.payload,
      };
    case GET_OUTPUT_FORMAT_LIST:
      return {
        ...state,
        outpuFormat: action.payload.data,
      };
    case AUTO_REFRESH:
      return {
        ...state,
        autoRefresh: action.payload,
      };
    case SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE:
      return {
        ...state,
        selectedFilesDataForProUpdate: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
