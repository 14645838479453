import React, { useEffect } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Grid, Box } from "@mui/material";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import BatchinfoTable from "../../../components/BatchInfoTable";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../../../redux/administrator/actions";
import constantValues from '../../../constants/ConstantValues'
export default function Index() {
  const Dispatch = useDispatch();
  const{PROJECT_NAME, BATCH_NAME,PRE_NAME,PRO_NAME,VAL_NAME,STATUS,BATCH_DETAILS} = constantValues;
  const project = useSelector((state) => state?.admin?.batchDetails);
  // useEffect(() => {
  //   Dispatch(
  //     acts.getBatchDetailsList({
  //       page: 1,
  //       page_size: 10,
  //     })
  //   );
  // }, [Dispatch]);

  let header = [
    PROJECT_NAME, 
    BATCH_NAME,
    PRE_NAME,
    PRO_NAME,
    VAL_NAME,
    STATUS
  ];
  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text= {BATCH_DETAILS}/>
            <Grid item md={12}>
              <BatchinfoTable header={header} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
