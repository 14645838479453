import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  clearApiStatus,
  getAuditTrailList,
} from "../redux/administrator/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./AuditTable.scss";
import DateRangePicker from "./DateRangePicker";
import moment from "moment";
import SearchBar from "./Controls/searchBar/SearchBar";
import { Select, MenuItem } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

const useStyles = makeStyles((theme) => ({
  tcontainer: {
    borderRadius: 0,
  },
  // tableHeader: {
  //   fontSize: "14px",
  //   fontWeight: "bold",
  //   color: "#202020",
  // },
}));

function Row(props) {
  const { project, i } = props;
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const viewAuditDetails = (project_id, batch_id) => {
    localStorage.setItem("projectId", project_id);
    localStorage.setItem("batchId", batch_id);
    history.push({
      pathname: "/audit-details",
      state: { batchId: batch_id },
    });
  };
  const viewAuditBatchDetails = (project_id, batch_id) => {
    localStorage.setItem("projectId", project_id);
    localStorage.setItem("batchId", batch_id);
    history.push({
      pathname: "/audit-history",
      state: { batchId: batch_id },
    });
  };

  return (
    <React.Fragment>
      <Grid
        container
        className="table-data"
      >
        <Grid item sm={1}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>

        <Grid item sm={3} className="break-word">
          {project?.project_name}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2} className="AuditTable"></Grid>
        <Grid item sm={3} className="AuditTable">
          <IconButton onClick={() => viewAuditDetails(project?.project_id)}>
            <VisibilityIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box>
        {open &&
          project.batch_details.map((batch, i) => (
            <Grid
              container
              className="hidden-row"
              spacing={2}
              key={batch?.batch_id}
            >
              <Grid item sm={5} className="auditTableBatchN">
                {batch.batch_name}
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <IconButton
                  onClick={() =>
                    viewAuditBatchDetails(batch?.project_id, batch?.batch_id)
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4} className="AuditTable"></Grid>
              <Grid item sm={3} className="AuditTable">
                <IconButton
                  onClick={() =>
                    viewAuditDetails(batch?.project_id, batch?.batch_id)
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </Box>
    </React.Fragment>
  );
}

export default function DataTable(records, headCells, props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const projectsFromState = useSelector(
    (state) => state?.prePro?.preProProjects
  );
  const auditlist = useSelector((state) => state?.admin.auditData);
  const history = useHistory();
  const type = useSelector((state) => state?.prePro?.type);
  const [activityName, setActivityName] = useState("Data History");
  const activity = [
    {
      id: "1",
      name: "User Activity",
    },
    { id: "2", name: "Data History" },
  ];
  const [pageCount, setPageCount] = useState(auditlist?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setProjects(auditlist?.data);
  }, [type]);

  useEffect(() => {
    dispatch(clearApiStatus());
    dispatch(
      getAuditTrailList({
        page: page + 1,
        page_size: rowsPerPage,
        type: "",
        formattedStartDate: "",
        formattedEndDate: "",
        status: "",
      })
    );
  }, [dispatch, rowsPerPage]);

  useEffect(() => {
    if (auditlist?.data?.length > 0) {
      setProjects(auditlist?.data);
    }
  }, [auditlist?.data]);

  const filterByDate = (startDate, endDate, status) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";
    dispatch(
      getAuditTrailList({
        page: page + 1,
        page_size: rowsPerPage,
        formattedStartDate,
        formattedEndDate,
      })
    );
  };

  const onSearch = (word, type) => {
    let result;
    let filteredProjects = [];

    if (type === "project") {
      if (word) {
        result = auditlist?.data?.filter(function (el) {
          return (
            el?.project_name
              .toString()
              .toLowerCase()
              .indexOf(word.toLowerCase()) !== -1
          );
        });
      } else {
        result = auditlist?.data;
      }
    } else {
      if (word) {
        const res = auditlist?.data?.map((item, index) => {
          const filteredBatches = item?.batch_details?.filter(function (el) {
            return (
              el?.batch_name
                .toString()
                .toLowerCase()
                .indexOf(word.toLowerCase()) !== -1
            );
          });
          if (filteredBatches.length > 0) {
            filteredProjects.push({
              ...auditlist?.data[index],
              batch_details: filteredBatches,
            });
          } else {
          }
        });
        result = filteredProjects;
      } else {
        result = auditlist?.data;
      }
    }
    setProjects(result);
  };

  const showUserActivity = (value) => {
    setActivityName(value);
    if (value === "User Activity") {
      history.push("/audit-user-activity");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(
      getAuditTrailList({
        page_size: rowsPerPage,
        page: value + 1,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div className="action-wrap-outer">
      <Grid container className="action-wrap">
        <Grid item className="action-select-wrap" md={2}>
          <Select
            className="input-drop-select"
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={activityName}
            size="small"
            onChange={(event) => showUserActivity(event.target.value)}
            fullWidth
          >
            {activity?.map((index) => {
              return (
                <MenuItem value={index?.name} selected="selected">
                  {index?.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={4}>
          <SearchBar onSearch={(value, type) => onSearch(value, type)} />
        </Grid>
        {/* <Grid item md={5} >
          <DateRangePicker filterByDate={filterByDate} />
        </Grid> */}
      </Grid>
      <Box
        className="Audit-table-wrap"
      // className={classes.tcontainer}
      >
        <Grid container className="tableHeader" >
          <Grid className="text-center padd-5" item xs={3}>
            Project Name
          </Grid>
          <Grid className="text-center padd-5" item xs={3}>
            Batch Name
          </Grid>
          <Grid className="padd-5 text-right" item xs={3}>
            History
          </Grid>
          <Grid className="padd-5 text-right" item xs={3}>
            Action
          </Grid>
        </Grid>
        <Grid>
          {projects &&
            projects.length > 0 &&
            projects?.map((project, i) => (
              <Row
                key={project?.project_id}
                project={project}
                i={i}
                props={props}
              />
            ))}
        </Grid>
      </Box>
      <Grid
        className="pagination-wrap pagination-bg"
      >
        <TablePagination
          component="div"
          rowsPerPageOptions={[15, 25, 50, 100]}
          count={parseInt(pageCount)}
          page={parseInt(page)}
          onPageChange={handlePageChange}
          rowsPerPage={parseInt(rowsPerPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </div>
  );
}
