import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./jsonModal.scss";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import Paper from '@mui/material/Paper';
export default function BasicModal({ isOpen, handleClose }) {
  var JSONPrettyMon = require("react-json-pretty/dist/monikai");
  const yourData = [
    {
      project_id: "20",
      batch_id: "28",
      files_paths: [
        {
          pdfpath:
            "https://cpv-auto-sia.s3.amazonaws.com/cpv-hybrid-dev-local/rawFiles/pdfs/CAR-NK/Z-05-9690-Day1.pdf",
          original_filename: "Z-05-9690-Day1.pdf",
          tagname: "Day1",
        },
        {
          pdfpath:
            "https://cpv-auto-sia.s3.amazonaws.com/cpv-hybrid-dev-local/rawFiles/pdfs/CAR-NK/Z-05-9690-Day3.pdf",
          original_filename: "Z-05-9690-Day3.pdf",
          tagname: "Day3",
        },
        {
          pdfpath:
            "https://cpv-auto-sia.s3.amazonaws.com/cpv-hybrid-dev-local/rawFiles/pdfs/CAR-NK/Z-05-9690-Day9.pdf",
          original_filename: "Z-05-9690-Day9.pdf",
          tagname: "Day9",
        },
      ],
      file_name_list: [
        "Z-05-9690-Day1.pdf",
        "Z-05-9690-Day3.pdf",
        "Z-05-9690-Day9.pdf",
      ],
      auto_extraction: true,
    },
  ];
  return (
    <div>
      <Modal
        className="json-modal"
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component={Paper} className="jsonViewerModal">
          <JSONPretty
            id="json-pretty"
            theme={JSONPrettyMon}
            data={yourData}
          ></JSONPretty>
        </Box>
      </Modal>
    </div>
  );
}
