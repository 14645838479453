import React from "react";
import HeaderButton from "../../Controls/buttons/HeaderButton";
import "./index.scss";

const header = ({ headerColor, onClick, position, selectedKey = "pdf" }) => {
  return (
    <div
      className="project-main-header"
      style={{
        background: headerColor,
        justifyContent: position,
      }}
    >
      <HeaderButton
        onClick={() => {
          onClick("pdfs");
        }}
        className={
          selectedKey.toLowerCase() === "pdfs"
            ? "header-btn"
            : "header-btn-selected"
        }
        text={"pdf"}
        cusStyle={{ margin: "12px", minWidth: "230px", height: "38px" }}
      ></HeaderButton>
      <HeaderButton
        size="larger"
        onClick={() => {
          onClick("template");
        }}
        text={"template"}
        cusStyle={{ margin: "12px", minWidth: "230px", height: "38px" }}
        className={
          selectedKey.toLowerCase() === "template"
            ? "header-btn"
            : "header-btn-selected"
        }
      ></HeaderButton>
      <HeaderButton
        text={"output format"}
        variant="contained"
        cusStyle={{ margin: "12px", minWidth: "230px", height: "38px" }}
        onClick={() => {
          onClick("op_format");
        }}
        className={
          selectedKey.toLowerCase() === "op_format"
            ? "header-btn"
            : "header-btn-selected"
        }
      ></HeaderButton>
    </div>
  );
};

export default header;
