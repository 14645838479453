import React from "react";
import Header from "../../../components/header/Header";
import ProjectHeader from "../../../components/header/ProjectHeader";
import "./mapData.scss";
import MapFooter from "../../../components/Footer/MapFooter";
import { bindActionCreators } from "redux";
import * as acts from "../../../redux/processor/actions";
import { connect } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ProgressBar from "../../../components/ProgressBar";
// import ProcessorStepper from "../../../components/Stepper/ProcessorStepper";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import ModalProcessor from "../../../components/Modal/rejectModal/ModalProcessor";
import DataEntryContainer from "./dataEntryContainer";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import Buttons from "../../../components/Controls/buttons/Button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { notification } from "../../../constants/notificationMessages";

class MapData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coorData: [],
      isFullView: false,
      leftgrid: 6,
      rightgrid: 6,
      isFullScreen: true,
      hideScreen: true,
      action: "",
      batchRejection: false,
      rejectionRemark: "",
    };
    // Create the child instance using react createRef
    this.child = React.createRef();
  }

  componentDidMount() {
    this.props.acts.clearApiStatus();
    this.props.acts.getProData(localStorage.batchId, localStorage.projectId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps?.entireBatchRejectedByPro &&
      nextProps?.entireBatchRejectedByPro
    ) {
      this.setState({ batchRejection: nextProps?.entireBatchRejectedByPro });
    }
    if (nextProps?.apiStatus?.loader === true) {
      this.setState({
        loader: true,
      });
    }
    if (
      nextProps?.apiStatus?.loader === false &&
      nextProps?.apiStatus?.next === true
    ) {
      this.setState({
        loader: false,
        sucessNotification: true,
        errorNotification: false,
        message: notification.successMessage,
      });
      if (this.state.action === "saveAndNext") {
        setTimeout(() => {
          this.setState({
            sucessNotification: false,
            message: "",
          });
          this.props?.history?.push("/pro-user-permission");
        }, 2000);
      }
      if (this.state.action === "save") {
        setTimeout(() => {
          this.setState({
            sucessNotification: false,
            message: "",
            loader: false,
          });
        }, 2000);
      }
    }
    if (
      nextProps?.apiStatus?.loader === false &&
      nextProps?.apiStatus?.message != null
    ) {
      this.setState({
        loader: false,
        sucessNotification: false,
        errorNotification: true,
        message: nextProps?.apiStatus.message,
      });
    }
    if (
      nextProps?.apiStatus?.loader === false &&
      //nextProps?.apiStatus?.next === true &&
      nextProps?.apiStatus?.isRejected === true
    ) {
      this.setState({
        loader: false,
        sucessNotification: true,
        errorNotification: false,
        message: notification.successMessage,
      });
      setTimeout(() => {
        this.setState({
          sucessNotification: false,
          message: "",
        });
        this.props?.history?.push("/processor-dashboard");
      }, 2000);
    }
  }

  onClickFooterAction = (action, e) => {
    if (action === "cancel") {
      this.props.history.push("/processor-dashboard");
    } else if (action === "save" || action === "saveAndNext") {
      this.setState({
        action: action,
      });

      if (
        this?.state?.batchRejection
        // && this?.state?.rejectionRemark
      ) {
        this.child.current.mapData(
          "reject",
          this?.state?.rejectionRemark,
          this?.state?.batchRejection
        ); // if entire batch is rejected
      } else this.child.current.mapData(action);
    } else if (action === "reject") {
      if (!e.target.checked) {
        this.setState({
          batchRejection: false,
          rejectionRemark: "",
        });
      } else if (e.target.checked) {
        this.setState({
          openRejectionModal: true,
        });
      }

      //   this?.state?.batchRejection && this?.state?.rejectionRemark // when checkbx is true then remove remark and set checkbox to false
      //     ? this.setState({
      //         batchRejection: false,
      //         rejectionRemark: "",
      //       })
      //     : this.setState({
      //         openRejectionModal: true,
      //       });
    }
  };

  handleCloseModal = () => {
    this.setState({
      openRejectionModal: false,
      batchRejection: false,
    });
  };

  handleReject = (remark) => {
    // this.child.current.mapData("reject", remark);
    this.setState({
      openRejectionModal: false,
      batchRejection: true,
      rejectionRemark: remark,
    });
  };

  slideScreentoMaximize = () => {
    this.setState({ rightgrid: 12 });
    this.setState({ isFullScreen: false });
    this.setState({ hideScreen: false });
  };
  slideScreentoMinimize = () => {
    this.setState({ leftgrid: 6 });
    this.setState({ rightgrid: 6 });
    this.setState({ isFullScreen: true });
    this.setState({ hideScreen: true });
  };

  handleCloseFullView = () => {
    this.setState({ isFullView: false });
  };

  handleOpenFullView = () => {
    this.setState({ isFullView: true });
  };

  render() {
    return (
      <div>
        <Header />
        <div className="fullscreen-view">
          {this.state.isFullScreen ? (
            <div className="slide-button">
              <Buttons
                size="small"
                onClick={() => this.slideScreentoMaximize()}
                text="Hide PDF"
                startIcon={<ArrowLeftIcon />}
              />
            </div>
          ) : (
            <div className="slide-button">
              <Buttons
                size="small"
                onClick={() => this.slideScreentoMinimize()}
                text="Show PDF"
                startIcon={<ArrowRightIcon />}
              />
            </div>
          )}
          <Typography className="project-batch-header">
            {localStorage.projectName + "-" + localStorage.batchName}
          </Typography>

          <IconButton
            aria-label="fullView"
            // onClick={(e) => this.handleOpenFullView()}
          >
            {/* <FullscreenOutlinedIcon /> */}
            <Typography className="current-step"> Data Entry</Typography>
          </IconButton>
        </div>

        {this?.state?.isFullView ? (
          <Dialog
            fullScreen={true}
            open={this?.state?.isFullView}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            keepMounted={true}
          >
            <DialogActions className="dialog-style">
              {this.state.isFullScreen ? (
                <div className="slide-button">
                  <Buttons
                    size="small"
                    onClick={() => this.slideScreentoMaximize()}
                    text="Hide PDF"
                    startIcon={<ArrowLeftIcon />}
                  />
                </div>
              ) : (
                <div className="slide-button">
                  <Buttons
                    size="small"
                    onClick={() => this.slideScreentoMinimize()}
                    text="Show PDF"
                    startIcon={<ArrowRightIcon />}
                  />
                </div>
              )}
              <IconButton
                className="fullview-close"
                aria-label="close"
                onClick={this.handleCloseFullView}
              >
                <CloseIcon />
              </IconButton>
            </DialogActions>

            <DialogContent className="fullscreen-table-wrap">
              <DataEntryContainer
                child={this.child}
                isFullView={this?.state?.isFullView}
                hideScreen={this?.state?.hideScreen}
                leftgrid={this?.state?.leftgrid}
                rightgrid={this?.state?.rightgrid}
              />
            </DialogContent>
          </Dialog>
        ) : (
          <DataEntryContainer
            child={this?.child}
            isFullView={this?.state?.isFullView}
            hideScreen={this?.state?.hideScreen}
            leftgrid={this?.state?.leftgrid}
            rightgrid={this?.state?.rightgrid}
          />
        )}

        <MapFooter
          pdfCount={this.props?.coorData.length}
          isDisableMultipageMappingLable={true}
          onClickFooterAction={this.onClickFooterAction}
          batchRejection={this.state.batchRejection}
        />

        {this.state?.sucessNotification && (
          <CustomizedSnackbar
            severity="success"
            state={this.state?.sucessNotification}
            text={this.state?.message}
            handleClose={() =>
              this.setState({
                sucessNotification: false,
              })
            }
          />
        )}
        {this.state?.errorNotification && (
          <CustomizedSnackbar
            severity="error"
            state={this.state?.errorNotification}
            text={this.state.message}
            handleClose={() =>
              this.setState({
                errorNotification: false,
              })
            }
          />
        )}

        {this.props?.apiStatus?.loader ? <ProgressBar /> : ""}

        {this.state.openRejectionModal && (
          <ModalProcessor
            modalOpen={this.state.openRejectionModal}
            handleClose={this.handleCloseModal}
            handleReject={this.handleReject}
            isShowClearButton={false}
            disableDeleteRejection={true}
            isShowDeleteButton={false}
            heading={"Do you want to reject the batch?"}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    coorData: state.pro?.coorData?.coordinatesDetails || [],
    parametersData: state.pro?.coorData?.mapping_details?.parametersData,
    apiStatus: state.pro?.apiStatus,
    entireBatchRejectedByPro:
      state.pro?.coorData?.mapping_details?.entireBatchRejectedByPro,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapData);
