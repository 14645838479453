import React, { useCallback } from "react";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { throttle } from "lodash";
import AddMoreColumnTH from "./AddMoreColumnTH";

const ResizableHeader = ({
  column,
  onResize,
  addColInBetween,
  deleteColumn,
  role,
  ...rest
}) => {
  const handleResizeStart = () => {
    // Add the no-select class to the body
    document.body.classList.add("no-select");
  };

  const handleResizeStop = () => {
    // Remove the no-select class from the body
    document.body.classList.remove("no-select");
  };

  const handleResize = useCallback(
    throttle((e, { size }) => {
      onResize(column.id, size.width);
    }, 100),
    []
  );

  return (
    <th {...rest}>
      <Resizable
        width={column.width}
        height={0}
        onResize={handleResize}
        onResizeStart={handleResizeStart} // Handle start of resize
        onResizeStop={handleResizeStop} // Handle end of resize
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <div
          style={{
            width: column.width,
          }}
        >
          <AddMoreColumnTH
            column={column}
            addColInBetween={addColInBetween}
            deleteColumn={deleteColumn}
            showColHeadersMenu={role !== "validator"}
            role={role}
          />

          {/* {column.render("Header")} */}
          <div
            style={{
              width: "5px",
              height: "100%",
              cursor: "col-resize",
              backgroundColor: "transparent",
            }}
            onMouseDown={(e) => e.preventDefault()} // Prevent default on mousedown
          />
        </div>
      </Resizable>
    </th>
  );
};

export default ResizableHeader;
