import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "../pages/pre_processor/Dashboard/index";
import newproject from "../pages/pre_processor/NewProject/index";
import UploadBatchRecords from "../pages/pre_processor/UploadBatchRecords";
import ChangePassword from "../components/AuthPages/forgot_password/PasswordChange";
import NewBatch from "../pages/pre_processor/NewBatch/index";
import UploadTemplateData from "../pages/pre_processor/UploadTemplateData/index";
import UploadPdfAgainstBatch from "../pages/pre_processor/UploadPdfAgainstBatch";
import PasswordChange from "../components/AuthPages/forgot_password/PasswordChange";
import BatchProcess from "../pages/pre_processor/BatchProcess";
import PopupModal from "../components/Modal/PopupModal";
import MapData from "../pages/pre_processor/MapData/index";
import ListPage from "../pages/administrator/ListPage/index";
import AddGroup from "../pages/administrator/AddGroup/index";
import ManageUser from "../pages/administrator/ManageUser/index";
import BatchProcessPreProcessor from "../pages/pre_processor/PreProcessorUserPermission/index";
import UserForm from "../pages/administrator/UserForm/index";
import DataReport from "../pages/administrator/DataDeletion/index";
import ExcelOutput from "../pages/administrator/ExcelOutPut/index";
import UploadBatchRecord from "../pages/administrator/uploadBatchRecords/index";
import OutputList from "../pages/administrator/OutputFormat/index";
import PreviewOpFile from "../pages/administrator/OutputFormat/PreviewOpFile";
import ReviewBatchRecords from "../pages/administrator/ReviewBatchRecords/ReviewBatchRecords";
import BatchProcessProcessor from "../pages/processor/ProcessorUserPermission/index";
import AuditList from "../pages/administrator/AuditTrail/AuditList/index";
import AuditDetails from "../pages/administrator/AuditTrail/AuditDetails/AuditDetails";
import ValidatorDashboard from "../pages/validator/Dashboard/index";
import ProcessorDashboard from "../pages/processor/Dashboard/index";
import DataEntry from "../pages/processor/DataEntry/index";
import DataValidation from "../pages/validator/DataValidation/index";
import ListDirectory from "../pages/administrator/uploadBatchRecords/ListDirectory";
import BatchMonitor from "../pages/administrator/BatchDetails/index";
import AuditHistory from "../pages/administrator/AuditTrail/auditHistory/index";
import AuditUserActivity from "../pages/administrator/AuditTrail/UserActivity/index";
import {
  adminRoutes,
  authRoutes,
  preProRoutes,
  proRoutes,
  valiRoutes,
} from "./RouteConstants";
import PageNotFount from "../components/PageNotFound";
import DataPointCalculation from "../pages/administrator/DpCalculation/index";
import DataPointDetails from "../pages/administrator/OutputFormat/DataPointDetails";
import MergeOpFiles from "../pages/administrator/MergeOpFiles/index";
import MergePDF from "../pages/administrator/MergePDF/MergePdf";
import MergePdfSelect from "../pages/administrator/MergePDF/index"
import TemplateCreation from "../pages/administrator/TemplatesCreation/index";

const RoleBaseAccessRoute = ({ ...rest }) => {
  const canAccess = (preProRoutes) => {
    return Object.values(preProRoutes).some(
      (path) => path === rest?.path.replace("/", "")
    );
  };

  if (authRoutes?.forgotPassword === "forgot-password") {
    if (canAccess(authRoutes)) {
      return <Route {...rest} />;
    }
  }
  if (localStorage?.userRole === "pre-processor") {
    if (canAccess(preProRoutes)) {
      return <Route {...rest} />;
    } else {
      return <PageNotFount />;
    }
  } else if (localStorage?.userRole === "processor") {
    if (canAccess(proRoutes)) {
      return <Route {...rest} />;
    } else {
      return <PageNotFount />;
    }
  } else if (localStorage?.userRole === "validator") {
    if (canAccess(valiRoutes)) {
      return <Route {...rest} />;
    } else {
      return <PageNotFount />;
    }
  } else if (localStorage?.userRole === "administrator") {
    if (canAccess(adminRoutes)) {
      return <Route {...rest} />;
    } else {
      return <PageNotFount />;
    }
  }
  else {
    return <PageNotFount />;
  }
};
class RoutesChild extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Switch>
          <RoleBaseAccessRoute
            name={preProRoutes?.preProDashboard}
            path={`${match.path}${preProRoutes?.preProDashboard}`}
            exact
            component={Dashboard}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.newProject}
            path={`${match.path}${preProRoutes?.newProject}`}
            exact
            component={newproject}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.uploadBatchRecord}
            path={`${match.path}${preProRoutes?.uploadBatchRecord}`}
            exact
            component={UploadBatchRecords}
          />
          <RoleBaseAccessRoute
            name={authRoutes?.changePassword}
            path={`${match.path}${authRoutes?.changePassword}`}
            exact
            component={ChangePassword}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.newBatch}
            path={`${match.path}${preProRoutes?.newBatch}`}
            exact
            component={NewBatch}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.uploadTemplateData}
            path={`${match.path}${preProRoutes?.uploadTemplateData}`}
            exact
            component={UploadTemplateData}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.uploadPdfAgainstBatch}
            path={`${match.path}${preProRoutes?.uploadPdfAgainstBatch}`}
            exact
            component={UploadPdfAgainstBatch}
          />
          <RoleBaseAccessRoute
            name={authRoutes?.forgotPassword}
            path={`${match.path}${authRoutes?.forgotPassword}`}
            exact
            component={PasswordChange}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.batchProcess}
            path={`${match.path}${preProRoutes?.batchProcess}`}
            exact
            component={BatchProcess}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.dataPointCalculation}
            path={`${match.path}${adminRoutes?.dataPointCalculation}`}
            exact
            component={DataPointCalculation}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.dataPointDetails}
            path={`${match.path}${adminRoutes?.dataPointDetails}`}
            exact
            component={DataPointDetails}
          />
          {/* <RoleBaseAccessRoute
            name={preProRoutes?.preProDashboard}
            path={`${match.path}${preProRoutes?.preProDashboard}`}
            exact
            component={PopupModal}
          /> */}
          <RoleBaseAccessRoute
            name={adminRoutes?.uploadBatch}
            path={`${match.path}${adminRoutes?.uploadBatch}`}
            exact
            component={UploadBatchRecord}
          />
          <RoleBaseAccessRoute
            name={preProRoutes?.mapData}
            path={`${match.path}${preProRoutes?.mapData}`}
            exact
            component={MapData}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.listPage}
            path={`${match.path}${adminRoutes?.listPage}`}
            exact
            component={ListPage}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.addGroup}
            path={`${match.path}${adminRoutes?.addGroup}`}
            exact
            component={AddGroup}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.manageUser}
            path={`${match.path}${adminRoutes?.manageUser}`}
            exact
            component={ManageUser}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.reviewBatchRecordDetails}
            path={`${match.path}${adminRoutes?.reviewBatchRecordDetails}`}
            exact
            component={ReviewBatchRecords}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.userForm}
            path={`${match.path}${adminRoutes?.userForm}`}
            exact
            component={UserForm}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.excelOutput}
            path={`${match.path}${adminRoutes?.excelOutput}`}
            exact
            component={ExcelOutput}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.dataReport}
            path={`${match.path}${adminRoutes?.dataReport}`}
            exact
            component={DataReport}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.outputList}
            path={`${match.path}${adminRoutes?.outputList}`}
            exact
            component={OutputList}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.previewOPFileDetails}
            path={`${match.path}${adminRoutes?.previewOPFileDetails}`}
            exact
            component={PreviewOpFile}
          />

          <RoleBaseAccessRoute
            name={preProRoutes?.preProUserPermission}
            path={`${match.path}${preProRoutes?.preProUserPermission}`}
            exact
            component={BatchProcessPreProcessor}
          />
          <RoleBaseAccessRoute
            name={proRoutes?.proUserPermission}
            path={`${match.path}${proRoutes?.proUserPermission}`}
            exact
            component={BatchProcessProcessor}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.auditUserActivity}
            path={`${match.path}${adminRoutes?.auditUserActivity}`}
            exact
            component={AuditUserActivity}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.auditList}
            path={`${match.path}${adminRoutes?.auditList}`}
            exact
            component={AuditList}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.auditDetails}
            path={`${match.path}${adminRoutes?.auditDetails}`}
            exact
            component={AuditDetails}
          />
          <RoleBaseAccessRoute
            name={valiRoutes?.valiDashboard}
            path={`${match.path}${valiRoutes?.valiDashboard}`}
            exact
            component={ValidatorDashboard}
          />
          <RoleBaseAccessRoute
            name={proRoutes?.proDashboard}
            path={`${match.path}${proRoutes?.proDashboard}`}
            exact
            component={ProcessorDashboard}
          />
          <RoleBaseAccessRoute
            name={proRoutes?.dataEntry}
            path={`${match.path}${proRoutes?.dataEntry}`}
            exact
            component={DataEntry}
          />
          <RoleBaseAccessRoute
            name={valiRoutes?.dataValidation}
            path={`${match.path}${valiRoutes?.dataValidation}`}
            exact
            component={DataValidation}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.listDirectory}
            path={`${match.path}${adminRoutes?.listDirectory}`}
            exact
            component={ListDirectory}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.batchMonitor}
            path={`${match.path}${adminRoutes?.batchMonitor}`}
            exact
            component={BatchMonitor}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.auditHistory}
            path={`${match.path}${adminRoutes?.auditHistory}`}
            exact
            component={AuditHistory}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.mergeOpFiles}
            path={`${match.path}${adminRoutes?.mergeOpFiles}`}
            exact
            component={MergeOpFiles}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.mergePdf}
            path={`${match.path}${adminRoutes?.mergePdf}`}
            exact
            component={MergePDF}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.mergePdfSelect}
            path={`${match.path}${adminRoutes?.mergePdfSelect}`}
            exact
            component={MergePdfSelect}
          />
          <RoleBaseAccessRoute
            name={adminRoutes?.CreateTemplate}
            path={`${match.path}${adminRoutes?.CreateTemplate}`}
            exact
            component={TemplateCreation}
          />
          <Route path="*" component={PageNotFount} />
        </Switch>
      </>
    );
  }
}

export default RoutesChild;
