const string = {
  min: 1,
  max: 100000000000,
  errorMessage: "Invalid Text",
};

const numeric = {
  min: 0,
  max: 100000000000,
  errorMessage: "Invalid Number Or It Excedded Range",
};

const date = {
  // pattern: "DD-MMM-YYYY",
  formats: ["DD-MMM-YYYY"],
  errorMessage: "Invalid Date",
};

const date_time = {
  formats: [
    // "YYYY-MM-DD LT","YYYY-MM-DD h:mm:ss A","YYYY-MM-DD HH:mm:ss","YYYY-MM-DD HH:mm"
    // "DD-MMM-YYYY LT","DD-MMM-YYYY h:mm:ss A","DD-MMM-YYYY HH:mm:ss","DD-MMM-YYYY HH:mm"
    "DD-MMM-YYYY h:mm:ss A",
    "DD-MMM-YYYY HH:mm:ss",
    "DD-MMM-YYYY HH:mm",
    "DD-MMM-YYYY h:m:s",

    "d-m-YYYY h:mm:ss A",
    "d-m-YYYY HH:mm:ss",
    "d-m-YYYY HH:mm",
    "d-m-YYYY h:m:s",

    "d-MMM-YYYY h:mm:ss A",
    "d-MMM-YYYY HH:mm:ss",
    "d-MMM-YYYY HH:mm",
    "d-MMM-YYYY h:m:s",

    "DD/MMM/YYYY h:mm:ss A",
    "DD/MMM/YYYY HH:mm:ss",
    "DD/MMM/YYYY HH:mm",
    "DD/MMM/YYYY h:m:s",
  ],
  errorMessage: "Invalid Date Time",
};

const float = {
  errorMessage: "Invalid Float Value",
};

const percentage = {
  min: 0,
  max: 100,
  errorMessage: "Invalid Percentage Or It Excedded Range",
};

const time = {
  errorMessage: "Invalid Time Value",
};

const scientific = {
  min: 0,
  max: 10,
  decimalMin: 0,
  decimalMax: 10,
  expoMax: 99,
  errorMessage: "Invalid Scientific Value",
};

const multipleValues = {
  errorMessage: "Multiple values for reletive parameter",
};

export {
  string,
  numeric,
  date,
  date_time,
  float,
  percentage,
  scientific,
  multipleValues,
  time,
};
