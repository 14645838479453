import React from "react";
import LoginPage from "../login/LoginPage";
import aventiorLogo from "../../../assets/aventior_logo_color_white-new.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./LandingPage.scss";
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  render() {
    return (
      <div className="logInImg">
        <Grid container className="loginFormWrapper">
          <Grid item sm={6} md={4}>
            <Box>
              <LoginPage props={this.props} />
            </Box>
            <Box className="logInImg-wrap">
              <div className="logoWrap">
                <img
                  src={aventiorLogo}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default LandingPage;
