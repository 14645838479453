import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Button, Grid, IconButton } from "@mui/material";
import * as acts from "../../../redux/administrator/actions";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Document, Page, pdfjs } from "react-pdf";
import TextField from "@mui/material/TextField";
import SearchBar from "../../../components/Controls/searchBar/SearchBar";
import DateRangePicker from "../../../components/DateRangePicker";
import "./DataDeletion.scss";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Autocomplete from "@mui/material/Autocomplete";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import Progressbar from "../../../components/ProgressBar";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Index = () => {
  const Dispatch = useDispatch();
  const history = useHistory();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [input, setInput] = useState("");
  const [file, setFile] = useState();
  const [listData, setListData] = useState([]);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);

  const data = useSelector((state) => state?.admin?.deletedData);

  // let listData = [];
  // if (data?.message) {
  // } else {
  //   listData = data;
  // }
  useEffect(() => {
    Dispatch(
      acts.getDeletedData({
        formattedStartDate: "",
        formattedEndDate: "",
      })
    );
  }, []);


  useEffect(() => {
    if (data && data.length > 0) {
      setListData([...data]);
    } else {
      setListData([]);
    }
    if (data && data !== undefined) {
      if (data?.message != null && data?.next === false) {
        setMessage(data?.message)
        setErr(true)
      }
    }
  }, [data]);

  const handleChange = (event, name) => {
    if (!name) {
      setInput("");
    } else {
      setFile(name?.split("|")[1]);
      setInput(name?.split("|")[0]);
    }

    // setListData(data);
  };
  // const handleSearch = (event) => {
  //   let searchParam = event.target.value;
  //   let obj = data.filter((index) => index.file_name.includes(searchParam));
  //   setListData(obj);
  // };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const filterByDate = (startDate, endDate, status) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";
    Dispatch(
      acts.getDeletedData({
        formattedStartDate,
        formattedEndDate,
      })
    );
  };

  const downloadPdf = (file) => {
    const link = document.createElement("a");
    link.href = file;
    // link.download = 'filename';
    link.click();
  };
  const clear = () => {
    Dispatch(
      acts.getDeletedData({
        formattedStartDate: "",
        formattedEndDate: "",
      })
    );
  }

  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8} className="data-deletion-wrap">
            <AdministratorHeader text="Data Deletion Reports" />
            <Grid container className="input-wrap">
              <Grid item md={4}>
                <DateRangePicker filterByDate={filterByDate} clear={clear} />
                {/* <SearchBar 
              // onSearch={(value, type) => onSearch(value, type)}
            /> */}
              </Grid>
              <Grid item md={4} className="selectDataReport-wrap">
                {/* <FormControl
                  className="selectDataReport"
                >
                  <InputLabel className="select-label">Select File</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    onChange={handleChange}
                    // value={listData}
                    fullWidth
                  >
                    <TextField
                      placeholder="Search..."
                      size="small"
                      fullWidth
                      onChange={handleSearch}
                    />
                    {listData?.length > 0 &&
                      listData?.map((list) => {
                        return (
                          <MenuItem key={list?.batch_name} value={list.url}>
                            {list.batch_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl> */}
                <FormControl className="selectDataReport">
                  <InputLabel className="select-label">Select File</InputLabel>
                  <Autocomplete
                    options={listData?.map(
                      (list) => list.batch_name + " | " + list.url
                    )}
                    getOptionLabel={(option) => option?.split("|")[0]}
                    inputValue={input}
                    // autoComplete
                    filterSelectedOptions={true}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Combo box"
                        size="small"
                        placeholder="Select File"
                        onChange={({ target }) =>
                          setInput(target.value.split("|")[0])
                        }
                        // variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid className="download-pdf" item md={2}>
              <Button
                onClick={() => downloadPdf(file)}
                size="medium"
                className="download-pdf_-btn"
                startIcon={<FileDownloadIcon />}
              >
                download
              </Button>
              </Grid>
            </Grid>
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
            <Box className="data-deletion-document">
              <Document file={file || ""} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Index;
