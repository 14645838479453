import { combineReducers } from "redux";
import preProReducer from "./pre_processor/reducer";
import auth from "./auth/reducer";
import proReducer from "./processor/reducer";
import validator from "./validator/reducer";
import administrator from "./administrator/reducer";

const appReducer = combineReducers({
  auth: auth,
  prePro: preProReducer,
  pro: proReducer,
  vali: validator,
  admin: administrator,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
