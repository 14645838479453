import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
export default class PreProcessor {
  getProjects = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};

    if (type === "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      let boolStatus = "";
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }

      params = {
        created_batch_after: formattedStartDate,
        created_batch_before: formattedEndDate,
        pre_pro_status: boolStatus,
      };
    }

    return axiosService.Get(`api/landing-page/`, {
      params: params,
    });
  };

  getUserList = () => {
    return axiosService.Get(`api/get-users-list/`);
  };

  esignHandle = (userName, userId, userRole, userPassword) => {
    const data = {
      body: {
        user_name: userName,
        user_id: userId,
        user_role: userRole,
        user_password: userPassword,
      },
    };
    return axiosService.Post(`api/verify-user/`, data);
  };

  isSignatureUploaded = () => {
    return axiosService.Get(`api/is-signature-uploaded/`);
  };

  userPermission = (authData) => {
    const data = {
      body: {
        username: authData.userName,
        project_id: authData.projectId,
        batch_id: authData.batchId,
        end_date: authData.endDate,
        subject: authData.subject,
        remark: authData.remark,
      },
    };
    return axiosService.Post(`api/assign-validator/`, data);
  };

  getProcessorDashboard = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};
    let boolStatus = "";
    if (type == "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }
    }
    params = {
      created_batch_after: formattedStartDate,
      created_batch_before: formattedEndDate,
      pro_status: boolStatus,
    };

    return axiosService.Get(`api/landing-page/`, {
      params: params,
    });
  };

  getProData = (batchId, projectId) => {
    const params = {
      batch_id: batchId,
      project_id: projectId,
    };

    return axiosService.Get(`api/get-extracted-data/`, {
      params: params,
    });
  };


  getProDataForReset = (batchId, projectId) => {
    const params = {
      batch_id: batchId,
      project_id: projectId,
    };

    return axiosService.Get(`api/reset-extracted-data/`, {
      params: params,
    });
  };



  dataEntry = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(`api/save-data-entry/`, data);
  };

  rejectData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(`api/reject-batch/`, data);
  };
  getProjectsViewed = (batchId) => {
    const data = {
      body: { batch_id: batchId },
    };
    return axiosService.Post(
      `api/project-batch-viewed/`,
      data
    );
  };
  projectInfo = (projectId) => {
    const params = {
      project_id: projectId
    };
    return axiosService.Get(
      `api/get-document-details/`, { params: params, }
    );
  };

}