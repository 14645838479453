import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "../PopupModal.scss";
import { Box, Typography } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import "./modalSign.scss";
import Button from "../../Controls/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearApiResponse,
  clearESighUploadStatue,
  saveUserSignature,
} from "../../../redux/auth/actions";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import { action } from "../../../constants/action";
import { messages } from "../../../constants/commonMessages";
import { notification } from "../../../constants/notificationMessages";

var AWS = require("aws-sdk");


const ModalSign = ({
  openSignPad,
  handleEsignDialogBoxClose,
  trimmedDataURL,
  handleEsignSuccess,
}) => {
  const eSignatureStatus = useSelector(
    (state) => state?.auth?.eSignatureStatus
  );
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [message, setMessage] = useState("");
  let signPad;

  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(clearESighUploadStatue());
  },[])

  useEffect(() => {
    dispatch(clearESighUploadStatue());
    setError(false);
    setMessage("");
  }, []);

  useEffect(() => {
    if (eSignatureStatus && eSignatureStatus !== undefined) {
      if (eSignatureStatus?.loader === false) {
        if (
          eSignatureStatus?.message !== undefined ||
          eSignatureStatus?.message !== ""
        ) {
          if (eSignatureStatus?.next === true) {
            setSuccess(true);
            setMessage(eSignatureStatus.message);
            setTimeout(() => {
              handleEsignDialogBoxClose(false);
              setSuccess(false);
            }, 2000);
          }
        } else if (eSignatureStatus?.message !== "") {
        }
      }
    }
    // dispatch(clearApiResponse());
  }, [eSignatureStatus]);

  const clear = () => {
    signPad.clear();
    //this.sigPad.clear();
  };
  const trim = (props) => {
    let trimmedDataURL = signPad.getTrimmedCanvas().toDataURL("image/png");

    const data = atob(
        trimmedDataURL.substring("data:image/png;base64,".length)
      ),
      asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    const blob = new Blob([asArray], { type: "image/png" });

    if (blob?.size >= 100) {
      // if having signature then upload
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_ID,
        secretAccessKey: process.env.REACT_APP_KEY,
      });
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME, // your bucket name
        Key: `${process.env.REACT_APP_BUCKET_NAME_ROOT_FOLDER}/e_signature/${localStorage.userid}/signature.png`,
        ACL: "public-read",
        Body: blob,
        ContentType: "image/png",
      };

      s3.upload(params, (s3Err, data) => {
        if (data) {
          dispatch(saveUserSignature(data?.Location));
          // setSuccess(true);
          // setMessage(apiStatus.message)
          // setTimeout(() => {
          //   handleEsignDialogBoxClose(false);
          //   setSuccess(false);
          // }, 2000);

          trimmedDataURL = null;
        }

        if (s3Err) {
        }
      });
    } else {
      // if not having signature then alert
      console.log("Empty sign");
      setError(true);
      setMessage(notification.signatureNotDrawn);
    }
  };

  return (
    <div className="E-sign-model">
      <Modal
        open={openSignPad}
        onClose={handleEsignDialogBoxClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
        className="modal-sign-box">
          <div></div>
          <Typography
            variant="h6"
            className="e-sing-title"
          >
            {messages.E_SIGNATURE}
            <CloseIcon
              onClick={handleEsignDialogBoxClose}
            />
          </Typography>
          <Typography
          className="user-name"
            variant="caption1"
          >
            {localStorage?.userName}
          </Typography>
          <div className="signatureContainer">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                // width: 500,
                height: 200,
                className: "signatureCanvas",
              }}
              ref={(ref) => {
                signPad = ref;
              }}
            />
            <div className="esignUploadedText">{action.DRAW}</div>
          </div>
          <div className="signatureOutputContainer">
            {trimmedDataURL && (
              <Paper
              >
                {trimmedDataURL ? (
                  <img
                    className="esignImage"
                    src={
                      "data:image/png;base64," +
                      btoa(String.fromCharCode.apply(null, trimmedDataURL))
                    }
                    //  src={trimmedDataURL}
                    alt=""
                  />
                ) : (
                  "Please Upload Your Signature"
                )}

                <div className="esignUploadedText">{action.UPLOAD}</div>
              </Paper>
            )}
          </div>
          <Box
          className="e-sign-modsal-btn-wrap"
          >
            <Button
            className="btn-clr"
              text="clear"
              size="small"
              onClick={clear}
              variant="outlined"
            ></Button>
            <Button
            className="btn-upload"
              text="Upload"
              size="small"
              variant="contained"
              onClick={trim}
            ></Button>
          </Box>
          {success && (
            <CustomizedSnackbar
              severity="success"
              state={success}
              text={message}
              handleClose={() => setSuccess(false)}
            />
          )}
          {error && (
            <CustomizedSnackbar
              severity="error"
              state={error}
              text={message}
              handleClose={() => setError(false)}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSign;
