export const Colors = {
  Green: "#48B06B",
  Blue: "#0C989B",
  Indigo: "indigo",
  Olive: "#6B8E23",
};

export const Themes = {
  Light: "light",
  Dark: "dark",
};

export const Fonts = {
  SansSerif: "sans-serif",
  Roboto: "calibri",
  OpenSans: "verdana",
  Montserrat: "Montserrat",
};

export const changeColor = (color) => {
  if (color === Colors.Green) {
    document.documentElement.style.setProperty("--theme-color", color);
  } else if (color === Colors.Blue) {
    document.documentElement.style.setProperty("--theme-color", color);
  } else if (color === Colors.Indigo) {
    document.documentElement.style.setProperty("--theme-color", color);
  } else if (color === Colors.Olive) {
    document.documentElement.style.setProperty("--theme-color", color);
  }
};

export const changeTheme = (theme) => {
  if (theme === Themes.Light) {
    document.documentElement.style.setProperty("--color-body", "#fff");
    document.documentElement.style.setProperty(
      "--secondary-body-color",
      "#fff"
    );
    document.documentElement.style.setProperty("--body-text-color", "#000");
    document.documentElement.style.setProperty("--bg-header-footer", "#fff");
  } else if (theme === Themes.Dark) {
    document.documentElement.style.setProperty(
      "--color-body",
      "rgb(26, 33, 56)"
    );
    document.documentElement.style.setProperty(
      "--secondary-body-color",
      "rgb(50, 59, 84)"
    );
    document.documentElement.style.setProperty(
      "--bg-header-footer",
      "rgb(35, 41, 57)"
    );

    document.documentElement.style.setProperty("--body-text-color", "#fff");
  }
};

export const changeFontFamily = (font) => {
  if (font === Fonts.SansSerif) {
    document.documentElement.style.setProperty("--font-family", "sans-serif");
  } else if (font === Fonts.Roboto) {
    document.documentElement.style.setProperty("--font-family", "Roboto");
  } else if (font === Fonts.OpenSans) {
    document.documentElement.style.setProperty("--font-family", "Open Sans");
  } else if (font === Fonts.Montserrat) {
    document.documentElement.style.setProperty("--font-family", "Montserrat");
  }
};
