import React from "react";
import ReactLoading from "react-loading";
import { styled } from "@mui/system";

const StyledLoading = styled(ReactLoading)({
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate (-40px -50px)",
  zIndex: "999",
});
export default function ProgressBar() {
  return (
    <StyledLoading
      type="spinningBubbles"
      color="#4096FC"
      height={75}
      width={75}
    />
  );
}
