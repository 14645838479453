export let notification = {
  createProjectSuccess: "Project Created successfully",
  invalidValidationErrorMessage: "Project name cannot contain special charater",
  successMessage: "Successfully updated",
  error: "error",
  invalidValidationErrMsgTag: "*SpecialCharater not allowed in tag name",
  tagAlreadyExistErr: "Tag Name already exists",
  tagNotSelectErr: "Tag not Selected",
  invalidValidationErrMsgBatch: "Batch name cannot contain special charater",
  remarkPre: "I’m the Pre Processor",
  remarkPro: "I’m the Processor",
  remarkVal: "I’m the Validator",
  subjectPre: "New batch assigned for Processing.",
  subjectPro: "New batch assigned for Validation.",
  userPermission: "Assigned Successfully",
  userPermissionCreds: "Something Went Wrong Please Check Credentials",
  logoutMsg: "Logged Out Successfully",
  inValidPasswordErrorMessage: "Password Does Not Match",
  invalidPasswordQuality:
    "Password does not meet the minimum system quality password requirement",
  somethingWentWrong: "Something went wrong please try again",
  signatureNotDrawn: "Signature in not drawn please draw signature",
};
