import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
export default class ValidatorService {
  getValidatorDashboard = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};
    let boolStatus = "";
    if (type === "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }
    }
    params = {
      created_batch_after: formattedStartDate,
      created_batch_before: formattedEndDate,
      vali_status: boolStatus,
    };

    return axiosService.Get(`api/landing-page/`, {
      params: params,
    });
  };

  getValiData = (projectId, batchId) => {
    const params = {
      project_id: projectId,
      batch_id: batchId,
    };

    return axiosService.Get(`api/get-data-for-validation/`, {
      params: params,
    });
  };

  validateData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(`api/save-validated-data/`, data);
  };

  rejectValidatorData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(`api/reject-batch/`, data);
  };

  isSignatureUploaded = () => {
    return axiosService.Get(`api/is-signature-uploaded/`);
  };
}