import React from "react";
import {
  Button,
  InputAdornment,
  Box,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import "./login.scss";
import auth from "../../../config/auth";
import { messages } from "../../../constants/commonMessages";
import * as acts from "../../../redux/auth/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CPVLogo from "../../../assets/CPV-Auto-logo-Final.png";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { notification } from "../../../constants/notificationMessages";
class LoginPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      inValidUserErrorMessage: "",
      errormessage: false,
      isLoggedInAlready: false,
      isFileUploaded: false,
      isDisable: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.acts.clearApiResponse();
    this.props.acts.loginClear();
    this.props.acts.loginInAlreadyClear();
    this.props.acts.clearForgotPassLinkStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    if (
      nextProps.userData.users &&
      nextProps.userData.users.data !== undefined
    ) {
      if (nextProps.userData.users.data.status === true) {
        localStorage.setItem(
          messages.TOKEN,
          nextProps.userData.users.data.data.token
        );
        localStorage.setItem(
          messages.USER_ROLE,
          nextProps.userData.users.data.data.role
        );
        localStorage.setItem(
          messages.USER_ID,
          nextProps.userData.users.data.data.userid
        );
        localStorage.setItem(
          messages.ISSIGNATUREUPLOADED,
          nextProps.userData.users.data.data.isSignatureUploaded
        );
        localStorage.setItem(
          messages.USER_NAME,
          nextProps.userData.users.data.data.username
        );
        this.setState({
          isLoading: false,
          inValidUserErrorMessage: "",
        });
        this.setState((prevState) => ({ inValidUserErrorMessage: "" }));

        auth.login();
        if (auth.isAuthenticated) {
          if (nextProps.userData.users.data.data.role === "pre-processor") {
            // <Redirect to="/dashboard"  component={Dashboard}/>
            // setTimeout(() => {
            this.setState({
              isLoading: false,
            });
            //this.props.history.push("/");

            this.props.props.history.push("/pre-processor-dashboard");
            // }, 1500);
          } else if (nextProps.userData.users.data.data.role === "processor") {
            this.props.props.history.push("/processor-dashboard");
          } else if (nextProps.userData.users.data.data.role === "validator") {
            setTimeout(() => {
              this.props.props.history.push("/validator-dashboard");
            }, 2000);
            //this.props.props.history.push("/validator-dashboard");
          } else if (
            nextProps.userData.users.data.data.role === "administrator"
          ) {
            this.props.props.history.push("/audit-list");
          } else {
            this.props.props.history.push("/");
          }
        }
      }
    } else if (nextProps.userData.error && nextProps.userData.error !== "") {
      this.setState({
        // inValidUserErrorMessage: nextProps.userData.error.message,
        inValidUserErrorMessage:
          nextProps?.userData?.error?.response?.data?.message,

        isLoading: false,
        errormessage: true,
      });
      if (nextProps.userData.error === "Please Update Your Password") {
        this.props.props.props.history.push("/forgotPassword");
        localStorage.setItem("invalidPassword", true);
      }
    }

    if (
      nextProps.forgotPasswordBoxStatus &&
      nextProps.forgotPasswordBoxStatus !== undefined
    ) {
      if (nextProps.forgotPasswordBoxStatus.loader === false) {
        if (
          nextProps.forgotPasswordBoxStatus.message === undefined ||
          (nextProps.forgotPasswordBoxStatus.message !== "" &&
            nextProps.forgotPasswordBoxStatus?.next === true)
        ) {
          this.setState({
            isFileUploaded: true,
            isMessage: nextProps.forgotPasswordBoxStatus.message,
          });
          this.props.acts.clearForgotPassLinkStatus();
        } else if (
          nextProps.forgotPasswordBoxStatus.message !== "" &&
          nextProps.forgotPasswordBoxStatus.loader === false &&
          nextProps.forgotPasswordBoxStatus?.next !== true
        ) {
          this.setState({
            tagNameError: true,
            isMessage: nextProps.forgotPasswordBoxStatus.message,
          });
          this.props.acts.clearForgotPassLinkStatus();
        }
      }
    }
    // if (nextProps.isLoggedInAlready) {
    //   this.setState({
    //     isLoggedInAlready: nextProps.isLoggedInAlready,
    //   });
    // }

    if (nextProps.apiStatus && nextProps.apiStatus !== undefined) {
      if (nextProps.apiStatus.loader === false) {
        if (
          nextProps.apiStatus.message === undefined ||
          nextProps.apiStatus.message === ""
        ) {
          this.setState({
            isFileUploaded: true,
            isMessage: notification.logoutMsg,
          });
          this.props.acts.clearApiResponse();
          if (nextProps.apiStatus.next === true) {
            this.props.acts.clearApiResponse();
            this.props.acts.loginInAlreadyClear();
            this.props.acts.loginFailClear();
            this.setState({
              isLoggedInAlready: false,
              inValidUserErrorMessage: "",
            });
          }
        } else if (
          nextProps.apiStatus.message !== "" &&
          nextProps.apiStatus.message !== "Invalid Token"
        ) {
          this.setState({
            tagNameError: true,
            isMessage: nextProps.apiStatus.message,
          });
        }
      }
    }

    if (
      nextProps.forgotPasswordLinkStatus &&
      nextProps.forgotPasswordLinkStatus !== undefined
    ) {
      if (
        nextProps.forgotPasswordLinkStatus.loader === false &&
        nextProps.forgotPasswordLinkStatus.next === true &&
        (nextProps.forgotPasswordLinkStatus.message !== undefined ||
          nextProps.forgotPasswordLinkStatus.message !== "")
      ) {
        this.setState({
          isFileUploaded: true,
          isMessage: nextProps.forgotPasswordLinkStatus.message,
        });
        this.props.acts.clearForgotPassLinkStatus();
      } else if (
        nextProps.forgotPasswordLinkStatus.loader === false &&
        nextProps.forgotPasswordLinkStatus.next === false &&
        (nextProps.forgotPasswordLinkStatus.message !== undefined ||
          nextProps.forgotPasswordLinkStatus.message !== "")
      ) {
        this.setState({
          tagNameError: true,
          isMessage: nextProps.forgotPasswordLinkStatus.message,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      inValidUserErrorMessage: "",
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    // this.props.acts.loginClear();
    const user = {
      body: {
        username: this.state.email,
        password: this.state.password,
      },
    };
    this.setState({
      isLoading: true,
    });
    this.props.acts.userLogin(user);
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleForgotPassword = (event) => {
    event.preventDefault();
    const forgotPasswordBox = `${process.env.REACT_APP_FORGOT_PASSWORD_LINK}`;
    this.props.acts.forgotPasswordLink(this.state.email, forgotPasswordBox);
  };

  render() {
    return (
      <Box component="main" className="ac-login-container-main">
        <div className="loginFormWrap">
          <div className="CPV_Logo">
            <img src={CPVLogo} alt="Logo" />
          </div>
          <form onSubmit={this.handleSubmit}>
            {/* <h5>Sign in</h5> */}
            <FormControl fullWidth={true}>
              <div className="input-wrap">
                <label>Username</label>
                <OutlinedInput
                  fullWidth={true}
                  id="email"
                  required
                  error={this.state.emailErrorStatus}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  // placeholder="Username"
                  labelwidth={0}
                  value={this.state.email}
                  onChange={(e) => this.handleEmailChange(e)}
                />
              </div>
              <div className="input-wrap">
                <label>Password</label>
                <OutlinedInput
                  fullWidth
                  required
                  name="password"
                  size="small"
                  type={this.state.showPassword ? "text" : "password"}
                  aria-describedby="outlined-weight-helper-text"
                  // placeholder="Password"
                  labelwidth={0}
                  value={this.state.password}
                  onChange={(e) => this.handlePasswordChange(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        className="input-wrap-icon"
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="error">
                {this.state.inValidUserErrorMessage && (
                  <label>{this.state.inValidUserErrorMessage}</label>
                )}
              </div>
              <Button
                className={
                  this.state.email === "" || this.state.password === ""
                    ? "signin-disabled"
                    : "signbtn"
                }
                type="submit"
                onClick={this.handleSubmit}
                disabled={
                  this.state.email === "" || this.state.password === ""
                    ? true
                    : false
                }
              >
                Sign in
              </Button>

              <Button
                className={
                  this.state.email === "" ? "forgetPassword-disabled" : ""
                }
                disabled={this.state.email === "" ? true : false}
                onClick={this.handleForgotPassword}
                endIcon={<MailOutlineIcon />}
              >
                Forgot/ Update Password
              </Button>

              <div className="version-wrap">
                <p className="app-version-text">Application Version</p>
                <p className="app-version-number">NXG 1.0</p>
              </div>
            </FormControl>
          </form>
        </div>

        {this.state.isFileUploaded && (
          <CustomizedSnackbars
            severity="success"
            state={this.state.isFileUploaded}
            text={this.state.isMessage}
            handleClose={() => this.setState({ isFileUploaded: false })}
          />
        )}
        {this.state.tagNameError && (
          <CustomizedSnackbars
            severity="error"
            state={this.state.tagNameError}
            text={this.state.isMessage}
            handleClose={() => this.setState({ tagNameError: false })}
          />
        )}
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    forgotPasswordBoxStatus: state.auth.forgotPasswordBoxStatus,
    userData: state.auth,
    apiStatus: state.auth.apiStatus,
    forgotPasswordLinkStatus: state?.auth?.forgotPasswordLinkStatus,
    isLoggedInAlready: state.auth.isLoggedInAlready,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
