import { Box, Button } from "@mui/material";
import moment from "moment";
import ActionBar from "../../../components/actionbar/ActionBars";
import Header from "../../../components/header/Header";
import ProjectHeader from "../../../components/header/ProjectHeader";
import DashboardTable from "../../../components/Table/DashboardTable";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "../../../components/DataTable.scss";
import * as pre from "../../../redux/pre_processor/actions";
import {
  clearApiStatus,
  clearAutoRefresh,
  uploadedTagClear,
} from "../../../redux/pre_processor/actions";
import ProgressBar from "../../../components/ProgressBar";
import constant from "../../../constants/ConstantValues";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import DashboardTableSkaleton from "../../../components/Skeletons/DashboardTableSkaleton";

const ProcessorDashboard = () => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const apiStatus = useSelector((state) => state?.prePro?.apiStatus);
  const autoRefresh = useSelector((state) => state?.prePro?.autoRefresh);
  const {
    PROJECT_HEADER,
    PROJECT_NAME,
    BATCH_NAME,
    COUNT,
    PDFS,
    ASSIGNED,
    OCR_STATUS,
    LAST_UPDATED,
    ACTION,
  } = constant;
  const projectFromState = useSelector(
    (state) => state?.prePro?.preProProjects
  );

  const type = useSelector((state) => state?.prePro?.type);

  useEffect(() => {
    setProjects(projectFromState.data);
  }, [type, projectFromState.data]);

  useEffect(() => {
    dispatch(clearApiStatus());
    dispatch(uploadedTagClear());
    dispatch(clearAutoRefresh());
    const autoRefresh = false;
    dispatch(
      pre.getPreProProjects({
        type: "",
        formattedStartDate: "",
        formattedEndDate: "",
        status: "",
        autoRefresh,
      })
    );
  }, [dispatch]);

  // useEffect(() => {
  //   if (apiStatus && apiStatus !== undefined) {
  //     if (apiStatus?.loading === false && apiStatus.message != null && apiStatus?.next === true) {
  //       setMessage(apiStatus.message);
  //       setSeverity("success")
  //       setNotification(true)

  //     }
  //     else if (apiStatus?.next === false && apiStatus.message != null) {
  //       setMessage(apiStatus.message);
  //       setSeverity("error")
  //       setNotification(true)

  //     }
  //   }
  // }, [apiStatus]);

  useEffect(() => {
    if (autoRefresh?.isAutoRefresh && autoRefresh?.isAutoRefresh) {
      const autoRefresh = true;
      dispatch(
        pre.getPreProProjects({
          type: "",
          formattedStartDate: "",
          formattedEndDate: "",
          status: "",
          autoRefresh,
        })
      );
      dispatch(clearAutoRefresh());
    }
  }, [autoRefresh]);

  useEffect(() => {
    if (projectFromState.data?.length > 0) {
      setProjects(projectFromState.data);
    }
  }, [projectFromState.data]);

  const onSearch = (word, type) => {
    let result;
    let filteredProjects = [];

    if (type === "project") {
      if (word) {
        result = projectFromState?.data.filter(function (el) {
          return (
            el?.project_name
              .toString()
              .toLowerCase()
              .indexOf(word.toLowerCase()) !== -1
          );
        });
      } else {
        result = projectFromState;
      }
    } else {
      if (word) {
        const res = projectFromState?.data?.map((item, index) => {
          const filteredBatches = item?.batch_info?.filter(function (el) {
            return (
              el?.batch_name
                .toString()
                .toLowerCase()
                .indexOf(word.toLowerCase()) !== -1
            );
          });
          if (filteredBatches.length > 0) {
            filteredProjects.push({
              ...projectFromState?.data[index],
              batch_info: filteredBatches,
            });
          } else {
          }
        });
        result = filteredProjects;
      } else {
        result = projectFromState?.data;
      }
    }
    setProjects([...result]);
  };

  const filterByDate = (startDate, endDate, status) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

    dispatch(
      pre.getPreProProjects({
        type,
        formattedStartDate,
        formattedEndDate,
        status,
      })
    );
  };
  const clear = () => {
    dispatch(clearApiStatus());

    dispatch(
      pre.getPreProProjects({
        type: "",
        formattedStartDate: "",
        formattedEndDate: "",
        status: "",
      })
    );
  };

  let header = [
    PROJECT_NAME,
    BATCH_NAME,
    COUNT,
    PDFS,
    ASSIGNED,
    OCR_STATUS,
    LAST_UPDATED,
    ACTION,
  ];

  return (
    <>
      <Header />
      <ActionBar
        onSearch={(value, type) => onSearch(value, type)}
        filterByDate={filterByDate}
        clear={clear}
      />

      <ProjectHeader
        text={PROJECT_HEADER}
        headerColor="white"
      />
      {projectFromState.loader ? (
        <DashboardTableSkaleton />
      ) : (
        <Box>
          <DashboardTable header={header} projects={projects} />
        </Box>
      )}
      {notification && (
        <CustomizedSnackbars
          severity={severity}
          state={notification}
          text={message}
          handleClose={() => setNotification(false)}
        />
      )}
    </>
  );
};
export default ProcessorDashboard;
