const values = {
   PDF: 'pdf',
   OUTPUT: 'output',
   TEMPLATE: 'template',
   PREVIOUS: 'Previous',
   SAVE: 'Save',
   GRANT: 'Grant Access',
   PROJECT_NAME: 'Project Name',
   DRUG_TYPE: 'Drug Type',
   UPLOAD_SAMPLE_BATCH_RECORD: 'Upload Sample / Master Batch Record',
   DATA_PARAMETER: "Import Data Parameters",
   DATA_FORMAT: "Import Data Format",
   PROJECT_HEADER: "Projects (Drug Products)",
   CREATE_BATCH: "Create Batch",
   BATCH_NAME: "Batch Name",
   TEXT_EXTRACTION: "Auto text extraction",
   SELECT: "Select user",
   TIMELINE: "Assign a Timeline",
   SUBJECT: "Subject",
   ASSIGN: "Assign User",
   SUBMIT_PROCESSING: "Submit For Processing",
   SUBMIT_VALIDATION: "Submit For Validation",
   PRE_NAME: "Pre Processor Name",
   PRO_NAME: "Processor Name",
   VAL_NAME: "Validator Name",
   STATUS: "Status",
   BATCH_DETAILS: "Batch Details",
   COUNT:"Count",
   PDFS: "PDF(s)",
   ASSIGNED:"Assigned To",
   OCR_STATUS: "OCR Status",
   LAST_UPDATED:"Last Updated",
   ACTION: "Action",
   END_DATE: "End Date",
   

}

export default Object.freeze(values);
