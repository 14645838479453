import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import OutlinedInput from "@mui/material/OutlinedInput";
import CloseIcon from "@mui/icons-material/Close";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import { useHistory } from "react-router-dom";
import "./DpCalculation.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataCalculation,
  calculateDataPoints,
  clearApiStatus,
} from "../../../redux/administrator/actions";
import ProgressBar from "../../../components/ProgressBar";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";

const Index = () => {
  const [fileName, setFileName] = useState([]);
  const [fileName1, setFileName1] = useState([]);
  const [fileName2, setFileName2] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const dataPoints = useSelector((state) => state?.admin?.dataPointCalculation);
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  console.log("data point response.........", dataPoints);
  const id = history?.location?.state?.id;
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus.loader === false) {
        if (apiStatus?.next === true && apiStatus?.loader === false) {
          setSuccess(true);
          setMessage(apiStatus?.message);
          setTimeout(() => {
            history.push("/data-point-details");
          }, 2000);
          dispatch(clearApiStatus());
        } else if (
          apiStatus?.message != null &&
          apiStatus?.message !== undefined &&
          apiStatus?.next === false
        ) {
          setMessage(apiStatus?.message);
          setErr(true);
          dispatch(clearApiStatus());
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    dispatch(clearApiStatus());
    dispatch(getDataCalculation(id));
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFileName(typeof value === "string" ? value.split(",") : value);
  };

  console.log("Print File names", fileName);

  const removeCaptureEffort = (names) => {
    console.log("events.....", names);
    const temp = [...fileName];
    // removing the element using splice
    temp.splice(names, 1);
    // updating the list
    setFileName(temp);
  };
  const removeCaptureEffort1 = (names) => {
    console.log("events.....", names);
    const temp = [...fileName1];
    // removing the element using splice
    temp.splice(names, 1);
    // updating the list
    setFileName1(temp);
  };
  const removeCaptureEffort2 = (names) => {
    console.log("events.....", names);
    const temp = [...fileName2];
    // removing the element using splice
    temp.splice(names, 1);
    // updating the list
    setFileName2(temp);
  };

  const calculateDP = () => {
    let data = {
      id: id,
      fileName: fileName,
      fileName1: fileName1,
      fileName2: fileName2,
    };
    console.log("clickk");
    dispatch(calculateDataPoints(data));
  };

  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader
              text="Manage Output Files"
              outPutButton={"Calculate"}
              Click={calculateDP}
            />
            <Grid container>
              <Grid item className="DpCalculation" md={4}>
                <Typography variant="subtitle2">Filled cell count</Typography>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label"></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={fileName}
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                    renderValue={(selected) => selected.join(", ")}
                    //   fullWidth
                  >
                    {dataPoints?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={fileName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box className="DpCalculation-list">
                  {fileName.map((names) => (
                    <Card className="DpCalculation-list-card" key={names}>
                      <Typography
                        className="DpCalculation-names"
                        variant="subtitle2"
                      >
                        {names}
                      </Typography>
                      <IconButton onClick={() => removeCaptureEffort(names)}>
                        <CloseIcon />
                      </IconButton>
                    </Card>
                  ))}
                </Box>
              </Grid>
              <Grid item className="DpCalculation" md={4}>
                <Typography variant="subtitle2">
                  Unique count
                </Typography>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label"></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={fileName1}
                    onChange={(e) => {
                      setFileName1(e.target.value);
                    }}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {dataPoints?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={fileName1.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box className="DpCalculation-list">
                  {fileName1.map((names) => (
                    <Card className="DpCalculation-list-card" key={names}>
                      <Typography
                        className="DpCalculation-names"
                        variant="subtitle2"
                      >
                        {names}
                      </Typography>
                      <IconButton onClick={() => removeCaptureEffort1(names)}>
                        <CloseIcon />
                      </IconButton>
                    </Card>
                  ))}
                </Box>
              </Grid>
              <Grid item className="DpCalculation" md={4}>
                <Typography variant="subtitle2">
                  Underscore count
                </Typography>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label"></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={fileName2}
                    onChange={(e) => {
                      setFileName2(e.target.value);
                    }}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {dataPoints?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={fileName2.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box className="DpCalculation-list">
                  {fileName2.map((names) => (
                    <Card className="DpCalculation-list-card" key={names}>
                      <Typography
                        className="DpCalculation-names"
                        variant="subtitle2"
                      >
                        {names}
                      </Typography>
                      <IconButton onClick={() => removeCaptureEffort2(names)}>
                        <CloseIcon />
                      </IconButton>
                    </Card>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {success && (
          <CustomizedSnackbars
            severity="success"
            state={success}
            text={message}
            handleClose={() => setSuccess(false)}
          />
        )}

        {err && (
          <CustomizedSnackbars
            severity="error"
            state={err}
            text={message}
            handleClose={() => setErr(false)}
          />
        )}

        {apiStatus?.loader === true && <ProgressBar></ProgressBar>}
      </Box>
    </>
  );
};

export default Index;
