import React from "react";
import "./HeaderButton.scss";
import { Button } from "@mui/material";

const HeaderButton = ({
  onClick,
  className,
  text,
  variant = "contained",
  startIcon,
  size,
  cusStyle,
  isDisabled = false,
}) => {
  return (
    <Button
      size={size || "large"}
      variant={variant}
      onClick={onClick}
      style={cusStyle}
      className={className}
      startIcon={startIcon}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
};

export default HeaderButton;
