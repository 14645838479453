import {
  date,
  date_time,
  numeric,
  percentage,
  scientific,
  string,
  time,
} from "./config";
import moment from "moment";
import { isValid } from "date-fns";

const IsNumericData = (trimmedValue, validResult, range) => {
  if (!/^\d+(\.\d+)?$/.test(trimmedValue)) {
    validResult = { error: true, helperText: numeric.errorMessage };
  } else if (isNaN(trimmedValue)) {
    validResult = { error: true, helperText: numeric.errorMessage };
  } else if (
    trimmedValue &&
    range &&
    range?.min &&
    range?.max &&
    (range?.min > parseInt(trimmedValue) || parseInt(trimmedValue) > range?.max)
  ) {
    validResult = { error: true, helperText: numeric.errorMessage };
  } else if (
    trimmedValue &&
    range &&
    range?.min &&
    range?.max &&
    (range?.min > parseFloat(trimmedValue) ||
      parseFloat(trimmedValue) > range?.max)
  ) {
    validResult = { error: true, helperText: numeric.errorMessage };
  }

  return validResult;
};

const IsStringData = (trimmedValue, validResult) => {
  if (trimmedValue?.length < string.min || trimmedValue?.length > string.max) {
    validResult = { error: true, helperText: string.errorMessage };
  }

  return validResult;
};

const IsDateData = (trimmedValue, validResult) => {
  let isValidDateFormat = false;
  let isValidDate = false;

  isValidDate = isValid(trimmedValue);
  isValidDateFormat = moment(trimmedValue, date.formats, true).isValid(); // true
  if (!isValidDate && !isValidDateFormat) {
    validResult = { error: true, helperText: date.errorMessage };
  }
  return validResult;
};

const IsDateAndTimeData = (trimmedValue, validResult) => {
  let isValidDateTime = false;

  isValidDateTime = moment(trimmedValue, date_time.formats, true).isValid();
  if (!isValidDateTime) {
    validResult = { error: true, helperText: date_time.errorMessage };
  }

  return validResult;
};
const IsTimeData = (trimmedValue, validResult) => {
  let isValidTime = false;
  let isValid = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/.test(trimmedValue);
  // isValidTime = trimmedValue;
  // console.log(isValid)
  if (!isValid) {
    validResult = { error: true, helperText: time.errorMessage };
   } 
  //else if (isValidTime != isValid) {
  //   validResult = { error: true, helperText: time.errorMessage };
  // }

  return validResult;
}

const IsPercentageData = (trimmedValue, validResult, range) => {
  if (!/^\d+(\.\d+)?%$/.test(trimmedValue)) {
    validResult = { error: true, helperText: percentage.errorMessage };
  } else if (
    trimmedValue &&
    range &&
    range?.min &&
    range?.max &&
    (range?.min > parseInt(trimmedValue) || parseInt(trimmedValue) > range?.max)
  ) {
    validResult = { error: true, helperText: percentage.errorMessage };
  }

  return validResult;
};

const IsScientificData = (trimmedValue, validResult) => {
  let isValueContainDot = false;
  let isValueContainExpoParameter = false;
  let isLastValueContainTwoDigits = false;
  let isStringContaiainRemeaningNumbers = false;
  let isBetweenDotAndEContainNumbers = false;
  let isStringContainOnlyOneDot = false;

  if (trimmedValue.includes(".")) {
    isValueContainDot = true;
  }

  if (trimmedValue.includes("e+") || trimmedValue.includes("E+")) {
    isValueContainExpoParameter = true;
  }

  if (
    parseInt(trimmedValue.substring(trimmedValue.indexOf("+") + 1)) &&
    trimmedValue.substring(trimmedValue.indexOf("+") + 1).length == 2 &&
    !isNaN(trimmedValue.substring(trimmedValue.indexOf("+") + 1))
  ) {
    isLastValueContainTwoDigits = true;
  }

  if (
    parseInt(trimmedValue.substr(0, trimmedValue.indexOf("."))) >= 0 &&
    !isNaN(trimmedValue.substr(0, trimmedValue.indexOf(".")))
  ) {
    isStringContaiainRemeaningNumbers = true;
  }

  if (
    parseInt(
      trimmedValue
        .toLowerCase()
        .substring(
          trimmedValue.toLowerCase().indexOf(".") + 1,
          trimmedValue.toLowerCase().lastIndexOf("e")
        )
    ) &&
    !isNaN(
      trimmedValue
        .toLowerCase()
        .substring(
          trimmedValue.toLowerCase().indexOf(".") + 1,
          trimmedValue.toLowerCase().lastIndexOf("e")
        )
    )
  ) {
    isBetweenDotAndEContainNumbers = true;
  }

  if (
    (trimmedValue?.match(/\./g) || []).length &&
    (trimmedValue?.match(/\./g) || []).length === 1
  ) {
    isStringContainOnlyOneDot = true;
  }

  if (
    !(
      isValueContainDot &&
      isValueContainExpoParameter &&
      isLastValueContainTwoDigits &&
      isStringContaiainRemeaningNumbers &&
      isBetweenDotAndEContainNumbers &&
      isStringContainOnlyOneDot
    )
  ) {
    validResult = { error: true, helperText: scientific.errorMessage };
  }

  return validResult;
};


const Validation = (dataType, range, value) => {
  let validResult = { error: false, helperText: "" };
  if (value) {
    {
      let trimmedValue;
      if (typeof value === "string") {
        trimmedValue = value?.trim();
      } else 
      trimmedValue = value

      switch (dataType) {
        case "string":
          return IsStringData(trimmedValue, validResult);

        case "numeric":
          return IsNumericData(trimmedValue, validResult, range);

        case "date":
          return IsDateData(trimmedValue, validResult);

        case "date_time":
          return IsDateAndTimeData(trimmedValue, validResult);

        case "percentage":
          return IsPercentageData(trimmedValue, validResult, range);

        case "scientific":
          return IsScientificData(trimmedValue, validResult);

        case "time":
          return IsTimeData(trimmedValue, validResult);

        default:
          validResult = { error: false, helperText: "" };
      }
    }
    return validResult;
  } else {
    validResult = { error: false, helperText: "" };
  }
};
export default Validation;
