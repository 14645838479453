export const dataTypes = [
  {
    type: "Text",
    value: "string",
  },
  {
    type: "Date",
    value: "date",
  },
  {
    type: "Date With Time",
    value: "date_time",
  },
  {
    type: "Number",
    value: "numeric",
  },
  {
    type: "Percentage",
    value: "percentage",
  },
  {
    type: "Scientific",
    value: "scientific",
  },
  {
    type: "Time",
    value: "time",
  },
];

export const sheets = [
  {
    name: "Sheet 1",
    value: "sheet1",
  },

  {
    name: "Sheet 2",
    value: "sheet2",
  },
  {
    name: "Sheet 3",
    value: "sheet3",
  },
  {
    name: "Sheet 4",
    value: "sheet4",
  },
];
