import React, { useState } from "react";
import { Button, Divider, Menu, MenuItem } from "@mui/material";

const AddMoreRowTD = ({
  rowId,
  addRowsInBetween,
  deleteRow,
  showRowHeadersMenu,
}) => {
  const [rowAnchorEl, setRowAnchorEl] = React.useState(null);
  const [indexOfRowOrColToBeAdd, setIndexOfRowOrColToBeAdd] = useState(null);

  const handleAddBetweenRowClose = () => {
    setRowAnchorEl(null);
    setIndexOfRowOrColToBeAdd(null);
  };

  const handleRowClose = (pos) => {
    indexOfRowOrColToBeAdd && addRowsInBetween(indexOfRowOrColToBeAdd, pos);
    setRowAnchorEl(null);
    setIndexOfRowOrColToBeAdd(null);
  };

  const handleDeleteRow = (pos) => {
    indexOfRowOrColToBeAdd && deleteRow(indexOfRowOrColToBeAdd);
    setRowAnchorEl(null);
    setIndexOfRowOrColToBeAdd(null);
  };

  const handleAddBetweenRowClick = (e, id) => {
    setRowAnchorEl(e.currentTarget);
    setIndexOfRowOrColToBeAdd(id);
  };

  return (
    <>
      <Button
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) =>
          showRowHeadersMenu && handleAddBetweenRowClick(e, rowId)
        }
        // disabled={isPreview || !isAddRowInBetween}
      >
        {parseInt(rowId) + 1}
      </Button>

      {showRowHeadersMenu && (
        <Menu
          anchorEl={rowAnchorEl}
          keepMounted
          open={Boolean(rowAnchorEl)}
          onClose={handleAddBetweenRowClose}
        >
          <MenuItem
            onClick={(e) => {
              handleRowClose("above");
            }}
          >
            Insert Row Above
          </MenuItem>
          <Divider light />
          <MenuItem
            onClick={(e) => {
              handleRowClose("below");
            }}
          >
            Insert Row Below
          </MenuItem>
          <Divider light />
          <MenuItem
            onClick={(e) => {
              handleDeleteRow();
            }}
          >
            Delete Row
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

AddMoreRowTD.defaultProps = {
  showRowHeadersMenu: true,
};

export default AddMoreRowTD;
