import React from "react";
import LandingPage from "./components/AuthPages/landing/LandingPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Switch as ReactRoutes, Route } from "react-router-dom";
import AllRoutes from "./routes/routes";
import "./css/utility.scss";

const theme = createTheme({
  // typography: {
  //   fontFamily: "var(--font-family)",
  // },
});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ReactRoutes>
          <Route exact path="/" component={LandingPage} props={this.props} />
          <Route path="/" render={(props) => <AllRoutes {...props} />} />
        </ReactRoutes>
      </ThemeProvider>
    );
  }
}

export default App;
