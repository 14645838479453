import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
class Service {
  Login(body) {
    return axiosService.Post(`api/login/`, body);
  }

  userLogout = () => {
    return axiosService.Get(`api/logout/`);
  };

  forgotPasswordLink = (userName, passwordResetLink) => {
    const data = {
      body: { username: userName, password_reset_link: passwordResetLink },
    };
    return axiosService.Post(`api/send-reset-password-link/`, data);
  };

  forgotPassword = (userName, newPassword) => {
    const data = {
      body: { username: userName, password: newPassword },
    };
    return axiosService.Post(`api/update-password/`, data);
  };

  saveUserSignature(filePath) {
    const data = {
      body: { signature_path: filePath },
    };
    return axiosService.Post(`api/upload-e-signature/`, data);
  }
}

export default Service;