import { initialState } from "./initialState";
import {
  API_STATUS,
  CLEAR_API_STATUS,
  GET_LIST,
  GET_VALI_DATA,
  PDF_AND_PAGE_DETAILS,
  SET_SELECTED_FILES,
  VALIDATE_USER_STATUS,
  IS_SIGNATURE_UPLOADED_STATUS,
} from "./types";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };
    case GET_LIST:
      return {
        ...state,
        valProject: action.payload,
      };
    case CLEAR_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case GET_VALI_DATA:
      return {
        ...state,
        coorData: action.payload,
      };

    case PDF_AND_PAGE_DETAILS: {
      return {
        ...state,
        pdfAndPageDetails: action.payload.data,
      };
    }
    case VALIDATE_USER_STATUS:
      return {
        ...state,
        validateUser: action.payload,
      };
    case IS_SIGNATURE_UPLOADED_STATUS:
      return {
        ...state,
        signUploaded: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
