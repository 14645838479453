import { Button, Checkbox, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Label from "../../label/Label";
import CloseIcon from "@mui/icons-material/Close";
import "./DataMapTable.scss";

const isDisabled = (parameterData) => {
  if (parameterData?.validatorRowRejection?.isRejected) {
    return true;
  } else {
    const isRejectedWithinCell = parameterData.mappings.find(
      (ele) => ele?.validatorCellRejection?.isRejected
    );
    if (isRejectedWithinCell) {
      return true;
    } else return false;
  }
};
const ValidationButton = ({
  onClickValidate,
  parameterData,
  parameterIndex,
}) => {
  return (
    <>
      <div className="sub-row validatior">
        {parameterData?.rowValidation?.isValidated ? (
          <div className="validator-edit">
            <Tooltip
              title={`Validated On :${parameterData?.rowValidation?.date}`}
              placement="top"
            >
              <IconButton>
                <CloseIcon
                  onClick={(e) => onClickValidate("invalidate", parameterIndex)}
                />
                {/* <Label
              className="validation-info"
              text={`Validated On :${parameterData?.rowValidation?.date}`}
            /> */}
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div className="checkbox-wrap ">
            <input
              type="checkbox"
              // checked={isChecked()}
              onChange={(e) => onClickValidate("validate", parameterIndex)}
              disabled={isDisabled(parameterData)}
              color={"red"}
            />
          </div>
          // <Checkbox
          //   onClick={(e) => onClickValidate("validate", parameterIndex)}
          //   disabled={isDisabled(parameterData)}
          // />
          // <Button
          //   className="btn-validate"
          //   onClick={(e) => onClickValidate("validate", parameterIndex)}
          //   disabled={isDisabled(parameterData)}
          // >
          //   Validate
          // </Button>
        )}
      </div>
    </>
  );
};

export default ValidationButton;
