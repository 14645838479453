import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router";
import { messages } from "../../constants/commonMessages";
import moment from "moment";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import "./DashboardTable.scss";
import Label from "../../components/label/Label";
import PriorityHighIcon from "@mui/icons-material/ReportProblem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as acts from "../../redux/processor/actions";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DraftIcon from "../../assets/draft-svgrepo-com.svg";
import CloneModal from "../Modal/clonModal/CloneModal";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { action } from "../../constants/action";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import InfoModal from "../../components/Modal/infoModal/InfoModal";

function Row(props) {
  const { project, i } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [batchAnchorEl, setBatchAnchorEl] = useState(null);
  const [menus, setMenus] = useState([]);
  const [batchMenus, setBatchMenus] = useState([]);
  const [list, setList] = useState();
  const MyOptions = ["Edit"];
  const openpop = Boolean(anchorE2);
  const history = useHistory();
  const [clone, setClone] = useState(false);
  const infoData = useSelector((state) => state?.pro?.infoData);
  const [infoModal, setInfoModal] = useState(false);
  const handleClick = (index) => (event) => {
    menus[index] = true;
    setAnchorEl(event.currentTarget);
    setMenus(menus);
  };
  const handleClose = (index) => () => {
    menus[index] = false;
    setAnchorEl(null);
    setMenus(menus);
  };

  const onCreacteNewBatch = (projectId) => {
    localStorage.setItem(messages.PROJECT_ID, projectId);
    localStorage.setItem("projectName", row.project_name);
    localStorage.setItem("projectId", row.project_id);
    localStorage.setItem("drugType", row.drug_type);
    history.push({ pathname: "/newbatch" });
  };
  const onClone = (projectId) => {
    setClone(true);
    localStorage.setItem(messages.PROJECT_ID, projectId);
    localStorage.setItem("projectName", row.project_name);
    localStorage.setItem("projectId", row.project_id);
  };
  const onCloseClone = () => {
    setClone(false);
  };
  const dispatch = useDispatch();
  const handleProjectClick = (row) => {
    const { project_name, is_map_data, project_id } = row;
    localStorage.setItem("projectName", project_name);
    localStorage.setItem("projectId", project_id);
    localStorage.setItem("drugType", row.drug_type);
    localStorage.setItem("mapData", row.is_map_data);
    localStorage.setItem("isCloned", row.is_cloned);
    // if (!row.is_pdf_upload) {
    //   history.push("/upload-batch-record");
    //   return;
    // }
    if (row.template_n_op_step_done) {
      history.push("/map-data");
      return;
    }
    if (!row.is_pdf_uploaded) {
      history.push("/upload-batch-record");
      return;
    }
    if (!row.is_template_upload) {
      history.push("/upload-template-data");
      return;
    }
    if (row.template_n_op_step_done) {
      history.push("/map-data");
      return;
    }
    history.push("/map-data");
  };
  //-----------------------------new-------------------------------------

  useEffect(() => {
    localStorage.setItem("count", 0);
  }, []);
  const handleClickOption = (e, list) => {
    setList(list);
    setAnchorE2(e.currentTarget);
  };

  const handleClickOnOption = (option) => {
    if (localStorage.getItem("userRole") === "validator") {
      if (option === "Edit") {
        localStorage.setItem("batchId", list.batch_id);
        localStorage.setItem("projectName", row.project_name);
        localStorage.setItem("batchName", list.batch_name);
        localStorage.setItem("projectId", row.project_id);
        if (list.is_validator_view === false) {
          dispatch(acts.isProcessView(list.batch_id));
        }
        const project_id = localStorage.getItem("projectId");
        console.log("on edit show id", project_id);
        // debugger
        history.push(`/data-validation`);
      }
    } else if (localStorage.getItem("userRole") === "processor") {
      if (option === "Edit") {
        localStorage.setItem("projectId", row.project_id);
        localStorage.setItem("projectName", row.project_name);
        localStorage.setItem("batchName", list.batch_name);
        localStorage.setItem("batchId", list.batch_id);
        if (list.is_processor_view === false) {
          dispatch(acts.isProcessView(list.batch_id));
        }
        history.push("/data-entry");
      }
    } else if (localStorage.getItem("userRole") === "pre-processor") {
      if (option === "Edit") {
        localStorage.setItem("projectName", row.project_name);
        localStorage.setItem("projectId", row.project_id);
        localStorage.setItem("batchName", list.batch_name);
        localStorage.setItem("batchId", list.batch_id);
        localStorage.setItem("drugType", row.drug_type);

        localStorage.setItem("ocrReInitiate", list?.ocr_re_initiate);
        if (list?.is_pdf_uploaded && list?.ocr_re_initiate) {
          history.push("/upload-pdf-against-batch");
        } else if (list?.is_pdf_uploaded) {
          history.push("/prepro-user-permission");
        } else {
          history.push("/upload-pdf-against-batch");
        }
      }
    }
  };
  const handleData = (data) => { };
  const handleClosePopUp = () => {
    setAnchorE2(null);
  };
  const handleProjectInfo = (row) => {

    dispatch(acts.projectInfo(row?.project_id));
    setTimeout(() => {
      setInfoModal(true)
    }, 1000);

  }
  // useEffect(() => {
  //   if (infoData && infoData != "undefined") {
  //     if (infoData.length > 1) {
  //       console.log("CLICKed......................")
  //       setInfoModal(true)
  //     }
  //   }

  // }, [infoData]);

  const { row } = props;
  return (
    <>
      <TableRow className="row-wrap">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="td" scope="row">
          {row?.new_batch && (
            <FiberNewOutlinedIcon
              className="new-icon"
            />
          )}
          {row?.total_rejected_batches > 0 && (
            <PriorityHighIcon
              color="warning"
              className="priority-reject-icon"
            ></PriorityHighIcon>
          )}
          {localStorage.getItem("userRole") === "pre-processor" &&

            <Tooltip title="info">
              <InfoIcon onClick={() => handleProjectInfo(row)} color="action" ></InfoIcon>
            </Tooltip>

          }

          <div className="p-name">
            <div>{row.project_name} </div>


            <Stack
              direction="row"
              className="drugType-wrap"
            >
              <Chip
                label={row.drug_type}
                size="small"
                className="drugType"
              />
              <Label
                fontSize={12}
                fontWeight={500}
                text={`${row.extraction_type}`}
                padding={"0px 0px 0px 5px"}
              />
            </Stack>
          </div>
        </TableCell>
        <TableCell></TableCell>
        {localStorage.getItem("userRole") === "pre-processor" && (
          <TableCell>
            {row?.total_batches} , {row?.total_rejected_batches}{" "}
          </TableCell>

        )}
        {localStorage.getItem("userRole") === "processor" && (
          <TableCell color="#1239ff">
            {row?.total_batches} , {row?.total_rejected_batches}
          </TableCell>
        )}
        {localStorage.getItem("userRole") === "validator" && (
          <TableCell color="#1239ff">
            {row?.total_batches} , {row?.total_rejected_batches}
          </TableCell>
        )}

        {localStorage.getItem("userRole") === "pre-processor" &&
          row?.total_pdfs >= 0 && <TableCell>{row?.total_pdfs}</TableCell>}

        {localStorage.getItem("userRole") === "pre-processor" && (
          <>
            <TableCell>{""}</TableCell>
            <TableCell>{""}</TableCell>
          </>
        )}

        {localStorage.getItem("userRole") === "validator" && (
          <>
            <TableCell></TableCell>
            <TableCell
            ></TableCell>
          </>
        )}

        {localStorage.getItem("userRole") === "processor" ? (
          <>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </>
        ) : (
          ""
        )}

        <TableCell>
          {moment(row.updated_on).format("DD-MMM-YYYY")}
          <br />
          {moment(row.updated_on).format("hh:mm:ss A")}
        </TableCell>
        {localStorage.getItem("userRole") === "processor" && (
          <TableCell>
            <Tooltip title="info">
              <InfoIcon color="action" onClick={() => handleProjectInfo(row)}></InfoIcon>
            </Tooltip>
          </TableCell>
        )}
        {localStorage.getItem("userRole") === "validator" && (
          <TableCell>
            <Tooltip title="info">
              <InfoIcon color="action" onClick={() => handleProjectInfo(row)}></InfoIcon>
            </Tooltip>
          </TableCell>
        )}
        {localStorage.getItem("userRole") === "pre-processor" ? (
          <TableCell>
            <IconButton
              aria-owns={anchorEl ? `simple-menu-${i}` : null}
              aria-haspopup="true"
              onClick={handleClick(i)}
              disabled={
                !row?.rejected_by_processor && row.is_role_grant === true
                  ? true
                  : false
              }
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              className="action-list"
              id={`simple-menu-${i}`}
              anchorEl={anchorEl}
              // keepMounted
              open={menus[i]}
              onClose={handleClose(i)}
            >
              <MenuItem
                className="action-item"
                onClick={() => handleProjectClick(row)}
              >
                <EditTwoToneIcon /> {action.EDIT}
              </MenuItem>
              {row?.mapping_status === "done" && (
                <MenuItem
                  className="action-item"
                  onClick={() => onCreacteNewBatch(row?.project_id)}
                >
                  <AddIcon /> {action.ADD_NEW_BATCH}
                </MenuItem>
              )}
              {row?.mapping_status === "done" && (
                <MenuItem
                  className="action-item"
                  onClick={() => onClone(row?.project_id)}
                >
                  <FileCopyIcon />
                  Clone Project
                </MenuItem>
              )}
            </Menu>
          </TableCell>
        ) : (
          ""
        )}
      </TableRow>

      {open &&
        row?.batch_info?.map((list) => (
          <>
            <TableRow className="hidden-row" key={list?.id}>
              <TableCell></TableCell>
              {localStorage.getItem("userRole") === "pre-processor" && (
                <TableCell className="float-right" >
                  {list?.rejected_by_processor === false && list?.is_processor_assigned === false && (
                    <FiberNewOutlinedIcon
                      className="new-icon"
                    />
                  )}
                  <Label
                    text={
                      list?.rejected_by_processor === true ? (
                        <PriorityHighIcon color="warning"></PriorityHighIcon>
                      ) : (
                        ""
                      )
                    }
                    color="#1239ff"
                  />
                </TableCell>
              )}
              {localStorage.getItem("userRole") === "processor" && (
                <TableCell className="float-right">
                  <Label
                    text={
                      list?.is_processor_view != true ? (
                        <IconButton>
                          <FiberNewOutlinedIcon
                            className="new-icon-batch"
                          />
                        </IconButton>
                      ) : "" || list?.rejected_by_validator ? (
                        <PriorityHighIcon color="warning"></PriorityHighIcon>
                      ) : (
                        ""
                      )
                    }
                    color="#1239ff"
                  />
                </TableCell>
              )}
              {localStorage.getItem("userRole") === "validator" && (
                <TableCell className="float-right">
                  <Label
                    text={
                      list.is_validator_view != true ? (
                        <IconButton>
                          <FiberNewOutlinedIcon
                            className="new-icon-batch"
                          />
                        </IconButton>
                      ) : (
                        ""
                      )
                    }
                    color="#1239ff"
                  />
                </TableCell>
              )}

              <TableCell>{list?.batch_name}</TableCell>
              <TableCell> </TableCell>
              {localStorage.getItem("userRole") === "pre-processor" && (
                <>
                  {list?.total_pdfs <= 1 && (
                    <TableCell>{list?.total_pdfs}</TableCell>
                  )}
                  {list?.total_pdfs > 1 && (
                    <TableCell>{list?.total_pdfs}</TableCell>
                  )}
                </>
              )}

              {localStorage.getItem("userRole") === "pre-processor" && (
                <>
                  {(list?.rejected_by_processor || list.ocr_re_initiate) && (
                    <TableCell>
                      <Tooltip title="[Rejected]">
                        <PriorityHighIcon
                          color="warning"
                        ></PriorityHighIcon>
                      </Tooltip>
                    </TableCell>
                  )}
                  {list?.rejected_by_processor === false &&
                    !list.ocr_re_initiate && (
                      <TableCell className="assigned-status">
                        {" "}
                        {list?.is_processor_assigned ? (
                          ""
                        ) : (
                          <Tooltip title="[Draft]">
                            <img className="img" src={DraftIcon} alt="" />
                          </Tooltip>
                        )}{" "}
                        {list?.processor}
                      </TableCell>
                    )}
                  {list?.is_ocr_done === "pending" && (
                    <TableCell className="text-center">
                      <Tooltip title="In Progress">
                        <HourglassTopIcon
                          color="primary"
                          className="hour-glass-anime"
                        />
                      </Tooltip>
                    </TableCell>
                  )}
                  {list?.is_ocr_done === "done" &&
                    <TableCell className="text-center">
                      <Tooltip title="Done">
                        <DoneAllIcon color="primary" />
                      </Tooltip>
                    </TableCell>
                  }
                  {list?.is_ocr_done === "not started" &&
                    <TableCell className="text-center">
                      <Tooltip title="Not Started">
                        <img className="img" src={DraftIcon} alt="" />
                      </Tooltip>
                    </TableCell>
                  }
                </>
              )}
              {localStorage.getItem("userRole") === "processor" && (
                <>
                  {list?.rejected_by_validator && (
                    <TableCell>
                      {" "}
                      <Tooltip title="[Rejected]">
                        <PriorityHighIcon
                          color="warning"
                        ></PriorityHighIcon>
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell>{list?.data_entry_end_date}</TableCell>
                  {list?.rejected_by_validator === false && (
                    <TableCell className="assigned-status">
                      {" "}
                      {list?.is_validator_assigned ? "" : <Tooltip title="[Draft]">
                        <img className="img"
                          src={DraftIcon} />
                      </Tooltip>}{" "}
                      {list?.validator}
                    </TableCell>
                  )}
                </>
              )}
              {localStorage.getItem("userRole") === "validator" && (
                <>
                  <TableCell> {list?.batch_validation_end_date}</TableCell>

                  {list?.rejected_by_administrator && (
                    <TableCell>
                      {" "}
                      <Tooltip title="[Rejected]">
                        <PriorityHighIcon
                          color="warning"
                        ></PriorityHighIcon>
                      </Tooltip>
                    </TableCell>
                  )}

                  {list?.rejected_by_administrator === false && (
                    <TableCell>
                      {list?.validation_completed === true ? (
                        <Tooltip title="Completed">
                          <DoneAllIcon color="primary" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="[Draft]">
                          <img className="img" src={DraftIcon} />
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </>
              )}

              <TableCell>
                {moment(list?.updated_on).format("DD-MMM-YYYY")}
                <br />
                {moment(list?.updated_on).format("hh:mm:ss A")}
              </TableCell>
              <TableCell>
                {" "}
                {localStorage.getItem("userRole") === "pre-processor" && (

                  <IconButton
                    disabled={
                      list?.is_processor_assigned === true ||
                      list?.is_ocr_done === "pending" ||
                      list?.rejected_by_processor === true
                    }
                    aria-label="more"
                    onClick={(e) => handleClickOption(e, list)}
                    aria-haspopup="true"
                    aria-controls="long-menu"
                  >
                    {list?.is_processor_assigned != true &&
                      list?.is_ocr_done != "pending" &&
                      list?.rejected_by_processor != true && (
                        <MoreVertIcon
                          onClick={() =>
                            list?.is_ocr_done === "not started" ||
                              list?.is_ocr_done === "done"
                              ? handleData()
                              : ""
                          }
                        />
                      )}
                  </IconButton>
                )}
                {localStorage.getItem("userRole") === "processor" && (
                  <IconButton
                    disabled={list?.is_validator_assigned === true}
                    aria-label="more"
                    onClick={(e) => {
                      handleClickOption(e, list);
                    }}
                    aria-haspopup="true"
                    aria-controls="long-menu"
                  >
                    {list?.is_validator_assigned != true && (
                      <MoreVertIcon
                        onClick={() =>
                          list?.is_validator_assigned != true
                            ? handleData()
                            : ""
                        }
                      />
                    )}
                  </IconButton>
                )}
                {localStorage.getItem("userRole") === "validator" && (
                  <IconButton
                    disabled={
                      list?.rejected_by_administrator === false &&
                      list?.validation_completed === true
                    }
                    aria-label="more"
                    onClick={(e) => handleClickOption(e, list)}
                    aria-haspopup="true"
                    aria-controls="long-menu"
                    Menu
                  >
                    {(list?.validation_completed === false ||
                      list?.rejected_by_administrator === true) && (
                        <MoreVertIcon onClick={() => handleData()} />
                      )}
                  </IconButton>
                )}
                <Menu
                  anchorEl={anchorE2}
                  onClose={handleClosePopUp}
                  open={openpop}
                >
                  {MyOptions.map((option) => (
                    <MenuItem
                      key={option}
                      onClick={() => {
                        handleClickOnOption(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </TableCell>
            </TableRow>
          </>
        ))}

      {clone && (
        <CloneModal open={clone} onCloseClone={onCloseClone}></CloneModal>
      )}
      {infoModal && (
        <InfoModal isOpen={infoModal} handleClose={(() => setInfoModal(false))} infoData={infoData}></InfoModal>
      )
      }
    </>
  );
}

export default function DashboardTable(props) {
  const { header, projects } = props;
  return (
    <>
      <Box
        className="dashboard-wrap"
      >
        <TableContainer
          className="dashBoardTh"
        // component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                {header?.map((list) => {
                  return (
                    <TableCell className="table-header-title">{list}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="tBody">
              {projects &&
                projects?.length > 0 &&
                projects?.map((project, i) => (
                  <Row
                    key={project?.project_id}
                    row={project}
                    i={i}
                    props={props}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
