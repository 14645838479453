/* eslint-disable no-empty */
import React, { useState, useEffect } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Grid, TextField } from "@mui/material";
import "./mergePdf.scss";
import List from "@mui/material/List";
import CheckboxTitle from "../../../components/UploadBatchRecord/CheckboxTitle/CheckboxTitle";
import SelectedFileList from "../../../components/UploadBatchRecord/SelectedFileList/SelectedFileList";
import constants from "../../../constants/ConstantValues";
import BottomButtonsList from "../../../components/UploadBatchRecord/BottomButtonsList/BottomButtonsList";
import { useDispatch, useSelector } from "react-redux";
import {
  listBoxFiles,
  mergePdfFiles,
} from "../../../redux/administrator/actions";
import { useHistory } from "react-router";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import HeaderButton from "../../../components/Controls/buttons/HeaderButton";
import ProgressBar from "../../../components/ProgressBar";

const Index = (props) => {
  const dispatch = useDispatch();
  const { GRANT, PREVIOUS } = constants;
  const [selectedName, setSelectedButton] = useState("pdfs");
  const [selectedBottomButton, setBottomButtonName] = useState(PREVIOUS);
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  let propsData = props?.location?.state;
  if (propsData?.value) {
    localStorage.setItem("pvalue", propsData.value);
    localStorage.setItem("folderName", propsData.folderName);
    // localStorage.setItem('fileType', propsData.)
  }
  let apiStatus = useSelector((state) => state?.admin?.uploadData);
  let group = useSelector((state) => state?.admin?.boxFiles);
  const history = useHistory();

  useEffect(() => {
    setGroupData(group);
  }, [group]);

  useEffect(() => {
    let pdata = localStorage.getItem("pvalue");
    let data = {
      folderId: pdata,
      fileType: "pdfs",
    };
    dispatch(listBoxFiles(data));
  }, [dispatch]);
  useEffect(() => {
    if (apiStatus && apiStatus != undefined) {
      if (apiStatus.loader === false) {
        setLoader(false);
        if (apiStatus.message != null && apiStatus.next === true) {
          setMessage(apiStatus?.message);
          setSeverity("success");
          setIsOpen(true);
          setTimeout(() => {
            history.push("/merge-pdf");
          }, 2000);
        }
        // else{
        //     setMessage(apiStatus?.message)
        //     setIsOpen(true)
        //     setSeverity("error")
        // }
      }
    }
  }, [apiStatus]);

  const handleToggle = (value) => () => {
    const currentIndex = checkedFiles.indexOf(value);
    let newChecked = [...checkedFiles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFiles(newChecked);
  };

  const removeSelectedFile = (index) => {
    let addedFiles = [...checkedFiles];
    if (index > -1) {
      addedFiles.splice(index, 1);
      setCheckedFiles(addedFiles);
    }
  };

  // const res = useSelector((state) => state?.admin?.uploadData);
  // if (res?.message === "submitted") {
  //     isOpen = true;
  //     text = "Uploaded";
  //     severity = "success";
  // }

  const onSelectBottomButton = (selectedButton = "") => {
    setBottomButtonName(selectedButton);
    if (/[^a-zA-Z0-9\_]/.test(fileName)) {
      setMessage("File Name cannot contain special character");
      setIsOpen(true);
      setSeverity("error");
    } else if (selectedButton === PREVIOUS) {
      history.push("/merge-pdf");
    } else if (selectedButton === GRANT) {
      let data = {
        body: {
          file_name: fileName,
          client_file_details: checkedFiles,
        },
      };
      if (fileName === "") {
        setIsOpen(true);
        setSeverity("error");
        setMessage("Please enter Pdf Name");
      }
      if (checkedFiles?.length > 1) {
        dispatch(mergePdfFiles(data));
        setLoader(true);

        // setTimeout(() => {
        //   history.push("/review-batch-records-details");
        // }, 1000);
      } else {
        setIsOpen(true);
        setSeverity("error");
        setMessage("Please select atleast two file");
      }
    }
  };

  const onSelectHeaderButton = (buttonSelected = "") => {
    setSelectedButton(buttonSelected);

    let data = {
      folderId: localStorage.getItem("pvalue"),
      fileType: buttonSelected,
    };
    dispatch(listBoxFiles(data));
  };


  return (
    <>
      <Header page="new-project" />
      {loader ? <ProgressBar /> : ""}

      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => setIsOpen(false)}
          severity={severity}
          state={isOpen}
          text={message}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <Box className="listPage-graybox"></Box>
            <Grid container className="merge-pdf-header">
              <Grid item md={5}>
                <HeaderButton
                  className={"header-btn"}
                  text={"pdf"}
                  cusStyle={{
                    margin: "12px",
                    minWidth: "230px",
                    height: "38px",
                  }}
                ></HeaderButton>
              </Grid>
              <Grid item md={7}>
                <TextField
                  size="small"
                  label="PDF Name"
                  onChange={(event) => setFileName(event.target.value)}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5}>
                <List className="scroller check-box-style">
                  {groupData?.map((value, index) => {
                    return (
                      <CheckboxTitle
                        key={index}
                        checked={checkedFiles}
                        onClick={(e) => handleToggle(e)}
                        value={value}
                      ></CheckboxTitle>
                    );
                  })}
                </List>
              </Grid>

              <Grid className="scroller" item xs={7}>
                <Grid container className="scroller-container" spacing={1}>
                  {checkedFiles.map((value, index) => (
                    <Grid key={index} item xs={2} sm={5.8} md={5.8}>
                      <SelectedFileList
                        onClick={() => {
                          removeSelectedFile(index);
                        }}
                        value={value}
                      ></SelectedFileList>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <BottomButtonsList
                  onClick={(e, action) => {
                    onSelectBottomButton(e, action);
                  }}
                  selectedKey={selectedBottomButton}
                  position="end"
                  headerColor="#FFFFF"
                  isNextDisable={
                    fileName !== "" && checkedFiles.length > 0 ? false : true
                  }
                  isPrevious={true}
                ></BottomButtonsList>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
