import React from "react";
import {
  Button,
  Grid,
  InputAdornment,
  Box,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./PasswordChange.scss";
import { messages } from "../../../constants/commonMessages";
import * as acts from "../../../redux/auth/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import aventiorLogo from "../../../assets/CPV-Auto-logoN.png";
import CustomizedSnackbars from "../../snackbars/CustomizedSnackbars";
import { notification } from "../../../constants/notificationMessages";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
class ChangePassword extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      newPassword: "",
      confirmPassword: "",
      emailErrorMessage: "",
      passwordErrorMessage: "",
      inValidUserErrorMessage: "",
      isLoading: false,
      isOpenForgotPasswordDialogBox: false,
      isSuccessMsg: "",
      showPassword: false,
      showConfPassword: false,
      sucessNotification: true,
    };
  }

  componentDidMount() {
    this.props.acts.clearForgoPasswordStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.forgotPasswordStatus &&
      nextProps.forgotPasswordStatus !== undefined
    ) {
      if (
        nextProps.forgotPasswordStatus.message !== "" ||
        nextProps.forgotPasswordStatus.status === false
      ) {
        this.setState({
          isErrorMsg: nextProps.forgotPasswordStatus.message,
        });
      }
      if (nextProps.forgotPasswordStatus?.next === true) {
        this.setState({
          isSuccessMsg: this?.props?.forgotPasswordStatus.message,
          isLoading: true,
        });
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
          //this.props.history.push("/");
          this.props.history.push("/");
        }, 1500);
      }

      if (
        nextProps.forgotPasswordStatus.message !== "" ||
        (nextProps.forgotPasswordStatus.loader === false &&
          nextProps.forgotPasswordStatus?.next === false)
      ) {
        this.setState({
          tagNameError: true,
          isMessage: nextProps.forgotPasswordStatus.message,
        });
      }
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleNewPasswordChange = (e) => {
    this.setState({
      newPassword: e.target.value,
      inValidUserErrorMessage: "",
    });
  };

  handleConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      inValidUserErrorMessage: "",
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const validPassword = new RegExp(
      "^(?=.*[0-9])" +
        "(?=.*[a-z])(?=.*[A-Z])" +
        "(?=.*[@#$%^&+=])" +
        "(?=\\S+$).{8,20}$"
    );
    const isValidPassword = validPassword.test(this.state.confirmPassword);

    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        inValidUserErrorMessage: notification.inValidPasswordErrorMessage,
        isLoading: false,
      });
    } else if (!isValidPassword) {
      this.setState({
        inValidUserErrorMessage: notification.invalidPasswordQuality,
        isLoading: false,
      });
    } else if (isValidPassword) {
      this.setState({
        inValidUserErrorMessage: "",
      });
      this.props.acts.forgotPasswordPermission(
        this.state.email,
        this.state.confirmPassword
      );
      // setTimeout(() => {
      //   this.props?.history?.push("/")
      // }, 1500);
    }
    localStorage.setItem(messages.USER_EMAIL_IS, this.state.email);
    localStorage.removeItem("invalidPassword");
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowConfPassword = () => {
    this.setState({ showConfPassword: !this.state.showConfPassword });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Box component="main" className="logInImg">
        <Grid container className="forgotpass-wrap">
          <Grid className="forgotpass-form-wrap" item xs={8} md={4}>
            <div className="loginFormWrap">
              <img src={aventiorLogo} />
            </div>
            <h5>Reset Password</h5>
            <form onSubmit={this.handleSubmit} className="forgot-pass-form">
              <FormControl fullWidth={true}>
                <div>
                  <label>User Name</label>
                  <OutlinedInput
                    className="input-wrap"
                    fullWidth={true}
                    required
                    onChange={this.handleEmailChange}
                    name="email"
                  />
                </div>

                <div>
                  <label>New Password </label>
                  <OutlinedInput
                    className="input-wrap"
                    fullWidth={true}
                    //label="NewPassword"
                    type={this.state.showPassword ? "text" : "password"}
                    required
                    onChange={this.handleNewPasswordChange}
                    name="Newpassword"
                    value={this.state.newPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>

                <div>
                  <label>Confirm Password</label>

                  <OutlinedInput
                    className="input-wrap"
                    fullWidth={true}
                    //label="ConfirmPassword"
                    type={this.state.showConfPassword ? "text" : "password"}
                    required
                    onChange={this.handleConfirmPasswordChange}
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showConfPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                {this.state.inValidUserErrorMessage && (
                  <div className="error_messge">
                    <ErrorOutlineIcon color="error" />
                    <div className="error">
                      <label>{this.state.inValidUserErrorMessage}</label>
                    </div>
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  className={
                    !(this?.state?.newPassword && this?.state?.confirmPassword)
                      ? "forgot-disabled"
                      : "forgot-continue"
                  }
                  // className="forgot-continue"
                  onClick={this.handleSubmit}
                  disabled={
                    !(this?.state?.newPassword && this?.state?.confirmPassword)
                  }
                >
                  Continue
                </Button>
              </FormControl>
            </form>
          </Grid>
        </Grid>
        {this?.props?.forgotPasswordStatus?.next === true &&
          this?.props?.forgotPasswordStatus.message !== "" && (
            <CustomizedSnackbars
              severity="success"
              state={this?.state?.sucessNotification}
              text={this?.props?.forgotPasswordStatus.message}
              handleClose={() => this.props?.acts?.clearForgoPasswordStatus()}

              // handleClose={() =>
              //   this.setState({
              //     sucessNotification: false,
              //   })
              // }
            />
          )}
        {this?.props?.forgotPasswordStatus?.next === false &&
          this?.props?.forgotPasswordStatus.loader === false &&
          this?.props?.forgotPasswordStatus.message !== "" && (
            <CustomizedSnackbars
              severity="error"
              state={this?.props?.forgotPasswordStatus.message}
              text={this?.props?.forgotPasswordStatus.message}
              handleClose={() => this.props?.acts?.clearForgoPasswordStatus()}
            />
          )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotPasswordStatus: state.auth.forgotPasswordStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
