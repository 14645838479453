import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./CustomizedSnackbars.scss"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function CustomizedSnackbars(props) {
  const { state, handleClose, text, severity } = props;
  const [open, setOpen] = useState(state);
  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Stack className="cust-snackbar-wrap" spacing={2} >
      <Snackbar
      className="cust-snackbar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        onClick={handleClick}
      >
        <Alert onClose={handleClose} severity={severity} className="cust-snackbar-wrap">
          {text}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
