import { Card, CardContent, CardHeader, Box } from "@mui/material";
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import "./activityCard.scss";
import IconButton from "@mui/material/IconButton";
import AuditDataTable from "../AuditTrailComponent/AuditDataTable";

export default function ActivityCard(props) {
  const [open, setOpen] = useState(false);
  const { details } = props;
  const handleClick = () => {
    setOpen(!open);
  };
  return details?.map((index) => {
    return (
      <Box className="activityCard-wrapper">
        <Card className="activity-container">
          <div className="activity-grey-container"></div>
          <Box classname="activitycard-innerBox w-100">
            <CardHeader
              className="activityCard-header"
              avatar={<Avatar aria-label="recipe">R</Avatar>}
              title={index.username}
              subheader={`Activity Date . ${index.activity_date}`}
            />
            <CardContent className="activityCard-content">
              <ul className="ul-item">
                <li key={index.username} className="list-item">
                  <span>User Name</span>
                  <span>{index.username}</span>
                </li>
                <li className="list-item">
                  <span>Activity Name</span>
                  <span>{index.activity_name}</span>
                </li>
                <li className="list-item">
                  <span>User Group</span>
                  <span>{index.user_group}</span>
                </li>
                <li className="list-item">
                  <span>Activity Data</span>
                  <span>{index.activity_data}</span>
                </li>
              </ul>
            </CardContent>
            <div
              className="expanLess-wrap"
              onClick={handleClick}
            >
              {open ? (
                <IconButton>
                  <ExpandLess />
                </IconButton>
              ) : (
                <IconButton>
                  <ExpandMore />
                </IconButton>
              )}
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {/* <CardContent> */}
              <Box
              className="activity-details">
                <AuditDataTable />
              </Box>
              {/* </CardContent> */}
            </Collapse>
          </Box>
        </Card>
      </Box>
    );
  });
}
