import {
  ADD_GROUP,
  EDIT_GROUP,
  GET_GROUP_LIST,
  DELETE_GROUP,
  API_STATUS,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
  GET_USER_LIST,
  GET_DELETED_DATA,
  LIST_BOX_FILES,
  UPLOAD_BOX_FILES,
  GET_OUT_PUT_LIST,
  GET_OUT_PUT_URL,
  REJECT_OUT_PUT_FILE,
  SUBMIT_OUT_PUT_FILE,
  BATCH_TYPE,
  UPLOAD_DATA,
  PRODUCT_TYPE,
  PRODUCT_SUB_FOLDER,
  MANAGE_GROUP,
  GET_BATCH_DATA,
  REJECT_OUT_PUT_FILE_BATCH,
  GET_AUDIT_LIST,
  CLEAR_API_STATUS,
  GET_AUDIT_DETAILS,
  LIST_BATCH_RECORD,
  SUBMIT_OUT_PUT_FILE_UPLOAD,
  GET_USER_ACTIVITY,
  GET_USER_NAME,
  GET_FILTERED_USER_ACTIVITY,
  GET_DATA_POINT_CALCULATION,
  GET_DP_DETAILS,
  GET_MERGE_BATCHES,
  GET_MERGE_PROJECTS,
  API_STATUS_MASTER,
  CREATE_TEMPLATE,
  GET_MERGED_FILES,
  CLEAR_GET_MERGED_FILES,
} from "./types";
import { initialState } from "./initialState";
import { errorMessage } from "../../constants/responseMessages";
import { messages } from "../../constants/commonMessages";
import AdministratorService from "../../services/administratorService";
import UploadBatchAPI from "../../services/uploadBatchService";

const service = new AdministratorService();
const uploadBatchAPI = new UploadBatchAPI();

export const getManageGroupsList = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getManageGroupList()
      .then((res) => {
        dispatch({
          type: GET_GROUP_LIST,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getAuditTrailList = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service.getAuditTrailList(data).then((res) => {
      dispatch({
        type: GET_AUDIT_LIST,
        payload: res.data,
      }).catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
    });
  };
};

export const getAuditDetails = (project_id, batch_id) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service.getAuditDetails(project_id, batch_id).then((res) => {
      dispatch({
        type: GET_AUDIT_DETAILS,
        payload: res.data,
      }).catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
    });
  };
};
export const getUserActivity = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .getUserActivity(data)
      .then((res) => {
        dispatch({
          type: GET_USER_ACTIVITY,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_ACTIVITY,
          payload: [
            {
              message: errorMessage(error),
              loader: false,
              next: false,
            },
          ],
        });
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

// export const getFilteredUserActivity = (data) => {
//   return (dispatch) => {
//     dispatch({
//       type: API_STATUS,
//       payload: [{ message: "", loader: true, next: false }],
//     });
//     service
//       .getFilteredUserActivity(data)
//       .then((res) => {
//         dispatch({
//           type: GET_FILTERED_USER_ACTIVITY,
//           payload: res.data.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: GET_FILTERED_USER_ACTIVITY,
//           payload: [
//             {
//               message: errorMessage(error),
//               loader: false,
//               next: false,
//             },
//           ],
//         });
//       });
//   };
// };
export const getManageGroup = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: [{ message: "", loader: true, next: false }],
    });
    service
      .getManageGroup()
      .then((res) => {
        dispatch({
          type: MANAGE_GROUP,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: MANAGE_GROUP,
          payload: [
            {
              message: errorMessage(error),
              loader: false,
              next: false,
            },
          ],
        });
      });
  };
};

export const getUsersByGroup = (groupName) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .getUsersByGroup(groupName)
      .then((res) => {
        dispatch({
          type: GET_USER_NAME,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const addNewGroup = (name) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: [{ message: "", loader: true, next: false }],
    });
    service
      .addNewGroup({ name: name })
      .then((json) => {
        dispatch({
          type: ADD_GROUP,
          payload: { message: "group added", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_GROUP,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const deleteGroup = (userId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_GROUP,
      payload: [],
    });
    service
      .deleteGroup(userId)
      .then((json) => {
        dispatch({
          type: DELETE_GROUP,
          payload: { message: "group deleted", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_GROUP,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const editGroup = (name, id) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .updateGroup({ name: name }, id)
      .then((json) => {
        dispatch({
          type: EDIT_GROUP,
          payload: { message: "group edited", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_GROUP,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

//------------------------- manage Users action

export const getUserList = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getUserList(data)
      .then((res) => {
        dispatch({
          type: GET_USER_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_LIST,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const addNewUser = (name) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .addNewUser(name)
      .then((json) => {
        dispatch({
          type: ADD_USER,
          payload: { message: "user added", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_USER,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const deleteUser = (userId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: [],
    });
    service
      .deleteUser(userId)
      .then((json) => {
        dispatch({
          type: DELETE_USER,
          payload: { message: "user deleted", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_USER,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const editUser = (name, id) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .editUser(name, id)
      .then((json) => {
        dispatch({
          type: EDIT_USER,
          payload: { message: "user edited", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_USER,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

// ----------------data deletion

export const getDeletedData = (obj) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getDeletedData(obj)
      .then((res) => {
        dispatch({
          type: GET_DELETED_DATA,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DELETED_DATA,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

// ------------ upload batch

export const listBoxFiles = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: [{ ...initialState?.apiStatus, loader: true }],
    });
    uploadBatchAPI
      .listBoxFiles(data)
      .then((res) => {
        dispatch({
          type: LIST_BOX_FILES,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LIST_BOX_FILES,
          payload: [
            {
              ...initialState?.apiStatus,
              message: errorMessage(error),
              loader: false,
              next: false,
            },
          ],
        });
      });
  };
};

export const uploadBoxFiles = (fileType, requestBody) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: [{ ...initialState?.apiStatus, loader: true }],
    });
    uploadBatchAPI
      .uploadBoxFiles(fileType, requestBody)
      .then((res) => {
        dispatch({
          type: UPLOAD_BOX_FILES,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_BOX_FILES,
          payload: [
            {
              ...initialState?.apiStatus,
              message: errorMessage(error),
              loader: false,
              next: false,
            },
          ],
        });
      });
  };
};

//------------------- Manage out put
export const ApiStatus = (CLEAR_API_STATUS) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_API_STATUS,
      payload: {},
    });
  };
};

export const getOutPutList = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getManageOutput(data)
      .then((res) => {
        dispatch({
          type: GET_OUT_PUT_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getOutPutUrl = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getManageOutputUrl(data)
      .then((res) => {
        dispatch({
          type: GET_OUT_PUT_URL,
          payload: res.data.data,
          loader: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const rejectExcelOutput = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .rejectExcelOutput(data)
      .then((json) => {
        dispatch({
          type: REJECT_OUT_PUT_FILE,
          payload: { message: "Rejected", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const rejectoutputFileBatch = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .rejectoutputFileBatch(data)
      .then((json) => {
        dispatch({
          type: REJECT_OUT_PUT_FILE_BATCH,
          payload: { message: "Rejected", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const submitOutputUpload = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .submitOutputUpload(data)
      .then((res) => {
        dispatch({
          type: SUBMIT_OUT_PUT_FILE_UPLOAD,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const submitOutput = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .submitOutput(data)
      .then((json) => {
        dispatch({
          type: SUBMIT_OUT_PUT_FILE,
          payload: { message: "submitted", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getBatchRecordType = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus },
    });
    service
      .getBatchRecordType(data)
      .then((res) => {
        dispatch({
          type: BATCH_TYPE,
          payload: res.data,
        });
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: res?.data?.message,
            loader: false,
            next: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const uploadClientData = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .uploadClientData(data)
      .then((json) => {
        dispatch({
          type: UPLOAD_DATA,
          payload: { message: "submitted", loader: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getDrugTypeList = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { message: "", loader: true, next: false },
    });
    service
      .getDrugTypeList()
      .then((res) => {
        dispatch({
          type: PRODUCT_TYPE,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
          },
        });
      });
  };
};
export const getProductTypeSubFolder = (data) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SUB_FOLDER,
      payload: { message: "", loader: true, next: false },
    });
    service
      .getProductTypeSubFolder(data)
      .then((res) => {
        dispatch({
          type: PRODUCT_SUB_FOLDER,
          payload: { message: "", loader: false, data: res.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            message: errorMessage(error),
            loader: false,
            data: [],
          },
        });
      });
  };
};

export const getBatchDetailsList = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getBatchDetailsList(data)
      .then((res) => {
        dispatch({
          type: GET_BATCH_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getBatchRecordList = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getBatchRecordList(data)
      .then((res) => {
        dispatch({
          type: LIST_BATCH_RECORD,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getDataCalculation = (id) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState, loader: true },
    });
    service
      .getDataCalculation(id)
      .then((res) => {
        dispatch({
          type: GET_DATA_POINT_CALCULATION,
          payload: res.data.data,
          loader: false,
          next: false,
        });
        dispatch({
          type: API_STATUS,
          payload: { ...initialState?.apiStatus, loader: false, next: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState,
            msg: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const calculateDataPoints = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .calculateDataPoints(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: { ...initialState?.apiStatus, message: res?.data?.message,  loader: false, next: true },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const clearApiStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_API_STATUS,
      payload: {},
    });
  };
};

export const getDpCalculation = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getDpCalculation(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getDpDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getDpDetails(data)
      .then((res) => {
        dispatch({
          type: GET_DP_DETAILS,
          payload: res.data,
        });
        dispatch({
          type: API_STATUS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getMergeProjectList = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getMergeProjectList()
      .then((res) => {
        dispatch({
          type: GET_MERGE_PROJECTS,
          payload: res.data.data,
        });
        dispatch({
          type: API_STATUS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const submitFinalOpFilesData = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS_MASTER,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .submitFinalOpFilesData(data)
      .then((res) => {
        dispatch({
          type: API_STATUS_MASTER,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS_MASTER,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const clearMergeBatches = () => {
  return (dispatch) => {
    dispatch({
      type: GET_MERGE_BATCHES,
      payload: [],
    });
  };
};

export const getMergeProjectBatches = (id) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getMergeProjectBatches(id)
      .then((res) => {
        dispatch({
          type: GET_MERGE_BATCHES,
          payload: res.data.data,
        });
        dispatch({
          type: API_STATUS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const mergePdfFiles = (data) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState.apiStatus, loader: true },
    });
    service
      .mergePdfFiles(data)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: res?.data?.message,
            loader: false,
            next: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            msg: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const getMergedFiles = () => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS_MASTER,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getMergedFiles()
      .then((res) => {
        dispatch({
          type: GET_MERGED_FILES,
          payload: res.data.data,
        });
        dispatch({
          type: API_STATUS_MASTER,
          payload: {
            ...initialState.apiStatus,
            message: res?.data?.message,
            loader: false,
            next: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS_MASTER,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const clearGetMergedFiles = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_GET_MERGED_FILES,
      payload: [],
    });
  };
};

export const createTemplates = (data, template_name) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .createTemplates(data, template_name)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: res?.data?.message,
            loader: false,
            next: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: {
            ...initialState.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
export const getAuditHistory = (batchId) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS_MASTER,
      payload: { ...initialState?.apiStatus, loader: true },
    });
    service
      .getAuditHistoryDetail(batchId)
      .then((res) => {
        dispatch({
          // type: GET_AUDIT_H,
          payload: res.data.data,
        });
        dispatch({
          type: API_STATUS_MASTER,
          payload: {
            ...initialState.apiStatus,
            message: res?.data?.message,
            loader: false,
            next: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS_MASTER,
          payload: {
            ...initialState?.apiStatus,
            message: errorMessage(error),
            loader: false,
            next: false,
          },
        });
      });
  };
};
