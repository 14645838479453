export const API_STATUS = "API_STATUS";
export const DELETE_PDF = "DELETE_PDF";
export const PRE_PRO_PROJECTS = "PRE_PRO_PROJECTS";
export const CLEAR_API_STATUS = "CLEAR_API_STATUS";
export const UPLOADED_TEMPLATE_AND_PARAMETERS =
  "UPLOADED_TEMPLATE_AND_PARAMETERS";
export const GET_TAGS = "GET_TAGS";
export const UPLOADED_BATCH_PDFS = "UPLOADED_BATCH_PDFS";
export const GET_USER_LIST = "GET_USER_LIST";
export const IS_SIGNATURE_UPLOADED_STATUS = "IS_SIGNATURE_UPLOADED_STATUS";
export const ASSIGN_USER_API_STATUS = "ASSIGN_USER_API_STATUS";
export const VALIDATE_USER_STATUS = "VALIDATE_USER_STATUS";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const GET_UPLOADED_PDF_TAGS = "GET_UPLOADED_PDF_TAGS";
export const GET_COOR_DATA = "GET_COOR_DATA";
export const GET_PROJECT_TYPE_LIST = "GET_PROJECT_TYPE_LIST";
export const GET_PROJECT_TYPE = "GET_PROJECT_TYPE";
export const GET_OUTPUT_FORMAT_LIST = "GET_OUTPUT_FORMAT_LIST";
export const GET_SHEETS_LIST = "GET_SHEETS_LIST";
export const AUTO_REFRESH = "AUTO_REFRESH";
export const SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE = "SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE"