import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import LeftSideBar from "../../../../components/LeftSideBar/LeftSideBar";
import { Grid, Box, Typography, Tooltip } from "@mui/material";
// import ProjectHeader from '../../../../components/header/ProjectHeader'
import AdministratorHeader from "../../../../components/header/AdministratorHeader";
import IconButton from "@mui/material/IconButton";
import { borderBottom } from "@mui/system";
import "./auditHistory.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ViewCarouselTwoTone } from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import JsonViewerModal from "../../../../components/Modal/jsonmodal/JsonViewerModal";
import * as acts from "../../../../redux/administrator/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
function Index() {
  const dispatch = useDispatch();
  const dateList = ["11 Feb 2011 | 11.30 pm", "12 Feb 2011 | 11.30 pm"];
  const [date, setDate] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(acts.getAuditHistory());
  }, [dispatch]);

  const showJsonModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text="History" isArrow="true" />
            <Grid item md={12}>
              <Box
                className="project-title"
              >
                <Box
                  className="project-title-main"
                >
                  <Typography sx={{ fontSize: "12px", marginTop: "-10px" }}>
                    Project Name
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                    Project 01
                  </Typography>
                </Box>
                <Box
                  className="project-title-main"
                >
                  <Typography className="projectName">
                    Batch Name
                  </Typography>
                  <Typography className="projectName_1">
                    Batch 01
                  </Typography>
                </Box>
                <Box className="project-title-main"

                >
                  <Typography className="projectName">
                    PreProcessor
                  </Typography>
                  <Typography className="projectName_1">
                    Kripal
                  </Typography>
                </Box>
                <Box
                  className="project-title-main"
                >
                  <Typography className="projectName">
                    Processor
                  </Typography>
                  <Typography className="projectName_1">
                    Diwakar
                  </Typography>
                </Box>
                <Box
                  className="project-title-main"
                >
                  <Typography className="projectName">
                    Validator
                  </Typography>
                  <Typography className="projectName_1">
                    Priyanka
                  </Typography>
                </Box>
              </Box>
              <Box className="auditHistory-outer">
                {dateList.map((e) => {
                  return (
                    <>
                      {
                        <Box
                          className={
                            date === e ? "date-time-div" : "date-time-div1"
                          }
                          onClick={() => {
                            setDate(e);
                          }}
                        >
                          {e}
                        </Box>
                      }
                    </>
                  );
                })}
              </Box>
              <Box className="auditHistory-wrap">
                <Table
                  // sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {/* <TableHead>
                      <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell>Calories</TableCell>
                        <TableCell>Fat&nbsp;(g)</TableCell>
                        <TableCell>Carbs&nbsp;(g)</TableCell>
                        <TableCell>Protein&nbsp;(g)</TableCell>
                      </TableRow>
                    </TableHead> */}
                  <TableBody>
                    <TableRow>
                      <TableCell>Prepared On</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Wash Syringe Volume</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5
                        <IconButton onClick={() => showJsonModal()}>
                          <ErrorOutlineIcon />
                        </IconButton>

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dilution Factor For Cell Count</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pre Dilution Factor</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Cells/Ml</TableCell>
                      <TableCell>test2</TableCell>
                      <TableCell>test3</TableCell>
                      <TableCell>test4</TableCell>
                      <TableCell>test5</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <JsonViewerModal
                isOpen={open}
                handleClose={closeModal}
              ></JsonViewerModal>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Index;