import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import "../../../css/utility.scss";

const StyledButton = styled(Button)({
  borderRadius: "10px",
  textTransform: "none",
  margin: "8px",
  // color:'#4096FC'
});

export default function Buttons(props) {
  const {
    text,
    size,
    colors,
    variant,
    onClick,
    startIcon,
    type,
    disabled,
    ...other
  } = props;

  return (
    <StyledButton
      variant={variant}
      size={size}
      color={colors}
      onClick={onClick}
      {...other}
      startIcon={startIcon}
      type={type}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
}

Buttons.defaultProps = {
  variant: "contained",
  color: "primary",
  lable: "Save",
  size: "medium",
  text: "Button",
  type: "text",
  disabled: false,
};
