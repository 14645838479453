import { Grid, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "./footer.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Checkbox from "@mui/material/Checkbox";

export default function MapFooter(props) {
  const {
    pdfCount,
    isDisableMultipageMappingLable,
    onClickFooterAction,
    isShowReject,
    isPrevious,
    batchRejection,
  } = props;

  return (
    <Grid container className="MapTableFooter">
      <Grid item md={6}>
        {!isDisableMultipageMappingLable && (
          <Stack className="StackPage" direction="row" spacing={1}>
            <Chip className="page-no" label="Page1 - Page2" />
            <Chip className="page-no" label="Page1 - Page2" />
          </Stack>
        )}
      </Grid>
      <Grid item md={6} className="MapTbleFbuttons">
        {isShowReject && (
          <div className="checkBox">
            <Tooltip title="Reject Batch" arrow>
              <Checkbox
                checked={batchRejection}
                onChange={(e) => onClickFooterAction("reject", e)}
              />
            </Tooltip>
          </div>
          // <Button onClick={(e) => onClickFooterAction("reject")}>Reject</Button>
        )}

        {isPrevious && (
          <Button
            size="medium"
            onClick={(e) => onClickFooterAction("previous")}
            startIcon={<ArrowBackIosIcon />}
          >
            Previous
          </Button>
        )}
        <Button onClick={(e) => onClickFooterAction("cancel")}>Cancel</Button>
        <Button
          onClick={(e) => onClickFooterAction("save")}
          disabled={batchRejection}
        >
          save
        </Button>
        <Button
          className="btn-contained"
          onClick={(e) => onClickFooterAction("saveAndNext")}
        >
          Save & Next
        </Button>
      </Grid>
    </Grid>
  );
}

MapFooter.defaultProps = {
  isShowReject: true,
  batchRejection: false,
};
