/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useIdleTimer } from "react-idle-timer";
import TimeOutModal from "./TimeOutModal";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as acts from "../redux/auth/actions";

const IdealTimeoutHooks = () => {
  const timeout = 600000000;
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [isIdle, setIsIdle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();

  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
    }, 1000);
  }, []);

  const handleClose = () => {
    setShowModal(false);
    handleReset();
    handleOnActive();
    setIsTimedOut(false);
  };

  const handleLogout = () => {
    setShowModal(false);
    dispatch(acts.userLogout());
    history.push("/");
    handleReset();
  };

  useEffect(() => {
    if (isIdle) {
      if (isTimedOut) {
        dispatch(acts.userLogout());
        history.push("/");
        handleReset();
      } else {
        handleReset();
        setIsTimedOut(true);
        setShowModal(true);
        handleOnActive();
      }
    }
  }, [isIdle]);

  return (
    <div>
      {showModal && (
        <TimeOutModal
          remaining={remaining}
          showModal={showModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
        />
      )}
    </div>
  );
};

export default IdealTimeoutHooks;
