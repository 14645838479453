import React from "react";
import Header from "../../../components/header/Header";
import ProjectHeader from "../../../components/header/ProjectHeader";
import { DialogContent, DialogActions, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./preMapData.scss";
import MapFooter from "../../../components/Footer/MapFooter";
import { bindActionCreators } from "redux";
import * as acts from "../../../redux/pre_processor/actions";
import { connect } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import ProgressBar from "../../../components/ProgressBar";
// import ProjectStepper from "../../../components/Stepper/ProjectStepperCpvAuto";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Buttons from "../../../components/Controls/buttons/Button";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import MapDataContainer from "./mapDataContainer";
import { notification } from "../../../constants/notificationMessages";
import Label from "../../../components/label/Label";

class MapData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      sucessNotification: false,
      errorNotification: false,
      message: "",
      isFullView: false,
      leftgrid: 6,
      rightgrid: 6,
      isFullScreen: true,
      hideScreen: true,
      action: "",
    };
    // Create the child instance using react createRef
    this.child = React.createRef();
  }

  componentDidMount() {
    this.props.acts.clearApiStatus();
    this.props.acts.getCoorData(localStorage.projectId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.apiStatus.loader === true) {
      this.setState({
        loader: true,
      });
    }
    if (
      nextProps?.apiStatus.loader === false &&
      nextProps?.apiStatus.next === true
    ) {
      if (this.state.action === "save") {
        this.setState({
          loader: false,
          sucessNotification: true,
          errorNotification: false,
          message: notification.successMessage,
        });
      }
      if (this.state.action === "saveAndNext") {
        this.setState({
          loader: false,
          sucessNotification: true,
          errorNotification: false,
          message: notification.successMessage,
        });
        setTimeout(() => {
          this.setState({
            sucessNotification: false,
            message: "",
          });
          this.props.history.push("/pre-processor-dashboard");
        }, 2000);
      }
    }
    if (
      nextProps?.apiStatus.loader === false &&
      nextProps?.apiStatus.message != null
    ) {
      this.setState({
        loader: false,
        sucessNotification: false,
        errorNotification: true,
        message: nextProps?.apiStatus.message,
      });
    }
  }

  onClickFooterAction = (action) => {
    this.setState({
      action: action,
    });

    if (action === "cancel") {
      this.props.history.push("/pre-processor-dashboard");
    } else if (action == "previous") {
      this.props.history.push("/upload-template-data");
    } else {
      this.child.current.mapData(action);
    }
  };

  handleCloseFullView = () => {
    this.setState({ isFullView: false });
  };

  handleOpenFullView = () => {
    this.setState({ isFullView: true });
  };

  slideScreentoMaximize = () => {
    // this.setState({ leftgrid: 2 })
    this.setState({ rightgrid: 12 });
    this.setState({ isFullScreen: false });
    this.setState({ hideScreen: false });
  };
  slideScreentoMinimize = () => {
    this.setState({ leftgrid: 6 });
    this.setState({ rightgrid: 6 });
    this.setState({ isFullScreen: true });
    this.setState({ hideScreen: true });
  };

  render() {
    return (
      <div>
        <Header />
        {/* <ProjectStepper currentStep={3} /> */}

        <div className="premap-data-container">
          {this.state.isFullScreen ? (
            <div className="slide-button">
              <Buttons
                size="small"
                onClick={() => this.slideScreentoMaximize()}
                text="Hide PDF"
                startIcon={<ArrowLeftIcon />}
                variant="outlined"
              />
            </div>
          ) : (
            <div className="slide-button">
              <Buttons
                size="small"
                onClick={() => this.slideScreentoMinimize()}
                text="Show PDF"
                startIcon={<ArrowRightIcon />}
                variant="outlined"
              />
            </div>
          )}

          <Typography className="project-batch-header">
            {localStorage.projectName + " - " + localStorage.drugType}
          </Typography>
          {/* <div
             className="premap-fullview-icon"
            > */}
          <IconButton
            aria-label="fullView"
            // onClick={(e) => this.handleOpenFullView()}
          >
            {/* <FullscreenOutlinedIcon /> */}
            <Typography className="current-step">Map Data</Typography>
          </IconButton>

          {/* </div> */}
        </div>

        {this?.state?.isFullView ? (
          <Dialog
            fullScreen={true}
            open={this?.state?.isFullView}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            keepMounted={true}
          >
            <DialogActions className="dialog-style">
              {this.state.isFullScreen ? (
                <div className="slide-button">
                  <Buttons
                    size="medium"
                    onClick={() => this.slideScreentoMaximize()}
                    text="Hide PDF"
                    startIcon={<ArrowLeftIcon />}
                  />
                </div>
              ) : (
                <div className="slide-button">
                  <Buttons
                    size="small"
                    onClick={() => this.slideScreentoMinimize()}
                    text="Show PDF"
                    startIcon={<ArrowRightIcon />}
                  />
                </div>
              )}
              <Typography>
                {localStorage.projectName + "-" + localStorage.drugType}
              </Typography>

              <IconButton
                className="fullview-close"
                aria-label="close"
                onClick={this.handleCloseFullView}
              >
                <CloseIcon />
              </IconButton>
            </DialogActions>

            <DialogContent className="fullscreen-table-wrap">
              <MapDataContainer
                child={this.child}
                isFullView={this?.state?.isFullView}
                hideScreen={this?.state?.hideScreen}
                leftgrid={this?.state?.leftgrid}
                rightgrid={this?.state?.rightgrid}
              />
            </DialogContent>
          </Dialog>
        ) : (
          <MapDataContainer
            hideScreen={this?.state?.hideScreen}
            leftgrid={this?.state?.leftgrid}
            rightgrid={this?.state?.rightgrid}
            child={this.child}
            isFullView={this?.state?.isFullView}
          />
        )}

        <MapFooter
          pdfCount={this.props?.coorData.length}
          isDisableMultipageMappingLable={true}
          onClickFooterAction={this.onClickFooterAction}
          isShowReject={false}
          isPrevious={true}
        />
        {this.state?.sucessNotification && (
          <CustomizedSnackbar
            severity="success"
            state={this.state?.sucessNotification}
            text={this.state?.message}
            handleClose={() =>
              this.setState({
                sucessNotification: false,
              })
            }
          />
        )}
        {this.state?.errorNotification && (
          <CustomizedSnackbar
            severity="error"
            state={this.state?.errorNotification}
            text={this.state.message}
            handleClose={() =>
              this.setState({
                errorNotification: false,
              })
            }
          />
        )}

        {this?.props?.apiStatus.loader ? <ProgressBar /> : ""}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acts: bindActionCreators(acts, dispatch),
  };
};

function mapStateToProps(state) {
  return {
    coorData: state.prePro?.coorData?.coordinatesDetails || [],
    apiStatus: state.prePro?.apiStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapData);
