import React from "react";
import Header from "../../../../components/header/Header";
import LeftSideBar from "../../../../components/LeftSideBar/LeftSideBar";
import { Grid, Box } from "@mui/material";
// import ProjectHeader from '../../../../components/header/ProjectHeader'
import AuditTable from "../../../../components/AuditTable";
import AdministratorHeader from "../../../../components/header/AdministratorHeader";

export default function index() {
  return (
    <>
      <Header page="new-project" />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text="Audit Trail" />
            <Grid item md={12}>
              <AuditTable />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
