import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { any, array, number } from "prop-types";
import pdfLogo from "../../../assets/pdf-logo.svg";
import "./SelectedFileList.scss";

// TODO : Add files array and replace static values
function SelectedFileList({ onClick, value }) {
  return (
    <>
      <ListItem className="added-list" disablePadding>
        <ListItemButton className="added-list-info">
          <IconButton>
            <img src={pdfLogo} />
          </IconButton>
          <ListItemText className="text-title" primary={value?.name} />
          <IconButton edge={"end"} onClick={onClick} size="small">
            <CloseIcon size="small" />
          </IconButton>
        </ListItemButton>
      </ListItem>
    </>
  );
}

SelectedFileList.propTypes = {
  index: number,
  onClick: any,
  value: any,
  fileArray: array,
};

export default SelectedFileList;
