import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Grid } from "@mui/material";
import "./ListPage.scss";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CheckBox } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, MenuItem, Menu } from "@mui/material";
import { useHistory } from "react-router";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";

const Index = () => {
  const Dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state?.admin?.group);
  let userData = [];

  let isOpen = false;
  let text = "";
  let severity = "";
  if (data?.message == "group deleted") {
    isOpen = true;
    text = "Group deleted";
    severity = "success";
    setTimeout(() => {
      Dispatch(acts.getManageGroupsList());
    }, 2000);
  } else {
    userData = data;
  }
  useEffect(() => {
    Dispatch(acts.getManageGroupsList());
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [groupdata, setGroupData] = React.useState(null);
  const MyOptions = ["Edit Group", "Delete Group"];
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleData = (data) => {
    return setGroupData(data);
  };

  const handleClickOnOption = (option) => {
    handleClose();
    if (option == "Edit Group") {
      history.push("/add-group", groupdata);
    } else {
      Dispatch(acts.deleteGroup(groupdata.id));
    }
  };

  return (
    <>
      <Header page="new-project" />
      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            isOpen = false;
          }}
          severity={severity}
          state={isOpen}
          text={text}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <Box className="listPage-graybox" 
            ></Box>
            <AdministratorHeader
              position="right"
              text="Add New Group"
              isShow={true}
              to="/add-group"
            />
            <Grid item md={12}>
              <TableContainer
              className="listPage-container"
                component={Paper}
              >
                <Table aria-label="simple table" className="group-table">
                  <TableHead className="group-table-th">
                    <TableRow className="group-table-row"
                    >
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Id</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Permission</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData?.length > 0 &&
                      userData?.map((data, index) => {
                        return (
                          <>
                            <TableRow className="listTabbleRow" key={data.id}>
                              <TableCell>
                                <span className="checkboxicon"></span>
                              </TableCell>
                              <TableCell align="left">{++index}</TableCell>
                              <TableCell align="left">{data?.name}</TableCell>
                              <TableCell align="left">
                                {data?.permissions}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="more"
                                  onClick={handleClick}
                                  aria-haspopup="true"
                                  aria-controls="long-menu"
                                >
                                  <MoreVertIcon
                                    onClick={() => {
                                      handleData(data);
                                    }}
                                  />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  open={open}
                                >
                                  {MyOptions.map((option) => (
                                    <MenuItem
                                      key={option}
                                      onClick={() => {
                                        handleClickOnOption(option);
                                      }}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
