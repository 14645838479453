import React, { useState } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import "./searchBar.scss";
import FormControl from "@mui/material/FormControl";

export default function DropDownSearch({
  option,
  handleChange,
  placeholder,
  // handleClearClick,
}) {
  const [selected, setSelected] = useState("");
  return (
    <div className="search-container">
      <FormControl fullWidth className="search-form">
        <InputLabel
          fullWidth
          className="DropDownSearch-label"
          labelId="demo-multiple-name-label"
          id="demo-multiple-name-label"
        >
          {placeholder}
        </InputLabel>
        <Select
          className="input-drop-select"
          value={selected}
          onChange={(event) => {
            handleChange(event.target.value);
            setSelected(event.target.value);
          }}
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
        >
          {option?.map((index) => {
            return (
              <MenuItem value={index?.name} selected="selected">
                {index?.name?.charAt(0).toUpperCase() + index?.name?.slice(1)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
