import AxiosService from "./axiosServices";
const axiosService = new AxiosService();

export default class PreProcessor {
  createNewProject = ({ projectName, drugType }) => {
    const data = { body: { project_name: projectName, drug_type: drugType } };
    return axiosService.Post(`api/create-project/`, data);
  };

  uploadMultipleFilesToServer = (fileContent) => {
    const jsonData = [];
    const fileNameList = [];
    for (let key in fileContent) {
      jsonData.push({
        pdfpath: fileContent[key].filePath,
        tagname: fileContent[key].tagName,
        original_filename: fileContent[key].fileName,
      });
      fileNameList.push(fileContent[key].fileName);
    }
    const data = {
      body: {
        files_paths: jsonData,
        project_id: localStorage.projectId,
        file_name_list: fileNameList,
      },
    };

    return axiosService.Post(`api/upload-batch-records/`, data);
  };

  getProjects = (obj) => {
    const { type, formattedStartDate, formattedEndDate, status } = obj;
    let params = {};

    if (type == "project") {
      params = {
        created_project_after: formattedStartDate,
        created_project_before: formattedEndDate,
      };
    } else {
      let boolStatus = "";
      if (status === "draft") {
        boolStatus = false;
      } else if (status === "assigned") {
        boolStatus = true;
      }

      params = {
        created_batch_after: formattedStartDate,
        created_batch_before: formattedEndDate,
        pre_pro_status: boolStatus,
      };
    }

    return axiosService.Get(`api/landing-page/`, {
      params: params,
    });
  };

  createBatch = (batchName, projectId) => {
    const data = {
      body: {
        project_id: projectId,
        batch_name: batchName,
      },
    };
    return axiosService.Post(`api/create-batch/`, data);
  };

  uploadTemplateAndOpFormat = (selectedFiles) => {
    const data = {
      body: {
        template_path: selectedFiles.templatePath,
        op_file_path: selectedFiles.opFormatPath,
        project_id: Number(selectedFiles.projectId),
      },
    };

    return axiosService.Post(`api/upload-template-and-op-format/`, data);
  };

  getUploadedTemplateAndOpFormat = (projectId) => {
    const data = {
      body: {
        project_id: projectId,
      },
    };

    return axiosService.Post(`api/get-template-and-op-detail/`, data);
  };

  getTags = (projectId) => {
    const data = {
      body: {
        project_id: projectId,
      },
    };

    return axiosService.Post(`api/get-tags/`, data);
  };

  uploadBatchPdfs = (selectedFiles, projectId, batchId, checked) => {
    let files_paths = [];
    let file_name_list = [];
    selectedFiles.map(
      (item) => (
        files_paths.push({
          pdfpath: item.pdfPath,
          original_filename: item.pdfName,
          tagname: item.tagName,
        }),
        file_name_list.push(item.pdfName)
      )
    );

    const data = {
      body: {
        project_id: projectId,
        batch_id: batchId,
        files_paths: files_paths,
        file_name_list: file_name_list,
        auto_extraction: checked,
      },
    };

    return axiosService.Post(`api/upload-batch-records/`, data);
  };

  getUserList = () => {
    return axiosService.Get(`api/get-users-list/`);
  };

  getTagsAndPdf = (projectId, batchId) => {
    const data = {
      body: {
        project_id: projectId,
        batch_id: batchId,
      },
    };

    return axiosService.Post(`api/get-tag-and-pdf/`, data);
  };
  esignHandle = (userName, userId, userRole, userPassword) => {
    const data = {
      body: {
        user_name: userName,
        user_id: userId,
        user_role: userRole,
        user_password: userPassword,
      },
    };
    return axiosService.Post(`api/verify-user/`, data);
  };

  isSignatureUploaded = () => {
    return axiosService.Get(`api/is-signature-uploaded/`);
  };

  userPermission = (authData) => {
    const data = {
      body: {
        username: authData.userName,
        project_id: authData.projectId,
        batch_id: authData.batchId,
        end_date: authData.endDate,
        subject: authData.subject,
        remark: authData.remark,
      },
    };
    return axiosService.Post(`api/assign-processor/`, data);
  };

  getUploadedPdfsWithTags = async (projectId) => {
    const data = {
      body: {
        project_id: projectId,
      },
    };

    const res = await axiosService.Post(`api/get-tag-and-pdf/`, data);
    return res;
  };

  getCoorData = (projectId) => {
    const data = {
      body: {
        project_id: projectId,
      },
    };

    return axiosService.Post(`api/get-details-for-mapping/`, data);
  };

  getS3Files = (fileType, subFolder) => {
    const params = { file_type: fileType, sub_folder: subFolder };
    return axiosService.Get(`api/list-dir/`, {
      params: params,
    });
  };

  mapData = (payloadData) => {
    const data = {
      body: payloadData,
    };

    return axiosService.Post(`api/save-mapping-details/`, data);
  };

  getSheetsNames = (project_id) => {
    const data = { body: { project_id: project_id } };
    return axiosService.Post(`api/get-sheet-names/`, data);
  };

  getOutputFormat = (subFolder) => {
    const params = { file_type: "op_format", sub_folder: subFolder };
    return axiosService.Get(`api/list-dir/`, {
      params: params,
    });
  };

  getProjectFiles = () => {
    return axiosService.Get(`api/get-drug-types/`, {});
  };

  clone = (projectName) => {
    const data = {
      body: { project_id: localStorage.projectId, project_name: projectName },
    };
    return axiosService.Post(`api/clone-project/`, data);
  };
}
