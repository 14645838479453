import React from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Grid, IconButton } from "@mui/material";
import "./AddGroup.scss";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import Buttons from "../../../components/Controls/buttons/Button";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import { messages } from "../../../constants/commonMessages";
const Index = (props) => {
  const [group, setGroup] = React.useState(props?.location?.state?.name);
  const Dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector((state) => state?.admin?.group);
  let isOpen = false;
  let text = "";
  let severity = "";
  if (data.message == "group added") {
    isOpen = true;
    text = "Group Added";
    severity = "success";
  } else if (data.message == "group edited") {
    isOpen = true;
    text = messages.GROUP_EDIT;
    severity = messages.SUCCESS;
  } else if (data.message == "error") {
    isOpen = true;
    text = messages.FAIL;
    severity = messages.ERROR;
  }

  const groupId = props?.location?.state?.id;

  const HandleSubmit = () => {
    if (groupId) {
      Dispatch(acts.editGroup(group, groupId));
      setTimeout(() => {
        history.push("/list-page");
      }, 2000);
    } else {
      Dispatch(acts.addNewGroup(group));
      setTimeout(() => {
        history.push("/list-page");
      }, 5000);
    }
  };

  return (
    <>
      <Header page="addgroup" />
      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            isOpen = false;
          }}
          severity={severity}
          state={isOpen}
          text={text}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <form
              onSubmit={(event) => {
                return event.preventDefault(), HandleSubmit();
              }}
            >
              <Box
              className="side-bar-wrap"             
              >
                <Link to="/list-page">
                  <IconButton
                  className="backButton"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
                <p
                className="addGroup-massage"
                >
                  {" "}
                  {groupId ? messages.EDIT_GROUP : messages.ADD_NEW_GROUP}
                </p>
              </Box>
              <Box
              className="addGrouip-wrap"
              >
                <div className="center-div">
                  <Typography variant="subtitle2">Group Name</Typography>
                  <TextField
                    placeholder="Group Name"
                    required
                    value={group}
                    onChange={(event) => setGroup(event.target.value)}
                    onInvalid={(e) => (e.target.style.border = "1px solid red")}
                  />
                </div>
              </Box>
              <Box className="bottom-div">
                <Buttons
                  variant="contained"
                  size="large"
                  text="save"
                  type="submit"
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
