import React from "react";
import PropTypes from "prop-types";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, TablePagination } from "@mui/material";
import Tables from "../../../components/Table/Table";
import * as acts from "../../../redux/administrator/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import "./reviewbatch.scss";
function ReviewBatchRecords(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const batchRecord = useSelector((state) => state.admin.batchRecord);
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(batchRecord?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  useEffect(() => {
    dispatch(
      acts.getBatchRecordList({
        page: page + 1,
        page_size: rowsPerPage,
      })
    );
  }, [dispatch, rowsPerPage]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setMessage(apiStatus?.message)
          setErr(true)
        }

      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (batchRecord?.data?.length > 0) {
      setRecords(batchRecord?.data);
    }
  }, [batchRecord?.data]);

  const handleBack = () => {
    history.push("/list-directory");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(
      acts.getBatchRecordList({
        page: value + 1,
        page_size: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <>
      <Header />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8} className="reviewbatch-main-grid">
            <Box
              className="reviewbatch-main-box"
            >
              <IconButton
                className="arrow-icon-button"
                onClick={handleBack}
              >
                <ArrowBackIcon />
              </IconButton>
              <p
                className="arrow-icon-button"
              >
                Detail record{" "}
              </p>
            </Box>
            <Box
             className="reviewbatch-table-box"
            >
              <Tables data={records} Type="Batch" />
            </Box>
            <Grid
              className="pagination-wrap"
            >
              <TablePagination
                component="div"
                rowsPerPageOptions={[15, 25, 50, 100]}
                count={parseInt(pageCount)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

// ReviewBatchRecords.propTypes = {}

export default ReviewBatchRecords;
