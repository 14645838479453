export const preProRoutes = {
  preProDashboard: "pre-processor-dashboard",
  newProject: "new-project",
  uploadBatchRecord: "upload-batch-record",
  newBatch: "newbatch",
  uploadTemplateData: "upload-template-data",
  uploadPdfAgainstBatch: "upload-pdf-against-batch",
  batchProcess: "batch-process",
  mapData: "map-data",
  preProUserPermission: "prepro-user-permission",
};

export const proRoutes = {
  proDashboard: "processor-dashboard",
  dataEntry: "data-entry",
  proUserPermission: "pro-user-permission",
};

export const valiRoutes = {
  valiDashboard: "validator-dashboard",
  dataValidation: "data-validation",
};

export const authRoutes = {
  changePassword: "change-password",
  forgotPassword: "forgot-password",
};

export const adminRoutes = {
  listPage: "list-page",
  addGroup: "add-group",
  manageUser: "manage-user",
  reviewBatchRecordDetails: "review-batch-records-details",
  userForm: "user-form",
  excelOutput: "excel-output",
  dataReport: "data-report",
  outputList: "output-list",
  previewOPFileDetails: "previewopfiledetails",
  auditUserActivity: "audit-user-activity",
  auditList: "audit-list",
  auditDetails: "audit-details",
  listDirectory: "list-directory",
  batchMonitor: "batch-monitor",
  auditHistory: "audit-history",
  dataPointCalculation: "data-point-calculation",
  dataPointDetails: "data-point-details",
  mergeOpFiles:"merge-op-files",
  uploadBatch: "upload-batch",
  mergePdf: "merge-pdf",
  mergePdfSelect: "merge-pdf-select",
  CreateTemplate:"create-template"
};
