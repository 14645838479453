export let messages = {
  // USER: "user",
  USER_ID: "userid",
  TOKEN: "token",
  USER_ROLE: "userRole",
  PROJECT_ID: "projectId",
  BATCH_ID: "batchId",
  PROJECT_NAME: "projectName",
  USER_NAME: "userName",
  BATCH_NAME: "batchName",
  E_SIGNATURE: "Create your Electronic-Signature",
  AUTH_CREDS: "Provide your Credentials",
  AUTH_P:
    "Electronic signatures are considered to be the legally binding equivalent to full handwritten signature by the FDA according to 21 CFR part 11",
  USER_NAME_AUTH: "User Name",
  PASS: "Password",
  NEW_PROJECT: "New Project",
  IMPORT_PDF: "Import PDFs",
  IMPORT_TEMPLATE: "Import Template/Output Format",
  MAP_DATA: "Map Data",
  NEW_BATCH: "New Batch",
  ASSIGN_USER: "Assign User",
  DATA_ENTRY: "Data Entry",
  DATA_VALIDATION: "Data Validation",
  SUCCESS: "success",
  ERROR: "error",
  GROUP_EDIT: "Group edited",
  FAIL: "Failed to add group",
  EDIT_GROUP: "Edit Group",
  ADD_NEW_GROUP: "Add New Group",
  USER_ADDED: "user added",
  USER_EDIT: "User edited",
  FAIL: "Failed to add user",
  PASSWORD_NOT_MATCH: "Password does not match",
  EDIT_USER: "Edit User",
  ADD_USER: "Add New User",
  F_NAME: "First Name",
  L_NAME: "Last Name",
  ANSWER: "Enter your answer",
  EMAIL: "Email",
  BATCH_OCR: "OCR Initiated",
  BATCH_NON_OCR: "Uploaded Successfully",
  ENTER_F_NAME: "Enter First Name",
  ENTER_L_NAME: "Enter Last Name",
  ENTER_EMAIL: "Enter Email",
  ENTER_USER_NAME: "Enter User Name",
  CLEAR:"Clear",
  SELECTED_COORDINATES:"Selected Coordinates",
  RESET_MAPPINGS:"Reset",
  RESET_MAPPING_TOOLTIP : "In order to reset and update mapping please select pdf page accordingly."
};
