import DatePicker from "react-datepicker";
import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import Buttons from "./Controls/buttons/Button";
import "../components/Controls/dateRange/basicDateRangePicker.scss";
import "./DateRangePickerAdmin.scss";

export default function DateRangePicker({ filterByDate, clear }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [status, setStatus] = React.useState("");
  

  return (
    <>
      <Grid container spacing={1} className="BasicDatePiker-wrap adminDatepiker">
        <Grid item md={7}>
          <DatePicker
            className="DateRangePickerAdministrator"
            placeholderText="MM/DD/YYYY  To  MM/DD/YYYY"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
            maxDate={new Date()}
          />          
        </Grid>
        <Grid item className="admin-search" md={2}>
          <Buttons
            className="btn-search"
            text="Search"
            size="small"
            onClick={(e) => filterByDate(startDate, endDate, status)}
          />          
        </Grid>
      </Grid>
    </>
  );
}
