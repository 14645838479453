import * as React from "react";
import "./Stepper.scss";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

const steps = [
  "New Project",
  "Upload PDFs",
  "Upload Template And Output Format",
  "Map Data",
  "Assign User",
];

export default function HorizontalLinearStepper(props) {
  const { step } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  return ( 
    <Box className="w-100">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (step(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (step(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
