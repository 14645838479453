import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import infoIcon from "../../../assets/info-svgrepo-com.svg";
import { messages } from "../../../constants/commonMessages";
import { fontSize } from "@mui/system";
import "./CellInfoMenu.scss";

const CellInfoMenu = ({ coordinatesValues, clearMappings }) => {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onClickClearMappings = () => {
    setOpen(null);
    clearMappings();
  };

  return (
    <>
      <button
        aria-label="more"
        aria-controls="long-menu"
        id="long-button"
        className="readMore-btn-cell-info"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <img
          src={infoIcon}
          alt="i"
        ></img>
      </button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="info-popup"
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 140,
          },
        }}
      >
        <Box class="detail-popup">
          <Box>
            <Typography
              variant="body2"          >
              {messages?.SELECTED_COORDINATES} -
              {coordinatesValues?.length >= 0 ? coordinatesValues?.length : 0}
            </Typography>
          </Box>
        </Box>

        <Divider className="border-dash" />
        <Box class="detail-popup">
          <Box>
            <Typography variant="body2">
              {coordinatesValues?.toString()}
            </Typography>
          </Box>
        </Box>

        <Divider className="border-dash" />

        <Box className="clear-wrap-btn">
          <Tooltip
            arrow
            title={messages?.RESET_MAPPING_TOOLTIP}
          >
            <Button
              fullWidth
              disableRipple
              onClick={(e) => onClickClearMappings()}
            >
              {messages?.RESET_MAPPINGS}
            </Button>
          </Tooltip>
        </Box>
      </Popover>
    </>
  );
};

export default CellInfoMenu;
