import React, { useState } from "react";

const isDisableRange = (dataType, role) => {
  let disable = false;
  if (role === "preProcessor") {
    const rangeDrivenDatatypes = ["numeric", "percentage"];
    if (!rangeDrivenDatatypes.includes(dataType)) {
      disable = true;
    }
    return disable;
  } else {
    disable = true;
    return disable;
  }
};

const RangeSelector = (props) => {
  const { handleRange, parameterIndex, dataType, selectedRange, role , entireBatchRejectedByPro} = props;

  const [range, setRange] = useState(selectedRange);
  const handleRangeChange = (value, type) => {
    setRange({
      ...range,
      [type]: value,
    });
  };

  return (
    <>
      <input
        className="textfieldClass"
        type="number"
        placeholder={"Min"}
        onBlur={(e) => handleRange(e.target.value, parameterIndex, "min")}
        // inputProps={{ style: { textAlign: "center" } }}
        disabled={isDisableRange(dataType, role)}
        value={range?.min}
        onChange={(e) => handleRangeChange(e.target.value, "min")}
      />
      -
      <input
        className="textfieldClass"
        type="number"
        placeholder={"Max"}
        onBlur={(e) => handleRange(e.target.value, parameterIndex, "max")}
        // inputProps={{ style: { textAlign: "center" } }}
        disabled={isDisableRange(dataType, role)}
        value={range?.max}
        onChange={(e) => handleRangeChange(e.target.value, "max")}
      />
    </>
  );
};

export default RangeSelector;
