import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import BasicDateRangePicker from "../Controls/dateRange/BasicDateRangePicker";
import SearchBar from "../Controls/searchBar/SearchBar";
import Button from "../../components/Controls/buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import NewSteppers from "../Stepper/NewSteppers";
import "./actionBars.scss";
import { Grid } from "@mui/material";
import { action } from "../../constants/action";

export default function ActionBar(props) {
  const history = useHistory();
  const { steps, onSearch, filterByDate,
    clear
  } = props;

  const createNewProject = () => {
    history.push("new-project");
  };

  return (
    <Box className="action-bar">
      <AppBar className="actionbar-bg">
        {steps != "steppers" ? (
          <Toolbar>
            <Grid container className="action-wrap">
              <Grid item md={3}>
                <SearchBar onSearch={(value, type) => onSearch(value, type)} />
              </Grid>
              <Grid item md={7}>
                <BasicDateRangePicker filterByDate={filterByDate} clear={clear} />
              </Grid>
              <Grid item md={2}>
                {localStorage.getItem("userRole") === "pre-processor" && (
                  <Button
                    text={action.CREATE_PROJECT}
                    className="btn-create-new-project"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={createNewProject}
                  />
                )}
              </Grid>
            </Grid>
          </Toolbar>
        ) : (
          <Toolbar>
            <NewSteppers />
          </Toolbar>
        )}
      </AppBar>
    </Box>
  );
}
