import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import "./skeleton.scss";

const DashboardTableSkaleton = () => {
  return (
    <Stack spacing={1} className="skaleton-table">
      <Skeleton variant="rectangular" height={50} animation={false} />
      <Skeleton variant="rounded" height={48} />
      <Skeleton variant="rounded" height={45} />
      <Skeleton variant="rounded" height={42} />
      <Skeleton variant="rounded" height={39} />
      <Skeleton variant="rounded" height={36} />
      <Skeleton variant="rounded" height={33} />
      <Skeleton variant="rounded" height={30} />
      <Skeleton variant="rounded" height={27} />
      <Skeleton variant="rounded" height={24} />
      <Skeleton variant="rounded" height={24} />
      <Skeleton variant="rounded" height={24} />
    </Stack>
  );
};

export default DashboardTableSkaleton;
