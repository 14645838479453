import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIBASE + "/",
  timeout: 900000, // 30 seconds,
  headers: {
    "Content-Type": "Application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token"),
  },
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = token;
  return config;
});

export default class AxiosServices {
  Post(url, data) {
    return instance.post(url, data);
  }

  Get(url, params) {
    return instance.get(url, params);
  }

  Delete(url, isHeaderRequired) {
    return instance.delete(url);
  }

  Put(url, data, isHeaderRequired, header) {
    return instance.put(url, data);
  }
}
