import React from "react";
import Button from "../../components/Controls/buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./header.scss";
import jsPDF from "jspdf";
import "jspdf-autotable";

const AdministratorHeader = (props) => {
  const {
    text,
    to,
    isShow,
    isArrow,
    isExport,
    dpCalculation,
    outPutButton,
    Click,
  } = props;
  const history = useHistory();

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Record History";
    const headers = [["Action", "Date & Time", "User Role", "User Name"]];
    const temp = isExport?.map((elt) => [
      elt.activity_name,
      elt.date,
      elt.user_group,
      elt.user_name,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: temp,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  return (
    <Box className="admin-box">
      {/* {isArrow && (
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      )} */}
      <Typography className="administartor-project-title" variant="h6">
        {text}
        {isShow && (
          <Link className="admin-header" to={to}>
            <Button
              // className="admin-header-btn"
              text={text}
              size="small"
              variant="contained"
              startIcon={text == "Batch Records" ? "" : <AddIcon />}
            ></Button>
          </Link>
        )}
      </Typography>
      {isExport?.length > 0 && (
        <Button
          text="Export"
          className="export-btn"
          size="medium"
          variant="contained"
          onClick={exportPDF}
        ></Button>
      )}
      {/* {dpCalculation?.length > 0 && (
        <Button
          text="Calculation"
          className="export-btn"
          size="medium"
          variant="contained"
          // onClick={exportPDF}
        ></Button>
      )} */}

      {outPutButton?.length > 0 && (
        <Grid md={3} 
        >
          <Button
            text={outPutButton}
            className="export-btn"
            size="medium"
            variant="contained"
            onClick={Click}
          ></Button>
        </Grid>
      )}
    </Box>
  );
};

export default AdministratorHeader;
