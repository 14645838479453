import React from "react";
import Header from "../../../components/header/Header";
import ActionBar from "../../../components/actionbar/ActionBars";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControl, Grid, Select, MenuItem } from "@mui/material";
import Footer from "../../../components/Footer";
import "./BatchProcess.scss";
import "react-datepicker/dist/react-datepicker.css";
import Label from "../../../components/label/Label";

const BatchProcess = () => {
  return (
    <React.Fragment>
      <Header page="new-project" />
      <ActionBar steps="steppers" />
      <Box className="batchProcessing">
        <Grid container className="h-center">
          <Grid item md={4}>
            <div className="input-wrap-title">
              {/* <label className="blueLabel">Assign User </label> */}
              <Label
                fontSize="16px"
                fontWeight="500"
                text="Submit For Processing"
              ></Label>
            </div>
            <form action="" className="batch-processing-form">
              <FormControl fullWidth>
                <div className="input-wrap">
                  <label>Subject</label>
                  <TextField fullWidth placeholder="Batch Name" />
                </div>
                <div className="input-wrap">
                  <label>Select User</label>
                  <Select fullWidth displayEmpty name="Select User">
                    <MenuItem disabled value="">
                      <em>Placeholder</em>
                    </MenuItem>
                    <MenuItem>User 1</MenuItem>
                    <MenuItem>User 2</MenuItem>
                    <MenuItem>User 3</MenuItem>
                  </Select>
                </div>
                <div className="input-wrap">
                  <label>Remark</label>
                  <TextField fullWidth placeholder="Select User" />
                </div>
                <div className="input-wrap">
                  <label>Assign a Timeline</label>
                  <TextField fullWidth placeholder="Project Name" />
                </div>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </React.Fragment>
  );
};
export default BatchProcess;
