import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import BedtimeTwoToneIcon from "@mui/icons-material/BedtimeTwoTone";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  changeColor,
  changeFontFamily,
  changeTheme,
  Colors,
  Fonts,
  Themes,
} from "./SettingFileContants";

const SettingsDrawer = ({ onClose }) => {
  const [value, setValue] = React.useState("arial");

  const handleChangeFontFamily = (event) => {
    setValue(event.target.value);
    changeFontFamily(event.target.value);
  };

  const handleCloseClick = () => {
    onClose();
  };

  const handleClickColorChange = (color) => {
    // const color331 = getComputedStyle(
    //   document.documentElement
    // ).getPropertyValue("var(--main-theme-color)");
    // console.log(color331);
    changeColor(color);
  };

  const handleClickThemeChange = (theme) => {
    changeTheme(theme);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px",
          alignItems: "center",
          width: "308px",
        }}
      >
        <Typography sx={{ fontSize: 16 }} color="text.primary" variant="body2">
          Settings
        </Typography>

        <IconButton aria-label="delete" onClick={() => handleCloseClick()}>
          <CloseIcon />
        </IconButton>
      </div>

      <Card sx={{ width: 300 }}>
        <CardContent>
          <>
            <Typography
              sx={{ fontSize: 16 }}
              color="text.primary"
              variant="body2"
            >
              Primary Color
            </Typography>

            <Grid container spacing={1} sx={{ padding: "10px" }}>
              <Grid item xs={6}>
                <Chip
                  sx={{ color: "#48B06B" }}
                  label="Green"
                  onClick={(e) => handleClickColorChange(Colors.Green)}
                  //   onDelete={handleDelete}
                  //   deleteIcon={<DeleteIcon />}
                  variant="outlined"
                  icon={
                    <div
                      style={{
                        backgroundColor: "#48B06B",
                        borderRadius: "50%",
                        flexShrink: 0,
                        height: "24px",
                        width: "24px",
                      }}
                    ></div>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Chip
                  sx={{ color: "#0C989B" }}
                  label="Blue"
                  onClick={(e) => handleClickColorChange(Colors.Blue)}
                  //   onDelete={handleDelete}
                  icon={
                    <div
                      style={{
                        backgroundColor: "#0C989B",
                        borderRadius: "50%",
                        flexShrink: 0,
                        height: "24px",
                        width: "24px",
                      }}
                    ></div>
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Chip
                  sx={{ color: "indigo" }}
                  label="Indigo"
                  onClick={(e) => handleClickColorChange(Colors.Indigo)}
                  //   onDelete={handleDelete}
                  //   deleteIcon={<DeleteIcon />}
                  variant="outlined"
                  icon={
                    <div
                      style={{
                        backgroundColor: "indigo",
                        borderRadius: "50%",
                        flexShrink: 0,
                        height: "24px",
                        width: "24px",
                      }}
                    ></div>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Chip
                  sx={{ color: "olive" }}
                  label="Olive"
                  onClick={(e) => handleClickColorChange(Colors.Olive)}
                  //   onDelete={handleDelete}
                  //   deleteIcon={<DeleteIcon />}
                  variant="outlined"
                  icon={
                    <div
                      style={{
                        backgroundColor: "#6B8E23",
                        borderRadius: "50%",
                        flexShrink: 0,
                        height: "24px",
                        width: "24px",
                      }}
                    ></div>
                  }
                />
              </Grid>
            </Grid>

            <Typography
              sx={{ fontSize: 16, paddingTop: "20px" }}
              color="text.primary"
              variant="body2"
            >
              Color Scheme
            </Typography>

            <Grid container spacing={2} sx={{ padding: "10px" }}>
              <Grid item xs={6}>
                <Chip
                  label="Light"
                  onClick={(e) => handleClickThemeChange(Themes.Light)}
                  //   onDelete={handleDelete}
                  //   deleteIcon={<DeleteIcon />}
                  variant="outlined"
                  icon={<WbSunnyTwoToneIcon />}
                />
              </Grid>
              <Grid item xs={6}>
                <Chip
                  label="Dark"
                  onClick={(e) => handleClickThemeChange(Themes.Dark)}
                  //   onDelete={handleDelete}
                  icon={<BedtimeTwoToneIcon />}
                  variant="filled"
                  // color="primary"
                  // sx={{
                  //   width: 300,
                  //   color: 'red',
                  // }}
                />
              </Grid>
            </Grid>

            <Typography
              sx={{ fontSize: 16, paddingTop: "20px" }}
              color="text.primary"
              variant="body2"
            >
              Font Family
            </Typography>

            <FormControl sx={{ padding: "10px" }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChangeFontFamily}
              >
                <FormControlLabel
                  value={Fonts.Montserrat}
                  control={<Radio />}
                  label="Montserrat"
                />
                <FormControlLabel
                  value={Fonts.SansSerif}
                  control={<Radio />}
                  label="Sans Serif"
                />
                <FormControlLabel
                  value={Fonts.Roboto}
                  control={<Radio />}
                  label="Roboto"
                />
                <FormControlLabel
                  value={Fonts.OpenSans}
                  control={<Radio />}
                  label={"Open Sans"}
                />
              </RadioGroup>
            </FormControl>
          </>
        </CardContent>
      </Card>
    </>
  );
};

export default SettingsDrawer;
