import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import SearchIconImg from "../../assets/SearchIcon.png";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RotateLeftTwoToneIcon from "@mui/icons-material/RotateLeftTwoTone";
import RotateRightTwoToneIcon from "@mui/icons-material/RotateRightTwoTone";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import LoopSharpIcon from "@mui/icons-material/LoopSharp";
import "./mapData.scss";

const styles = {
  margin: 0,
  fontSize: "10px",
  color: "#202020",
  // fontfamily:var(--font-family),
};
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(1),
    left: theme.spacing(-2),
  },
}));

const MapToolBar = ({
  file,
  handlePageChange,
  searchPageClick,
  selectedFile,
  zoom,
  onClickZoom,
  rotateClick,
  fitToScreenClick,
  onClickChangeOrientation,
}) => {
  // const { pdfName, pages } = file;

  const [inputValue, setInputValue] = useState(0);

  const actions = [
    {
      icon: (
        // <Tooltip title="Zoom Out" arrow placement="top">
        <ZoomOutIcon
          onClick={(e) => onClickZoom("out")}
          className="cursorPointer"
          color={zoom === 1 ? "disabled" : "inherit"}
        />
        // </Tooltip>
      ),
      name: "Zoom Out",
    },
    {
      icon: <h6 style={{ ...styles }}>{parseInt(zoom * 10)}%</h6>,
      name: "Zoom Level",
    },

    {
      icon: (
        <IconButton className="p-4px">
          <ZoomInIcon
            onClick={(e) => onClickZoom("in")}
            className="cursorPointer"
            color={zoom === 10 ? "disabled" : "inherit"}
          />
        </IconButton>
      ),
      name: "Zoom In",
    },
    {
      icon: (
        <IconButton className="p-4px">
          <RotateRightTwoToneIcon
            onClick={(e) => rotateClick("right")}
            className="cursorPointer"
          />
        </IconButton>
      ),
      name: "Rotate Right",
    },
    {
      icon: (
        <IconButton className="p-4px">
          <AutorenewOutlinedIcon
            onClick={fitToScreenClick}
            className="cursorPointer"
          />
        </IconButton>
      ),
      name: "Refresh",
    },

    {
      icon: (
        <IconButton className="p-4px">
          <IconButton className="p-4px">
            <RotateLeftTwoToneIcon
              onClick={(e) => rotateClick("left")}
              className="cursorPointer"
            />
          </IconButton>
        </IconButton>
      ),
      name: "Rotate Left",
    },

    {
      icon: (
        <div className="pageNo-wrap">
          <FormControl variant="standard">
            <Input
              id="standard-adornment-weight"
              // value={}
              onChange={(e) => setInputValue(e.target.value)}
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Page"
              type="number"
            />
          </FormControl>
        </div>
      ),
      name: "Search",
    },

    {
      icon: (
        <IconButton className="mapToolBar-serach">
          <img
            className="serchIcon"
            src={SearchIconImg}
            alt="search"
            onClick={(e) => searchPageClick(inputValue - 1)}
          />
        </IconButton>
      ),
      name: "Search",
    },
    {
      icon: (
        <LoopSharpIcon
          onClick={(e) => onClickChangeOrientation()}
          className="cursorPointer"
        />
      ),
      name: "Change Orientation",
    }
  ];

  return (
    <Box className="mapTool-Wrap">
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        // hidden={hidden}
        icon={<MenuIcon />}
        // icon={<SpeedDialIcon openIcon={<ClearAllIcon />} />}
        direction={"down"}
        FabProps={{
          sx: {
            bgcolor: "rgba(0, 0, 0, 0.6)",
            "&:hover": {
              bgcolor: "rgb(229, 34, 34)",
            },
            height: "40px",
            width: "40px",
          },
        }}
      >
        {actions.map((action) => {
          return (
            <SpeedDialAction
              className="MapToolSpeedAction"
              sx={{
                ...(action?.name === "Zoom Level" && { cursor: "not-allowed" }),
                ...(action?.name === "Zoom Level" && { cursor: "not-allowed" }),
            }}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          );
        })}
      </StyledSpeedDial>
    </Box>
  );
};

export default MapToolBar;
