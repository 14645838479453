import * as React from "react";
import "./auditDataTable.scss";

export default function DataMap() {
  return (
    <table className="audit-table-style">
      <tr>
        <th>Prepared On</th>
        <th>Wash Syringe Volume</th>
        <th>Dilution Factor For Cell Count</th>
        <th>Pre Dilution Factor</th>
        <th>Total Cells/Ml</th>
      </tr>
      <tr className="audit-table-disable">
        <td>Data Type</td>
        <td>Data Type</td>
        <td>Data Type</td>
        <td>Data Type</td>
        <td>Data Type</td>
      </tr>
      <tr className="audit-table-disable">
        <td>Data Range</td>
        <td>Data Range</td>
        <td>Data Range</td>
        <td>Data Range</td>
        <td>Data Range</td>
      </tr>

      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
    </table>
  );
}
