import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
const configUrl = process.env.REACT_APP_APIBASE + "/";
export default class UploadBatchAPI {
  getHeader = () => {
    return {
      headers: {
        "Content-Type": "Application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    }
  };
  listBoxFiles = (data) => {
    return axiosService.Get(
      `${configUrl}api/list-box-files/?folder_id=${data?.folderId}&file_type=${data?.fileType}`,
      this.getHeader(),
    );
  };

  uploadBoxFiles = (fileType = "", body) => {
    return axiosService.Post(
      `${configUrl}api/list-box-files/?file_type=${fileType}/`,
      body,
      this.getHeader(),
    );
  };
}