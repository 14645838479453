import React, { useState, useEffect } from "react";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import Header from "../../../components/header/Header";
import {
  Box,
  Card,
  Grid,
  ListItemText,
  Typography,
  TextField,
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  CircularProgress,
} from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  getMergeProjectList,
  getMergeProjectBatches,
  clearMergeBatches,
  submitFinalOpFilesData,
  clearApiStatus,
  getMergedFiles,
  clearGetMergedFiles,
} from "../../../redux/administrator/actions";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import CalendarViewDayTwoToneIcon from "@mui/icons-material/CalendarViewDayTwoTone";
import "./MergeOpFiles.scss";

function Index() {
  const dispatch = useDispatch();
  const projectList = useSelector((state) => state?.admin?.mergeProjects);
  const batchList = useSelector((state) => state?.admin?.mergeBatches);
  const apiStatus = useSelector((state) => state?.admin?.apiStatus);
  const mergedFilesList = useSelector((state) => state?.admin?.mergedFiles);

  const [batchNames, setBatchNames] = useState([]);
  const [finalBatches, setFinalBatches] = useState([]);
  const [data, setData] = useState([]);
  const [isDuplicte, setIsDuplidate] = useState(false);
  const [selectedProjectId, setSelectedProjIdToAddBatches] =
    React.useState(null);
  const [masterFileName, setMasterFileName] = useState("");

  const [isMergedFilesApiCalled, setIsMergedFilesApiCalled] = useState(false);
  const [mergedFIlesList, setMergedFIlesList] = useState([]);
  const [batchSwitch, setBatchSwitch] = useState(false);
  const [batchLoader, setBatchLoader] = useState(false);
  const [filesLoader, setFilesLoader] = useState(false);

  useEffect(() => {
    // get project list call
    dispatch(clearApiStatus());
    dispatch(clearMergeBatches());
    dispatch(getMergeProjectList());
    dispatch(clearGetMergedFiles());
    setBatchNames([]);
    setFinalBatches([]);
    // setFinalFiles([]);
    setIsMergedFilesApiCalled(false);
  }, [dispatch]);

  useEffect(() => {
    // if (batchList && batchList.length) {
    setBatchNames(batchList);
    setBatchLoader(false);
    // }
  }, [batchList]);

  useEffect(() => {
    if (mergedFilesList && mergedFilesList.length) {
      setMergedFIlesList(mergedFilesList);
      setIsMergedFilesApiCalled(true);
      setFilesLoader(false);
    }
  }, [mergedFilesList]);

  const handleProjectChecked = (e, project) => {
    const { value, checked } = e.target;

    if (checked) {
      setData([...data, { projName: project?.project_name, batches: [] }]);
    } else if (!checked) {
      setData((prev) => prev.filter((x) => x?.projName !== value));
    }
  };

  const getBatchesOfProject = (project) => {
    setBatchLoader(true);
    getBatchListOfSelectedProj(project?.project_name);
    setFinalBatches([]);
    // setFinalFiles([])
  };

  const handleBatchChecked = (e, batch, batchIndex) => {
    // const { checked } = e.target;
    // if (checked) {
    //   batch.checked = true;
    //   finalBatches.push(batch);
    //   setFinalBatches([...finalBatches]);
    // } else if (!checked) {
    //   let rem;
    //   batch.checked = false;
    //   rem = finalBatches.filter((arr) => arr.batch_name != batch?.batch_name);
    //   setFinalBatches([...rem]);
    // }

    const { checked } = e.target;
    if (checked) {
      batchNames[batchIndex].checked = true;
      setBatchNames([...batchNames]);
    } else if (!checked) {
      batchNames[batchIndex].checked = false;
      setBatchNames([...batchNames]);
    }
  };

  const getBatchListOfSelectedProj = (names) => {
    // get batch names for checked project
    let id = projectList.filter((index) => index.project_name === names);
    id = id[0].project_id;
    dispatch(getMergeProjectBatches(id));
    setBatchNames([]);
  };

  const handleOpen = (i) => {
    data[i].isOpen = !data[i].isOpen;
    setData([...data]);
    // setOpen(!open);
  };

  const handleFocus = (i) => {
    data[i].isFocused = !data[i].isFocused;
    setData([...data]);
  };

  const addBatches = () => {
    let batchesToAdd;
    let filesToAAdd;

    if (batchNames?.length > 0) {
      batchesToAdd = batchNames.filter((batch) => batch.checked);
    }

    if (mergedFIlesList?.length > 0) {
      filesToAAdd = mergedFIlesList.filter((batch) => batch.checked);
    }

    if (
      selectedProjectId !== null &&
      selectedProjectId >= 0 &&
      (batchesToAdd?.length > 0 || filesToAAdd?.length > 0)
    ) {
      // let selectedBatches = data[selectedProjectId]?.batches;
      let selectedBatchNames = data[selectedProjectId]?.batches?.map(
        (batch) => batch.batch_name
      );

      if (batchesToAdd?.length > 0) {
        let mergedBatches = [
          ...data[selectedProjectId]?.batches,
          ...batchesToAdd.filter(
            (batch) => !selectedBatchNames.includes(batch.batch_name)
          ),
        ];
        const filteredMergedFiles = mergedBatches.filter(
          (batch) => batch.checked
        );

        data[selectedProjectId].batches = [...filteredMergedFiles];
        // data[selectedProjectId].batches.filter((batch) => batch.checked);

        setData([...data]);
      }

      if (filesToAAdd?.length > 0) {
        let mergedBatches = [
          ...data[selectedProjectId]?.batches,
          ...filesToAAdd.filter(
            (batch) => !selectedBatchNames.includes(batch.batch_name)
          ),
        ];
        const filteredMergedFiles = mergedBatches.filter(
          (batch) => batch.checked
        );
        data[selectedProjectId].batches = [...filteredMergedFiles];
        // data[selectedProjectId].batches.filter((batch) => batch.checked);
        setData([...data]);
      }
    } else if (
      selectedProjectId !== null &&
      selectedProjectId >= 0 &&
      (batchesToAdd?.length === 0 || filesToAAdd?.length === 0)
    ) {
      data[selectedProjectId].batches = [];
      setData([...data]);
    }

    // console.log(batchesToAdd, filesToAAdd);
    //     if (
    //       selectedProjectId !== null &&
    //       selectedProjectId >= 0 &&
    //       (batchesToAdd.length > 0 || filesToAAdd.length > 0)
    //     ) {
    //       console.log(selectedProjectId);
    //       console.log(finalBatches);
    //       data[selectedProjectId].batches = [
    //         ...data[selectedProjectId].batches,
    //         ...batchesToAdd,
    //         ...filesToAAdd,
    //       ];
    //     }
  };

  const handleChange = (event) => {
    setSelectedProjIdToAddBatches(event.target.value);
  };

  const handleProjectNameChange = (event, i) => {
    console.log(event);
    data[i].projName = event.target.value;
    setData([...data]);
  };

  const submit = () => {
    dispatch(submitFinalOpFilesData({ masterFileName, data }));
  };

  const onChangeFiles = (e) => {
    setBatchSwitch((prevState) => !prevState);
    if (e.target.value === "false" && !isMergedFilesApiCalled) {
      dispatch(getMergedFiles());
      setFilesLoader(true);

    }
  };

  const handleFileChecked = (e, file, fileIndex) => {
    // const { checked } = e.target;
    // if (checked) {
    //   file.checked = true;
    //   finalFiles.push(file);
    //   setFinalFiles([...finalFiles]);
    // } else if (!checked) {
    //   let rem;
    //   file.checked = false;
    //   rem = finalFiles.filter((arr) => arr.batch_name != file?.batch_name);
    //   setFinalFiles([...rem]);
    // }

    const { checked } = e.target;
    if (checked) {
      mergedFIlesList[fileIndex].checked = true;
      setMergedFIlesList([...mergedFIlesList]);
    } else if (!checked) {
      mergedFIlesList[fileIndex].checked = false;
      setMergedFIlesList([...mergedFIlesList]);
    }
  };

  return (
    <div>
      <Header />
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader text="Merge Op Files" />
            <Grid container className="mergeop-grid-container">
              <Grid item md={4} className="mergeop-grid1-style">
                <Typography className="mergeop-text" variant="subtitle2">
                  Project Name
                </Typography>

                <Box className="mergeop-grid1-Box1">
                  {projectList?.map((project, i) => (
                    <Card key={i} className="mergeop-card1">
                      <label key={i} className="mergeop-lable">
                        <input
                          className="mergeop-input"
                          type="checkbox"
                          name="lang"
                          value={project.project_name}
                          onChange={(e) => handleProjectChecked(e, project)}
                        />
                        {project?.project_name}
                      </label>
                      <Tooltip
                        className="mergeop-tooltip"
                        title="View Batches"
                        arrow
                        placement="top"
                      >
                        <IconButton aria-label="delete">
                          <CalendarViewDayTwoToneIcon
                            onClick={(e) => getBatchesOfProject(project)}
                          />
                        </IconButton>
                      </Tooltip>
                    </Card>
                  ))}
                </Box>
              </Grid>

              <Grid item md={4} className="mergeop-grid2-style">
                <Typography
                  className="mergeop-grid2-text-style"
                  variant="subtitle2"
                >
                  Batches
                </Typography>
                <Box className="mergeop-grid2-Box1">
                  <FormControl className="mergeop-form-control">
                    <InputLabel
                      className="mergeop-label"
                      id="demo-simple-select-label"
                    >
                      Select Project
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedProjectId}
                      label="Select Project"
                      size="small"
                      onChange={handleChange}
                    >
                      {data?.map((project, index) => {
                        return (
                          <MenuItem value={index}>{project?.projName}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/* </Box> */}
                  <Button
                    className="add-btn"
                    onClick={addBatches}
                    startIcon={<AddIcon />}
                    disabled={
                      selectedProjectId !== null && selectedProjectId >= 0
                        ? false
                        : true
                    }
                  >
                    Add
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Typography>Batches</Typography> */}
                  <Switch
                    onChange={(e) => onChangeFiles(e)}
                    value={batchSwitch}
                  />
                  <Typography>Show Merged Files</Typography>
                </Box>

                <Box className="mergeop-grid2-Box2">
                  {batchNames?.length > 0 && <Typography>Batches</Typography>}

                  {batchLoader && (
                    <Box>
                      <CircularProgress />
                    </Box>
                  )}

                  {batchNames?.map((batch, batchIndex) => (
                    <Card key={batch?.batch_name} className="mergeop-card2">
                      <CheckBox
                        checked={batch?.checked ? true : false}
                        id={batch?.batch_name}
                        onChange={(e) =>
                          handleBatchChecked(e, batch, batchIndex)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography className="text-style" variant="subtitle1">
                        {batch?.batch_name}
                      </Typography>
                    </Card>
                  ))}
                  {batchSwitch && <Typography>Merged Files</Typography>}

                  {filesLoader && (
                    <Box>
                      <CircularProgress />
                    </Box>
                  )}

                  {batchSwitch &&
                    mergedFIlesList?.map((file, fileIndex) => (
                      <Card key={file?.batch_name} className="mergeop-card2">
                        <CheckBox
                          checked={file?.checked ? true : false}
                          id={file.batch_name}
                          onChange={(e) =>
                            handleFileChecked(e, file, fileIndex)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <Typography className="text-style" variant="subtitle1">
                          {file?.batch_name}
                        </Typography>
                      </Card>
                    ))}
                </Box>
              </Grid>
              <Grid item md={4} className="mergeop-grid3-style">
                <Typography className="text-style" variant="subtitle2">
                  Selected Batches
                </Typography>
                <TextField
                  className="text-field"
                  size="small"
                  placeholder="Master File Name"
                  value={masterFileName}
                  onChange={(event) => setMasterFileName(event.target.value)}
                />
                <Box className="mergeop-grid3-Box3">
                  {data?.map((x, i) => (
                    <List
                      className="mergeop-list-style"
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <div className="mergeop-list-item">
                        {!data[i].isFocused ? (
                          <ListItemText
                            onClick={() => {
                              handleFocus(i);
                            }}
                          >
                            {x.projName}
                          </ListItemText>
                        ) : (
                          <TextField
                            className="mergeop-editable-text"
                            size="small"
                            value={x.projName}
                            onChange={(event) =>
                              handleProjectNameChange(event, i)
                            }
                            onBlur={(event) => handleFocus(i)}
                          />
                        )}

                        {/* <IconButton onClick={(e) => handleOpen(i)}>
                          {data[i]?.batches?.length ? <ExpandLess /> : <ExpandMore />}
                        </IconButton> */}
                      </div>
                      <Collapse
                        in={data[i]?.batches?.length}
                        timeout="auto"
                        unmountOnExit
                      >
                        {data[i]?.batches?.length  &&
                          x?.batches?.map((index) => (
                            <List
                              className="mergeop-collapse-list"
                              component="div"
                              disablePadding
                            >
                              <ListItemText>{index.batch_name}</ListItemText>
                            </List>
                          ))}
                      </Collapse>
                    </List>
                  ))}
                </Box>
              </Grid>
              <Grid className="mergeop-submit-grid" item md={12}>
                <Button
                  className="mergeop-submit-button"
                  onClick={submit}
                  // startIcon={<AddIcon />}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {isDuplicte && (
        <CustomizedSnackbars
          severity="error"
          state={isDuplicte}
          text={"Project Aledy Exist"}
          handleClose={() => setIsDuplidate(false)}
        />
      )}
      {apiStatus?.status && (
        <CustomizedSnackbars
          severity="success"
          state={apiStatus?.status}
          text={apiStatus?.message}
          handleClose={() =>
            this.setState({
              sucessNotification: false,
            })
          }
        />
      )}
      {apiStatus?.loader === false && apiStatus?.next === false && (
        <CustomizedSnackbars
          severity="error"
          state={apiStatus?.loader === false && apiStatus?.next === false}
          text={apiStatus?.message}
          handleClose={() =>
            this.setState({
              errorNotification: false,
            })
          }
        />
      )}
    </div>
  );
}

export default Index;
