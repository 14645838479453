import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const ParameterName = ({
  name,
  onBlurParameterNameChangeInput,
  isNameEditable,
}) => {
  const [parameterName, setParameterName] = useState("");

  useEffect(() => {
    setParameterName(name);
  }, [name]);

  const onChangeParameterName = (value) => {
    setParameterName(value);
  };

  return isNameEditable ? (
    <TextField
      className="parameters-name"
      multiline
      id="standard-basic"
      variant="outlined"
      value={parameterName}
      onChange={(e) => onChangeParameterName(e?.target?.value)}
      onBlur={(e) =>
        name !== parameterName && onBlurParameterNameChangeInput(parameterName)
      }
    />
  ) : (
    <TextField
      className="parameters-name"
      multiline
      id="standard-basic"
      variant="standard"
      value={name}
    />
  );
};

export default ParameterName;
