// Common
export const server_error_msg = "Server Error Please Try Again...";
export const files_not_uploades_msg = "Files Not Uploaded Please Try Again";
export const page_not_found_msg = "Page Not Found";
export const project_not_created_msg = "Project Not Created Please Try Again";
export const forbidden_msg = "Forbidden resources";
export const unauthorized_msg = "Unauthorized";
export const bad_request_msg = "Bad Request";
export const request_timeout_msg = "Request Timeout";
export const network_error_msg = "Network Error";

export const errorMessage = (error) => {
  if (error?.message === "Network Error") {
    return network_error_msg;
  }

  if (error?.response?.data?.message) {
    return error.response.data.message;
  }

  if (error?.response?.status === 400) {
    return bad_request_msg;
  }

  if (error?.response?.status === 401) {
    return unauthorized_msg;
  }

  if (error?.response?.status === 403) {
    return forbidden_msg;
  }

  if (error?.response?.status === 404) {
    return page_not_found_msg;
  }

  if (error?.response?.status === 500 || error?.response?.status === 503) {
    return server_error_msg;
  }

  if (error?.response?.status === 408) {
    return request_timeout_msg;
  }
};
