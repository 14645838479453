import React from "react";
import RoutesChild from "./routesChild";
import IdealTimeoutHooks from "../components/idealTimeoutHooks";
class Routes extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <>
        <IdealTimeoutHooks {...this.props} />
        <RoutesChild match={match} />
      </>
    );
  }
}

export default Routes;
