import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./infoModal.scss";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
export default function BasicModal({ isOpen, handleClose, infoData }) {
  var JSONPrettyMon = require("react-json-pretty/dist/monikai");

  return (
    <div>
      <Modal
        className="info-modal"
        open={isOpen}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box component={Paper} className="infoModal">

          {infoData?.batch_records?.map((pdf) => (
            <Typography subtitle="1" >{`PDF name: ${pdf}`}</Typography>
          ))}


          <Typography>{`Template: ${infoData?.template}`}</Typography>
          <Typography>{`Output Format: ${infoData?.op_format}`}</Typography>

          {/* <JSONPretty
            id="json-pretty"
            theme={JSONPrettyMon}
            data={infoData}
          ></JSONPretty> */}
        </Box>
      </Modal>
    </div>
  );
}
