import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearApiStatus,
  getAuditDetails,
  getManageGroup,
} from "../../../../redux/administrator/actions";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./auditDetails.scss";
import ActivityCard from "../../../../components/Card/ActivityCard";
import { useLocation } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

export default function AuditDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const detail = useSelector((state) => state?.admin.auditDetails?.data);
  const manageGroup = useSelector((state) => state?.admin.groupDetails);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    dispatch(getManageGroup());
    if (location.state.batchId) {
      dispatch(getAuditDetails(localStorage.projectId, localStorage.batchId));
    } else {
      dispatch(getAuditDetails(localStorage.projectId));
    }
    setDetails(detail);
  }, [dispatch]);

  useEffect(() => {
    setDetails(detail);
  }, [detail]);

  const backToAuditList = () => {
    history.push("/audit-list");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    handleFilterClick(e.name);
  };

  const handleFilterClick = (word) => {
    let result = [];
    if (word) {
      result = detail?.filter(function (el) {
        return el?.user_group == word;
      });
    } else {
      result = detail;
    }
    setDetails(result);
  };

  return (
    <div>
      <Box className="navigation">
        <div className="back-button">
          <IconButton onClick={backToAuditList}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="subtitle1">Back</Typography>
        </div>
        <div className="filter-style">
          <IconButton onClick={handleClick}>
            <FilterAltIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            // onChange={(event) => setName(event.target.value)}
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => handleClose(e)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {manageGroup?.map((index) => (
              <MenuItem value={index.id} onClick={(e) => handleClose(index)}>
                {index.name.charAt(0).toUpperCase() + index.name.slice(1)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Box>
      <Box className="details-container">
        <ActivityCard details={details} />
      </Box>
    </div>
  );
}
